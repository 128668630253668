import React, { useEffect, useState } from 'react';
import { BsMenuUp, BsEye } from 'react-icons/bs';
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';
import { getUser } from '../../service/constants';
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import SideOrdeList from '../../commponets/SideOrdeList';
import Footer from '../Footer/Footer';
import Processing from '../../commponets/Processing/Processing';

function OrderList() {
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(getUser());

  useEffect(() => {
    fetchPricebandList();
  }, []);

  const fetchPricebandList = () => {
    setLoading(true);
    fetchDataFromAPI(`/user/customer/item/order/list`, "get", "", user?.token)
      .then((response) => {
        if (response.status === true) {
          const pricebandData = response?.data?.data;
          console.log(pricebandData, "----response--->");
          setAllPricebandList(pricebandData);
          setLoading(false);
        } else {
          toast.error(response?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "---error");
        setLoading(false);
      });
  };

  return (
    <>
      <Header />
      <Navbar />
      <main>
    
        <section>
          <div className="container">
            <div className="row">
              <SideOrdeList />
              <div className="col-lg-9 col-md-8 col-12">
                
                <div className="py-6 p-md-6 p-lg-10">
                <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{"Your Orders"}</li>
                      </ol>
                    </nav>
                  <h2 className="mb-6">Your Orders</h2>
                  {loading ? (
                    <div className="text-center">
                      <Processing />
                    </div>
                  ) : (
                    <div className="table-responsive-xxl border-0">
                      <table className="table mb-0 text-nowrap table-centered">
                        <thead className="bg-light">
                          <tr>
                            {/* <th>&nbsp;</th> */}
                            <th>Order</th>
                            <th>Date</th>
                            <th>tracking order status</th>
                            {/* <th>tracking payment status</th> */}
                            <th>View Order</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allPricebandList?.map((product) => (
                            <tr key={product.id}>
                              {console.log(product, "------------product")}
                              {/* <td className="align-middle border-top-0">
                                <Link
                                  to={`/singleOrder/${product?.order_line[0]?.order_id}`}
                                  className="text-decoration-none text-inherit"
                                ></Link>
                              </td> */}
                              <td className="align-middle border-top-0">
                                <Link
                                  to={`/singleOrder/${product?.order_line[0]?.order_id}`}
                                >
                                  <a
                                    href="account-orders.html#"
                                    className="text-inherit"
                                  >
                                    {product?.order_number}
                                  </a>
                                </Link>
                              </td>
                              <td className="align-middle border-top-0">
                                {product.dateTime}
                              </td>

                              {
                                product.tracking_order_status == "Pending" ?
                                  <td className="align-middle border-top-0 ">

                                    will be delivered soon...
                                  </td>
                                  :
                                  <td className="align-middle border-top-0 ">

                                    will be delivered soon...
                                  </td>
                              }
                              {/* <td className="align-middle border-top-0">
                                {product.tracking_payment_status}
                              </td> */}
                              <td className="text-muted align-middle border-top-0">
                                <Link
                                  to={`/singleOrder/${product?.order_line[0]?.order_id}`}
                                  className="text-decoration-none text-inherit"
                                >
                                  <a
                                    href="account-orders.html#"
                                    className="text-inherit"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-title="View"
                                  >
                                    <BsEye />
                                    <span className="ms-2 text-muted">View</span>
                                  </a>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default OrderList;
