import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import Navbar from '../Navbar/Navbar'
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { getUser } from '../../service/constants';
import SideOrderList from '../../commponets/SideOrdeList';
import Footer from '../Footer/Footer';
import second from '../../assets/images/avatar/coming_soon.jpg';
function SingleOrderList() {
    let { orderId } = useParams();
    const [allData, setAllData] = useState([])
    const [loading, setLoading] = useState(false)
    const user = JSON.parse(getUser());
    useEffect(() => {
        placeOrder()
    }, [])

    const placeOrder = (e) => {
        const formdata = new FormData();
        formdata.append("orderId", orderId);

        fetchDataFromAPI("/user/customer/item/order/view", "post", formdata, user?.token)
            .then((response) => {
                if (response.status == true) {
                    setAllData(response?.data?.order_line)
                } else {
                    if (response.data.type) {
                        toast.error(response.data.type[0]);
                    } else {
                        toast.error(response.data.message || "An error occurred");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const cancelorder = (item) => {
        const formdata = new FormData();
        formdata.append("orderId", item);
        fetchDataFromAPI("/user/customer/item/order/cancel", "post", formdata, user?.token)
            .then((response) => {
                if (response.status == true) {
                    placeOrder()
                    toast.success(response.message);
                } else {
                    if (response.data) {
                        toast.error(response.message);
                    } else {
                        toast.error(response.message || "An error occurred");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };



    return (
        <>
            <Header />
            <Navbar />
            <div
                className="offcanvas offcanvas-start"
                tabIndex={-1}
                id="offcanvasAccount"
                aria-labelledby="offcanvasAccountLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasAccountLabel">
                        Offcanvas
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body">
                    <ul className="nav flex-column nav-pills nav-pills-dark">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                aria-current="page"
                                href="account-orders.html"
                            >
                                <i className="feather-icon icon-shopping-bag me-2" />
                                Your Orders
                            </a>
                        </li>
                        <li className="nav-item">
                            <Link to={'/cancelOrder'}>
                                <a className="nav-link" href="account-settings.html">
                                    <i className="feather-icon icon-settings me-2" />
                                    Cancelled Orders
                                </a>
                            </Link>
                        </li>
                    </ul>
                    <hr className="my-6" />
                    <div>
                        <ul className="nav flex-column nav-pills nav-pills-dark">
                            {/* nav item */}
                            <li className="nav-item">
                                <a className="nav-link" href="../index.html">
                                    <i className="feather-icon icon-log-out me-2" />
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <main>
                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center d-md-none py-4">
                                    <h3 className="fs-5 mb-0">Account Setting</h3>
                                    <button
                                        className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasAccount"
                                        aria-controls="offcanvasAccount">
                                        <i className="bi bi-text-indent-left fs-3" />
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-12 my-lg-14 my-8">
                                <h2 className="mb-6">Your Orders</h2>
                                <div className="row g-4 justify-content-lg-start">
                                    {loading ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        allData.map((product) => (
                                            <div className='col-lg-3 col-md-4 col-6 d-flex' key={product?.order_id}>
                                                <div className="card card-product cards w-100">
                                                    <div className="card-body">
                                                        <div className="text-center position-relative">
                                                            <picture>
                                                                <img
                                                                    src={product?.item?.itemImage[0] || second}
                                                                    alt="Product"
                                                                    className="product-image1"
                                                                />
                                                            </picture>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="text-small mb-1">
                                                                <a className="text-decoration-none text-muted">
                                                                    <small>{product?.item?.item_code}</small>
                                                                </a>
                                                            </div>
                                                            <div className="text-small mb-1">
                                                                <a className="text-decoration-none text-muted">
                                                                    <small>{product?.quantity}</small>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <h2 className="fs-6">
                                                            <a className="text-inherit text-decoration-none">
                                                                {product?.item?.name}
                                                            </a>
                                                        </h2>
                                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                                            <div>
                                                                <span className="text-dark">{product?.item?.currency} {product?.item?.price}</span>
                                                            </div>
                                                            <div>
                                                                <div className="">
                                                                    <button className="btn fw-bold text-decoration-none text-muted" onClick={() => cancelorder(product?.order_id)}>Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default SingleOrderList