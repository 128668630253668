import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { PricebandContext } from "../context/PricebandContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchDataFromAPI } from "../service/api";
import { getUser } from "../service/constants";

function SellerCommisionModal(props) {
    const [Item_ID, setItem_ID] = useState("");
    const [Sales1_ID, setSales1_ID] = useState("");
    const [Sales2_ID, setSales2_ID] = useState("");
    const [Sales3_ID, setSales3_ID] = useState("");
    const [Sales4_ID, setSales4_ID] = useState("");
    const [Sales5_ID, setSales5_ID] = useState("");
    const [loading, setLoading] = useState(false);
    const [ItemData, setitemData] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [priceBand, setPriceBand] = useState([]);
    const [selectedPricebandID, setSelectedPricebandID] = useState("");
    const user = JSON.parse(getUser());
    const [discount, setDiscount] = useState('');
    const [discountWith, setDiscountWith] = useState('0');
    const [discount2, setDiscount2] = useState('');
    const [discountWith2, setDiscountWith2] = useState('0');
    const [discount3, setDiscount3] = useState('');
    const [discountWith3, setDiscountWith3] = useState('0');
    const [discount4, setDiscount4] = useState('');
    const [discountWith4, setDiscountWith4] = useState('0');
    const [discount5, setDiscount5] = useState('');
    const [discountWith5, setDiscountWith5] = useState('0');

    useEffect(() => {
        fetchCategoriesList();
        fetchPricebandList();
        fetchSalesList();
    }, []);

    const handleUploadData = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("item_id", Item_ID);
        // formData.append("customer_id", selectedPricebandID);

        formData.append("sales1_id", Sales1_ID);
        formData.append("sales1_commission_type", discountWith);
        formData.append("sales1_commission", discount);
        
        formData.append("sales2_id", Sales2_ID);
        formData.append("sales2_commission_type", discountWith2);
        formData.append("sales2_commission", discount2);
        
        formData.append("sales3_id", Sales3_ID);
        formData.append("sales3_commission_type", discountWith3);
        formData.append("sales3_commission", discount3);

        formData.append("sales4_id", Sales4_ID);
        formData.append("sales4_commission_type", discountWith4);
        formData.append("sales4_commission", discount4);

        formData.append("sales5_id", Sales5_ID);
        formData.append("sales5_commission_type", discountWith5);
        formData.append("sales5_commission", discount5);

        try {
            const response = await fetch(
                "https://laravel.snackhouseuk.com/api/admin/commission/create",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: formData,
                }
            );

            const result = await response.json();
            console.log(result, "-------result");
            if (response.ok) {
                toast.success(result.message || "Data saved successfully");
                props.onHide();
                props.onSuccess();
            } else {
                toast.error(result.message || "An error occurred");
            }
        } catch (error) {
            console.error("error", error);
            toast.error("An error occurred while uploading data");
        } finally {
            setLoading(false);
        }
    };

    const fetchCategoriesList = () => {
        fetchDataFromAPI(
            `/admin/item/listing`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                if (response.status === true) {
                    setitemData(response?.data);
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                console.log(error, "---error");
            });
    };

    const fetchSalesList = () => {
        fetchDataFromAPI(
            `/admin/listing`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                if (response.status === true) {
                    setSalesData(response?.data);
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                console.log(error, "---error");
            });
    };

    const fetchPricebandList = (page) => {
        fetchDataFromAPI(
            `/admin/customer/listing`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                if (response.status === true) {
                    setPriceBand(response?.data);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                console.error("Error fetching priceband list:", error);
            });
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">
                            Add Commission
                        </h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form
                            className="needs-validation"
                            noValidate
                            onSubmit={handleUploadData}
                        >
                            <div className="mb-3">
                                <label htmlFor="item_id" className="form-label">
                                    Item
                                </label>
                                <select
                                    value={Item_ID}
                                    className="form-control"
                                    id="item_id"
                                    onChange={(e) => setItem_ID(e.target.value)}
                                    required
                                >
                                    <option value="">Select Item</option>
                                    {ItemData?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband.item_code}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please select an item.</div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">
                                    Customer
                                </label>
                                <select
                                    value={selectedPricebandID}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setSelectedPricebandID(e.target.value)}
                                    required
                                >
                                    <option value="">Customer List</option>
                                    {priceBand?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband.email}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please select a customer.</div>
                            </div>

                            {/* Sales List 1 */}
                            <div className="d-flex mb-3 justify-content-between">
                                <div className="mb-3">
                                    <label htmlFor="sales1_id" className="form-label">
                                        Sales List 1
                                    </label>
                                    <select
                                        value={Sales1_ID}
                                        className="form-control"
                                        id="sales1_id"
                                        onChange={(e) => setSales1_ID(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Item</option>
                                        {salesData?.map((priceband) => (
                                            // console.log(priceband,"-----priceband")
                                            <option key={priceband.id} value={priceband.id}>
                                                {`${priceband.sales_code} - ${priceband.email}`}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">Please select a sales item.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount" className="form-label">Sales Commission 1</label>
                                    <input
                                        type="number"
                                        value={discount}
                                        className="form-control"
                                        id="discount"
                                        placeholder="Discount"
                                        onChange={(e) => setDiscount(e.target.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter Commission 1.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount_with" className="form-label">Sales Commission type 1</label>
                                    <select
                                        value={discountWith}
                                        className="form-control"
                                        id="discount_with"
                                        onChange={(e) => setDiscountWith(e.target.value)}
                                        required
                                    >
                                        <option value="0">Percentage</option>
                                        <option value="1">Value Off</option>
                                    </select>
                                    <div className="invalid-feedback">Please select Commission type.</div>
                                </div>
                            </div>

                            {/* Sales List 2 */}
                            <div className="d-flex mb-3 justify-content-between">
                                <div className="mb-3">
                                    <label htmlFor="sales2_id" className="form-label">
                                        Sales List 2
                                    </label>
                                    <select
                                        value={Sales2_ID}
                                        className="form-control"
                                        id="sales2_id"
                                        onChange={(e) => setSales2_ID(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Item</option>
                                        {salesData?.map((priceband) => (
                                            <option key={priceband.id} value={priceband.id}>
                                                {`${priceband.sales_code} - ${priceband.email}`}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">Please select a sales item.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount2" className="form-label">Sales Commission 2</label>
                                    <input
                                        type="number"
                                        value={discount2}
                                        className="form-control"
                                        id="discount2"
                                        placeholder="Discount"
                                        onChange={(e) => setDiscount2(e.target.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter Commission 2.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount_with2" className="form-label">Sales Commission type 2</label>
                                    <select
                                        value={discountWith2}
                                        className="form-control"
                                        id="discount_with2"
                                        onChange={(e) => setDiscountWith2(e.target.value)}
                                        required
                                    >
                                        <option value="0">Percentage</option>
                                        <option value="1">Value Off</option>
                                    </select>
                                    <div className="invalid-feedback">Please select Commission type.</div>
                                </div>
                            </div>

                            {/* Sales List 3 */}
                            <div className="d-flex mb-3 justify-content-between">
                                <div className="mb-3">
                                    <label htmlFor="sales3_id" className="form-label">
                                        Sales List 3
                                    </label>
                                    <select
                                        value={Sales3_ID}
                                        className="form-control"
                                        id="sales3_id"
                                        onChange={(e) => setSales3_ID(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Item</option>
                                        {salesData?.map((priceband) => (
                                            <option key={priceband.id} value={priceband.id}>
                                                {`${priceband.sales_code} - ${priceband.email}`}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">Please select a sales item.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount3" className="form-label">Sales Commission 3</label>
                                    <input
                                        type="number"
                                        value={discount3}
                                        className="form-control"
                                        id="discount3"
                                        placeholder="Discount"
                                        onChange={(e) => setDiscount3(e.target.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter Commission 3.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount_with3" className="form-label">Sales Commission type 3</label>
                                    <select
                                        value={discountWith3}
                                        className="form-control"
                                        id="discount_with3"
                                        onChange={(e) => setDiscountWith3(e.target.value)}
                                        required
                                    >
                                        <option value="0">Percentage</option>
                                        <option value="1">Value Off</option>
                                    </select>
                                    <div className="invalid-feedback">Please select Commission type.</div>
                                </div>
                            </div>

                            {/* Sales List 4 */}
                            <div className="d-flex mb-3 justify-content-between">
                                <div className="mb-3">
                                    <label htmlFor="sales4_id" className="form-label">
                                        Sales List 4
                                    </label>
                                    <select
                                        value={Sales4_ID}
                                        className="form-control"
                                        id="sales4_id"
                                        onChange={(e) => setSales4_ID(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Item</option>
                                        {salesData?.map((priceband) => (
                                            <option key={priceband.id} value={priceband.id}>
                                                {`${priceband.sales_code} - ${priceband.email}`}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">Please select a sales item.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount3" className="form-label">Sales Commission 4</label>
                                    <input
                                        type="number"
                                        value={discount4}
                                        className="form-control"
                                        id="discount3"
                                        placeholder="Discount"
                                        onChange={(e) => setDiscount4(e.target.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter Commission 4.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount_with3" className="form-label">Sales Commission type 4</label>
                                    <select
                                        value={discountWith4}
                                        className="form-control"
                                        id="discount_with3"
                                        onChange={(e) => setDiscountWith4(e.target.value)}
                                        required
                                    >
                                        <option value="0">Percentage</option>
                                        <option value="1">Value Off</option>
                                    </select>
                                    <div className="invalid-feedback">Please select Commission type.</div>
                                </div>
                            </div>

                            {/* Sales List 5 */}
                            <div className="d-flex mb-3 justify-content-between">
                                <div className="mb-3">
                                    <label htmlFor="sales5_id" className="form-label">
                                        Sales List 5
                                    </label>
                                    <select
                                        value={Sales5_ID}
                                        className="form-control"
                                        id="sales5_id"
                                        onChange={(e) => setSales5_ID(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Item</option>
                                        {salesData?.map((priceband) => (
                                            <option key={priceband.id} value={priceband.id}>
                                               {`${priceband.sales_code} - ${priceband.email}`}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">Please select a sales item.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount3" className="form-label">Sales Commission 5</label>
                                    <input
                                        type="number"
                                        value={discount5}
                                        className="form-control"
                                        id="discount3"
                                        placeholder="Discount"
                                        onChange={(e) => setDiscount5(e.target.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter Commission 5.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount_with3" className="form-label">Sales Commission type 5</label>
                                    <select
                                        value={discountWith5}
                                        className="form-control"
                                        id="discount_with3"
                                        onChange={(e) => setDiscountWith5(e.target.value)}
                                        required
                                    >
                                        <option value="0">Percentage</option>
                                        <option value="1">Value Off</option>
                                    </select>
                                    <div className="invalid-feedback">Please select Commission type.</div>
                                </div>
                            </div>

                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-50"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-50"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? "Saving..." : "Save"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SellerCommisionModal;
