import React, { useContext, useEffect, useRef, useState } from "react";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import { toast } from "react-toastify";
import useApi from "../../service/useApi";
import { format } from "date-fns";
import { fetchDataFromAPI } from "../../service/api";
import { PricebandContext } from "../../context/PricebandContext";
import { getUser } from "../../service/constants";
import AdminOrderStatus from "../../commponets/AdminOrderStatus";
import AdminOrderPaymentStatus from "../../commponets/AdminOrderPaymentStatus";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { saveAs } from 'file-saver';
import Pagination from "../../commponets/Pagination";
import axios from "axios";
import { Button } from "react-bootstrap";

function AdminOrder() {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowPaymentOrder, setModalShowPaymentOrder] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [aItemList, setAllItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const { apiAction } = useApi();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [allPricebandList, setallPricebandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setPricebandData } = useContext(PricebandContext);
  console.log('orderStatus--------------------------------', orderStatus);
  const user = JSON.parse(getUser());
  const [totalItems, setTotalItems] = useState(null);
  useEffect(() => {
    fetchPricebandList(currentPage);
  }, [currentPage, orderStatus, paymentStatus]);

  useEffect(() => {
    filterPricebandList();
  }, [searchTerm, aItemList]);

  const fetchPricebandList = (page) => {
    setLoading(true);
    fetchDataFromAPI(
      `/admin/order/list?perPage=10&page=${page}&orderStatus=${orderStatus}&paymentStatus=${paymentStatus}&itemSearch=${searchTerm}`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false);
        if (response?.status === true) {
          const pricebandData = response?.data?.data;
          setAllItemList(pricebandData);
          setTotalItems(response?.data?.total)
          console.log("priceband data: ", response?.data?.total);
          setFilteredPricebandList(pricebandData);
          setallPricebandList(pricebandData)
          setTotalPages(response?.data?.last_page);
        } else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "---error");
      });
  };

  const filterPricebandList = () => {
    if (searchTerm?.trim() === "") {
      setFilteredPricebandList(aItemList);
    } else {
      const filteredList = aItemList.filter((item) =>
        item.order_number?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPricebandList(filteredList);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleModalSuccess = (newStatus) => {
    const updatedItemList = aItemList.map((item) => {
      if (item.id === selectedItemId) {
        return { ...item, status: newStatus };
      }
      return item;
    });
    setOrderStatus(updatedItemList)
    setAllItemList(updatedItemList);
    setFilteredPricebandList(updatedItemList);
    setModalShow(false);
  };

  const handleModalPaymentSuccess = (newStatus) => {
    const updatedItemList = aItemList.map((item) => {
      if (item.id === selectedItemId) {
        return { ...item, payment_status: newStatus };
      }
      return item;
    });
    setAllItemList(updatedItemList);
    setFilteredPricebandList(updatedItemList);
    setModalShowPaymentOrder(false);
  };

  const handleChangeStatusClick = (itemId) => {
    setSelectedItemId(itemId);
    setModalShow(true);
  };

  const handleChangeStatusPaymentClick = (itemId) => {
    setSelectedItemId(itemId);
    setModalShowPaymentOrder(true);
  };


  const exportOrderData = () => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    myHeaders.append("Authorization", `Bearer ${user.token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://laravel.snackhouseuk.com/api/admin/order/export", requestOptions)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'order_data.xlsx'; // Set the filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);

        // Show success toast
        toast.success('Order data exported successfully!');
        setLoading(false)
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Failed to export order data. Please try again.');
        setLoading(false)
      });
  };

  const exportPDF = async (orderNumber) => {
    try {
      setLoading(true)
        const token = user?.token; 
        const response = await axios.get(`https://laravel.snackhouseuk.com/api/admin/order/pdf/export?orderNumber=${orderNumber}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: 'blob', // Important to handle binary data
        });

        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `DeliveryNote_${orderNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoading(false)
    } catch (error) {
        console.error('Error downloading the PDF:', error);
        setLoading(false)
    }
};


  return (
    <>
      <AdminOrderStatus
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSuccess={handleModalSuccess}
        itemId={selectedItemId}
        onUpdate={() => fetchPricebandList(currentPage)}
      />
      <AdminOrderPaymentStatus
        show={modalShowPaymentOrder}
        onHide={() => setModalShowPaymentOrder(false)}
        onSuccess={handleModalPaymentSuccess}
        itemId={selectedItemId}
        onUpdate={() => fetchPricebandList(currentPage)}
      />
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>Customer Order List</h2>
                  </div>
                  <button className="btn btn-primary"
                    onClick={exportOrderData}>Export Excel</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                      <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                        <select
                          className="form-select"
                          value={orderStatus}
                          onChange={(e) => setOrderStatus(e.target.value)}
                        >
                          <option value="">Select order status</option>
                          <option value="0">Created</option>
                          <option value="1">Accepted</option>
                          <option value="2">Cancelled</option>
                          <option value="3">Delivered</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                        <select
                          className="form-select"
                          value={paymentStatus}
                          onChange={(e) => setPaymentStatus(e.target.value)}
                        >
                          <option value="">Select payment status</option>
                          <option value="0">Pending</option>
                          <option value="1">Paid</option>
                          <option value="2">Cancelled</option>
                          <option value="3">Refunded</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      {loading ? (
                        <div className="text-center py-5">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>Order Number</th>
                              <th>Order Status</th>
                              <th>Payment Status</th>
                              <th>Total</th>
                              <th>Number of items</th>
                              <th>Created at</th>
                              <th>Updated at</th>
                              <th>Export PDF</th>
                              {/* <th/> */}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredPricebandList.length > 0 ? (
                                 Array.isArray(filteredPricebandList) &&
                                 filteredPricebandList.map((item) => (
                                   <tr key={item.id}>
                                     <td>
                                       <Link to={`/order-view/${item?.order_number}`}>
                                         <a className="text-reset" href="#">
                                           {item?.order_number}
   
                                         </a>
                                       </Link>
                                     </td>
                                   
                                     {/* </td> */}
                                     <td>
                                       {item?.status == 0 && (
                                         <button
                                           onClick={() =>
                                             handleChangeStatusClick(item?.id)
                                           }
                                           className="btn btn-dark-secondary w-100"
                                         >
                                           Created
                                         </button>
                                       )}
                                       {item?.status == 1 && (
                                         <button
                                           onClick={() =>
                                             handleChangeStatusClick(item?.id)
                                           }
                                           className="btn btn-dark-secondary w-100"
                                         >
                                           Accepted
                                         </button>
                                       )}
                                       {item?.status == 2 && (
                                         <button
                                           onClick={() =>
                                             handleChangeStatusClick(item?.id)
                                           }
                                           className="btn btn-dark-secondary w-100"
                                         >
                                           Cancelled
                                         </button>
                                       )}
                                       {item?.status == 3 && (
                                         <button
                                           onClick={() =>
                                             handleChangeStatusClick(item?.id)
                                           }
                                           className="btn btn-dark-secondary w-100"
                                         >
                                           Delivered
                                         </button>
                                       )}
                                     </td>
                                     <td>
                                       {item?.payment_status == 0 && (
                                         <button
                                           onClick={() =>
                                             handleChangeStatusPaymentClick(
                                               item?.id
                                             )
                                           }
                                           className="btn btn-dark-secondary w-100"
                                         >
                                           Pending
                                         </button>
                                       )}
                                       {item?.payment_status == 1 && (
                                         <button
                                           onClick={() =>
                                             handleChangeStatusPaymentClick(
                                               item?.id
                                             )
                                           }
                                           className="btn btn-dark-secondary w-100"
                                         >
                                           Paid
                                         </button>
                                       )}
                                       {item?.payment_status == 2 && (
                                         <button
                                           onClick={() =>
                                             handleChangeStatusPaymentClick(
                                               item?.id
                                             )
                                           }
                                           className="btn btn-dark-secondary w-100"
                                         >
                                           Cancelled
                                         </button>
                                       )}
                                       {item?.payment_status == 3 && (
                                         <button
                                           onClick={() =>
                                             handleChangeStatusPaymentClick(
                                               item?.id
                                             )
                                           }
                                           className="btn btn-dark-secondary w-100"
                                         >
                                           Refunded
                                         </button>
                                       )}
                                     </td>
                                     <td>
                                       {item?.totalGrossValue}
                                     </td>
                                     <td>{item.order_line?.length}</td>
                                     <td>
                                       {format(
                                         new Date(item?.created_at),
                                         "yyyy-MM-dd"
                                       )}
                                     </td>
                                     <td>
                                       {format(
                                         new Date(item?.updated_at),
                                         "yyyy-MM-dd"
                                       )}
                                     </td>
                                     <td>
                                     <button className="btn btn-primary" onClick={()=>exportPDF(item?.order_number)}>
                                      Export PDF
                                    </button>
                                     </td>
                                   </tr>
                                 ))
                            ) : (
                              <tr>
                              <td colSpan="9" className="text-center">No Customer Order List found.</td>
                            </tr>
                            )}
                         
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div className="border-top d-md-flex justify-content-between align-items-center px-6 py-6">
                      <span>Showing {currentPage} to {allPricebandList?.length} of {totalItems} entries</span>
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminOrder;
