import React, { useState } from 'react'
import logo from "../../assets/images/logo/freshcart-logo.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BsCart, BsFillHouseDoorFill ,BsFillPersonFill} from "react-icons/bs";
import constants, { getUser } from '../../service/constants';
import { toast } from 'react-toastify';
import profile from "../../assets/images/avatar/user.png"
function SellerProfile() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const handleNavLinkClick = (path) => {
    setActiveLink(path);
  };
console.log(user,"--------user");
  const handleLogout = () => {
    localStorage.removeItem(constants.USER);
    toast.success('Successfully logged out.');
    navigate('/sellerLogin');
    window.location.reload();
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  return (
    <>
      <div>
        {/* navbar */}
        <nav className="navbar navbar-expand-lg navbar-glass">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center">
                <a
                  className="text-inherit d-block d-xl-none me-4"
                  data-bs-toggle="offcanvas"
                  href="index.html#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    fill="currentColor"
                    className="bi bi-text-indent-right"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm10.646 2.146a.5.5 0 0 1 .708.708L11.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zM2 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </a>
                <form role="search">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>
              </div>
              <div>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profile}
                        alt=""
                        className="avatar avatar-md rounded-circle"
                        style={{ height: '45px', width: '45px' }}
                      />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                      <li className="px-4 py-3">
                      <h5 className="mb-1 h6">{user?.sales_code}</h5>
                      <h5 className="mb-1 h6">{user?.name}</h5>
                      <small>{user?.email}</small>
                    </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li className="px-4 py-2">
                        <a href="#" onClick={handleLogout}>Log Out</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div className="main-wrapper">
          <nav
            className="navbar-vertical-nav offcanvas offcanvas-start navbar-offcanvac"
            tabIndex={-1}
            id="offcanvasExample"
          >
            <div className="navbar-vertical">
              <div className="px-4 py-5 d-flex justify-content-between align-items-center">
                <a href="../index.html" className="navbar-brand">
                  <img src={logo} alt="" />
                </a>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div className="navbar-vertical-content flex-grow-1" data-simplebar="">
                <ul className="navbar-nav flex-column" id="sideNavbar">
                  <li className="nav-item">
                    <Link className={`nav-link ${location.pathname === '/sellerDashboard' ? 'active' : ''}`}
                      to="/sellerDashboard"
                      onClick={() => handleNavLinkClick('/sellerDashboard')}>
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-house"></i><BsFillHouseDoorFill />
                        </span>
                        <span className="nav-link-text">Seller Dashboard</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item mt-6 mb-3">
                    <span className="nav-label">Store Managements</span>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/sellerOrderList' ? 'active' : ''}`}
                      to="/sellerOrderList"
                      onClick={() => handleNavLinkClick('/sellerOrderList')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-cart"></i><BsCart /></span>
                        <span className="nav-link-text">Order List
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/allocated-customer' ? 'active' : ''}`}
                      to="/allocated-customer"
                      onClick={() => handleNavLinkClick('/allocated-customer')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-cart"></i><BsFillPersonFill  /></span>
                        <span className="nav-link-text">Allocated Customer
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

    </>
  )
}

export default SellerProfile