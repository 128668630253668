import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../../assets/libs/tiny-slider/dist/tiny-slider.css';
import '../../assets/libs/slick-carousel/slick/slick.css';
import '../../assets/libs/slick-carousel/slick/slick-theme.css';
import '../../assets/libs/simplebar/dist/simplebar.min.css';
import '../../assets/css/theme.min.css';
// import "../../../src/Screens/AdminNavbar/AdminNavbar.css";
import Logo from "../../../src/assets/images/logo/116x116.png";
import { BsCart, BsFillHouseDoorFill ,BsFillPersonFill  } from "react-icons/bs";

function SellerNavbar({ isSidebarVisible }) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  const handleNavLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <nav className={`navbar-vertical-nav d-xl-block ${isSidebarVisible ? 'd-block' : 'd-none'}`}>
      <div className="navbar-vertical">
        <div className="d-flex justify-content-center align-items-center px-4 py-5">
          <a href="" className="navbar-brand">
            <img src={Logo} alt="FreshCart Logo" />
          </a>
        </div>
        <div className="navbar-vertical-content flex-grow-1" data-simplebar="">
          <ul className="navbar-nav flex-column" id="sideNavbar">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/sellerDashboard' ? 'active' : ''}`}
                to="/sellerDashboard"
                onClick={() => handleNavLinkClick('/sellerDashboard')}>
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-house"></i><BsFillHouseDoorFill />
                  </span>
                  <span className="nav-link-text">Seller Dashboard</span>
                </div>
              </Link>
            </li>
            <li className="nav-item mt-6 mb-3">
              <span className="nav-label">Store Managements</span>
            </li>
           
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/sellerOrderList' ? 'active' : ''}`}
                to="/sellerOrderList"
                onClick={() => handleNavLinkClick('/sellerOrderList')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-cart"></i><BsCart /></span>
                  <span className="nav-link-text">Order List
                  </span>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/allocated-customer' ? 'active' : ''}`}
                to="/allocated-customer"
                onClick={() => handleNavLinkClick('/allocated-customer')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-cart"></i><BsFillPersonFill /></span>
                  <span className="nav-link-text">Allocated Customer
                  </span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default SellerNavbar;
