import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../service/useApi';
import { getUser } from '../service/constants';

const EditItemsModal = (props) => {
    const [categoryName, setCategoryName] = useState('');
    const [categoryPriority, setCategoryPriority] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false); // State for loader
    const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
    const user = JSON.parse(getUser());

    useEffect(() => {
        if (!props.show) {
            setCategoryName('');
            setCategoryPriority('');
            setImage(null);
        }
    }, [props.show]);

    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loader

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${user?.token}`);

        const formdata = new FormData();
        formdata.append("name", categoryName);
        formdata.append("image", image);
        formdata.append("priority", categoryPriority);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://laravel.snackhouseuk.com/api/admin/category/create", requestOptions);
            const result = await response.json();

            if (response.ok) {
                toast.success(result.message || "Category created successfully!");
                props.onHide();
                props.onSuccess();
            } else {
                toast.error(result.message || "Failed to create category.");
            }
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error("An error occurred while creating the category.");
        } finally {
            setLoading(false); // Hide loader
        }
    };

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Add Categories</h5>
                        <Button variant="close" onClick={props.onHide}></Button>
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="categoryname" className="form-label">Categories Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryname"
                                    placeholder="Category Name"
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter item name.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="categorypriority" className="form-label">Categories Priority</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="categorypriority"
                                    placeholder="Category Priority"
                                    value={categoryPriority}
                                    onChange={(e) => setCategoryPriority(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter Categories Priority.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Upload Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    onChange={handleFileChange}
                                    required
                                />
                                <div className="invalid-feedback">Please upload an image.</div>
                            </div>
                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EditItemsModal;
