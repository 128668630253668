import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { PricebandContext } from '../context/PricebandContext';
import { fetchDataFromAPI } from '../service/api';
import { getUser } from '../service/constants';

function AdminPopularModal(props) {
    const [Item_ID, setItem_ID] = useState('');
    const [categoryPriority, setCategoryPriority] = useState('');
    const [ItemData, setitemData] = useState([]);
    const [loading, setLoading] = useState(false);
    const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
    const { pricebandData } = useContext(PricebandContext)
    const user = JSON.parse(getUser());

    useEffect(()=>{
        fetchPricebandList()
    },[])
    const handleUploadData = (e) => {
        e.preventDefault();
        if (!Item_ID || !categoryPriority) {
            toast.error('All fields are required');
            return;
        }

        if (isNaN(categoryPriority)) {
            toast.error('Categorie Priority must be a number');
            return;
        }

        setLoading(true);

        const requestBody = {
            item_id: Item_ID,
            priority:categoryPriority,
        };

        console.log('requestBody --->', requestBody);

        fetchDataFromAPI("/admin/popular/create", "post", requestBody, user?.token)
            .then((response) => {
                console.log(response, "---response");
                if (response.status == true) {
                    toast.success(response.data);
                    props.onHide();
                    props.onSuccess();
                } else {
                    if (response.data && response.data.item_code) {
                        toast.error(response.data.item_code[0]);
                    } else {
                        toast.error(response.data || "An error occurred");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
                if (error.response && error.response.data) {
                    console.log("Server response:", error.response.data);
                    toast.error(error.response.data.message || "An error occurred while uploading data");
                } else {
                    toast.error("An error occurred while uploading data");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchPricebandList = () => {
        fetchDataFromAPI(
            `/admin/item/dropdwon/list`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                // setLoading(true);
                if (response.status == true) {
                    console.log("response--->>", response);
                    setitemData(response?.data)
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                // setLoading(false)
                console.log(error, "---error");
            });
    };


    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Add Popular</h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleUploadData}>
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">Item</label>
                                <select
                                    value={Item_ID}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setItem_ID(e?.target?.value)}
                                    required
                                >
                                    <option value="">Item</option>
                                    {ItemData?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{priceband?.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="categorypriority" className="form-label">Categorie Priority</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="categorypriority"
                                    placeholder="Categorie Priority"
                                    value={categoryPriority}
                                    onChange={(e) => setCategoryPriority(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter Categorie Priority.</div>
                            </div>
                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminPopularModal;
