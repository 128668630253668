import React, { useState } from 'react';
import signin from "../../assets/images/banner/size332x332.png";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../../service/api';
import { saveUserLocally } from '../../service/constants';

function AdminLogin({ onLogin }) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        setEmailError("");
        setPasswordError("");

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            return;
        }

        if (password.length < 6) {
            setPasswordError("The password field must be at least 6 characters.");
            return;
        }

        setLoading(true); // Set loading to true when starting the API call

        const body = {
            email: email,
            password: password,
        };
        fetchDataFromAPI("/admin/login", "post", body, "")
            .then((res) => {
                setLoading(false); // Set loading to false when API call is complete
                if (res.status === true && res) {
                    saveUserLocally(JSON.stringify(res?.data));
                    toast.success("Login successful!");
                    navigate("/adminDashboard");
                } else {
                    console.log(res, "--res");
                    toast.error(res.data || res.errors[0]);
                }
            })
            .catch((error) => {
                setLoading(false); // Set loading to false when API call fails
                console.log("error", error);
            });
    };

    return (
        <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
            <section className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <img src={signin} alt="Sign In" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 offset-lg-1">
                        <div className="mb-4">
                            <h1 className="h2 fw-bold mb-1">Sign in to Snackhouseuk</h1>
                            <p>Welcome back to Snackhouseuk! Enter your email to get started.</p>
                        </div>
                        <form className="needs-validation" noValidate="" onSubmit={handleSignIn}>
                            <div className="mb-3">
                                <label htmlFor="formSigninEmail" className="form-label">Email address</label>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                    id="formSigninEmail"
                                    placeholder="Email"
                                    required=""
                                />
                                <div className="invalid-feedback">
                                    {emailError}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="formSigninPassword" className="form-label">Password</label>
                                <input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                    id="formSigninPassword"
                                    placeholder="*****"
                                    required=""
                                />
                                <div className="invalid-feedback">
                                    {passwordError}
                                </div>
                            </div>
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading ? 'Signing In...' : 'Sign In'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default AdminLogin;
