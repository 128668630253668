import React, { useState } from 'react';
import register from '../../assets/images/banner/size332x332.png';
import { Link, useNavigate } from 'react-router-dom';
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';

function SellerSignUp() {
    const navigate = useNavigate();
    const [emails, setEmails] = useState('');
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');

    const handleSignUp = (e) => {
        e.preventDefault();
        const requestBody = {
            name: name,
            email: emails,
            contact: contact,
        };
        fetchDataFromAPI("/sale/register", "post", requestBody)
            .then((response) => {
                console.log('response seller signup--->', response);
                if (response.status  === true ) {
                    toast.success(response?.message);
                    navigate("/sellerDashboard");
                } else {
                    if (response.errors && typeof response.errors === 'object') {
                        for (const [key, value] of Object.entries(response.errors)) {
                            if (Array.isArray(value)) {
                                value.forEach((error) => {
                                    toast.error(`${key}: ${error}`);
                                    console.log(`${key}: ${error}`, "--error");
                                });
                            }
                        }
                    } else {
                        toast.error(response.message || "Failed to update item.");
                    }
                }
            })
            .catch((error) => {
                toast.error("An error occurred while uploading data");
            });
    };
    
    return (
        <section className="my-lg-14 my-8">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <img src={register} alt="Sign In" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
                        <div className="mb-lg-9 mb-5">
                            <h1 className="mb-1 h2 fw-bold">Get Start Shopping</h1>
                            <p>Welcome to Snackhouseuk! Enter your email to get started.</p>
                        </div>
                        <form className="needs-validation" noValidate="" onSubmit={handleSignUp}>
                            <div className="row g-3">
                                <div className="col-12">
                                    <label htmlFor="formSignupName" className="form-label visually-hidden">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formSignupName"
                                        placeholder="Name"
                                        required=""
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <div className="invalid-feedback">Please enter your name.</div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="formSignupEmail" className="form-label visually-hidden">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="formSignupEmail"
                                        placeholder="Email"
                                        required=""
                                        value={emails}
                                        onChange={(e) => setEmails(e.target.value)}
                                    />
                                    <div className="invalid-feedback">Please enter your email.</div>
                                </div>

                                <div className="col-12">
                                    <label htmlFor="formSignupContact" className="form-label visually-hidden">
                                        Contact
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formSignupContact"
                                        placeholder="Contact"
                                        required=""
                                        value={contact}
                                        onChange={(e) => setContact(e.target.value)}
                                    />
                                    <div className="invalid-feedback">Please enter your contact.</div>
                                </div>

                                <div className="col-12 d-grid">
                                    <button type="submit" className="btn btn-primary">
                                        Register
                                    </button>
                                </div>
                                <div className="mt-3 text-center">
                                    Already have an account?
                                    <Link to="/sellerLogin">
                                        <span> Sign In</span>
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SellerSignUp;
