// import React from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import constants from "../service/constants";
// import { toast } from "react-toastify";

// function SideOrderList() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const btnLogOut = () => {
//     localStorage.removeItem(constants.USER);
//     toast.success("Successfully logged out.");
//     navigate("/dashboard");
//     window.location.reload();
//   };

//   return (
//     <div className="col-lg-3 col-md-4 col-12 border-end">
//       <div className="pt-10 pe-lg-10">
//         <ul className="nav flex-column nav-pills nav-pills-dark">
//           <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 location.pathname === "/orderList" ? "active" : ""
//               }`}
//               aria-current="page"
//               to={"/orderList"}
//             >
//               <i className="feather-icon icon-shopping-bag me-2" />
//               Your Orders
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 location.pathname === "/cancelOrder" ? "active" : ""
//               }`}
//               to={`/cancelOrder`}
//             >
//               <i className="feather-icon icon-map-pin me-2" />
//               Cancel Order
//             </Link>
//           </li>
//           <li className="nav-item">
//             <hr className="d-md-none" />{" "}
//             {/* Hide horizontal rule on medium and larger screens */}
//           </li>
//           <li className="nav-item">
//             <div className="nav-link" onClick={() => btnLogOut()}>
//               <i className="feather-icon icon-log-out me-2" />
//               Log out
//             </div>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default SideOrderList;




import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BsMenuUp } from "react-icons/bs";

function SideOrderList() {
  const location = useLocation();

  return (
    <>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center d-md-none py-4">
          <h3 className="fs-5 mb-0">Account setting</h3>
          <button
            className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasAccount"
            aria-controls="offcanvasAccount"
          >
            <BsMenuUp />
          </button>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-12 border-end d-none d-md-block">
        <div className="pt-10 pe-lg-10">
          <ul className="nav flex-column nav-pills nav-pills-dark">
            <li className="nav-item">
              <Link to={'/orderList'} className={`nav-link ${location.pathname === '/orderList' ? 'active' : ''}`}>
                <i className="feather-icon icon-shopping-bag me-2" />
                Your orders
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/cancelOrder'} className={`nav-link ${location.pathname === '/cancelOrder' ? 'active' : ''}`}>
                <i className="feather-icon icon-settings me-2" />
                Cancelled orders
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/settings'} className={`nav-link ${location.pathname === '/settings' ? 'active' : ''}`}>
                <i className="feather-icon icon-settings me-2" />
                Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasAccount"
        aria-labelledby="offcanvasAccountLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <ul className="nav flex-column nav-pills nav-pills-dark">
            <li className="nav-item">
              <Link to={'/orderList'} className={`nav-link ${location.pathname === '/orderList' ? 'active' : ''}`}>
                <i className="feather-icon icon-shopping-bag me-2" />
                Your orders
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/cancelOrder'} className={`nav-link ${location.pathname === '/cancelOrder' ? 'active' : ''}`}>
                <i className="feather-icon icon-settings me-2" />
                Cancelled orders
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/settings'} className={`nav-link ${location.pathname === '/settings' ? 'active' : ''}`}>
                <i className="feather-icon icon-settings me-2" />
                Settings
              </Link>
            </li>
          </ul>
          <hr className="my-6" />
        </div>
      </div>
    </>
  )
}

export default SideOrderList;
