import React, { useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from 'react-toastify';
import { BsTrash, BsPencilSquare, BsEye } from "react-icons/bs";
import { format } from 'date-fns';
import { fetchDataFromAPI } from '../../service/api';
import AdminPopularModal from '../../commponets/AdminPopularModal';
import AdminPopularEditModal from '../../commponets/AdminPopularEditModal';
import { getUser } from '../../service/constants';
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import Button from 'react-bootstrap/Button';
import second from '../../assets/images/avatar/coming_soon.jpg';
import Swal from 'sweetalert2';
function AdminPopular() {
  const [modalShow, setModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [viewItem, setViewItem] = useState(false);
  const [aItemList, setAllItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemData, setEditItemData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [itemStatus, setItemStatus] = useState('Active');
  const user = JSON.parse(getUser());
  const [totalItems, setTotalItems] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  useEffect(() => {
    fetchPricebandList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    filterPricebandList();
  }, [searchTerm, aItemList]);

  const fetchPricebandList = (page) => {
    setLoading(true);
    fetchDataFromAPI(
      `/admin/popular/list?perPage=10&page=${page}&itemSearch=${searchTerm}`,
      "get",
      "",
      user?.token)
      .then((response) => {
        setLoading(false);
        if (response.status == true) {
          const pricebandData = response?.data?.data;
          setTotalItems(response?.data?.total)
          localStorage.setItem('Items', JSON.stringify(pricebandData));
          setAllItemList(pricebandData);
          setFilteredPricebandList(pricebandData);
          setTotalPages(response?.data?.last_page);
        } else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "---error");
      });
  };

  const handleDeleteItem = (itemId) => {
    fetchDataFromAPI(
      `/admin/popular/delete/${itemId}`,
      "delete",
      "",
      user?.token
    )
      .then((response) => {
        if (response.status == true) {
          toast.success(response?.data);
          fetchPricebandList();
          setDeleteModalShow(false);
        } else {
          toast.error(response?.data);
          setLoading(false); // Hide loader
          setDeleteModalShow(false);
          setDeleteItemId(null);
        }
      })
      .catch((error) => {
        console.log(error, "---error");
      });
  };
  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };

  const handleItemStatus = (itemId) => {
    const newStatus = itemStatus === 'Active' ? 'Inactive' : 'Active'; // Toggle status
    const requestBody = new FormData();
    requestBody.append('id', itemId);
    fetchDataFromAPI(
      `/admin/popular/status`,
      'post',
      requestBody,
      user?.token
    )
      .then((response) => {
        if (response.status) {
          toast.success(response?.message);
          setItemStatus(newStatus); // Update local state
        } else {
          toast.error(response?.messages);
        }
      })
      .catch((error) => {
        console.log(error, '---error');
      });
  };

  const handleStatus = (itemId) => {
    console.log(itemId,"---itemId");
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newStatus = itemStatus === 'Active' ? 'Inactive' : 'Active'; // Toggle status
        const requestBody = new FormData();
        requestBody.append('id', itemId);
  
        try {
          setLoading(true); // Assuming you have a loading state to manage
  
          const response = await fetchDataFromAPI(
            `/admin/popular/status`,
            'post',
            requestBody,
            user?.token
          );
  
          if (response.status) {
            toast.success(response?.message);
            setItemStatus(newStatus); // Update local state
            fetchPricebandList(); // Assuming you want to refetch the list
          } else {
            toast.error(response?.messages || 'Failed to change status, please try again.');
          }
        } catch (error) {
          console.error('Error changing status:', error);
          toast.error('Failed to change status, please try again.');
        } finally {
          setLoading(false); // Stop loading indicator
        }
      } else if (result.isDenied) {
        Swal.fire('Status change cancelled', '', 'info');
      }
    });
  };
  
  // const handleStatus = async (id) => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, change it!',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         setLoading(true);
  //         // The correct structure of axios.post:
  //         const response = await api.post(
  //           `/admin/customer/property/status`,
  //           { id }, // This is the request payload (data)
  //           {
  //             headers: {
  //               'Authorization': `${token}`,
  //               'Accept': 'application/json',
  //             },
  //           }
  //         );

  //         if (response.data.status === true) {
  //           list();
  //           toast.success(response.data.message);
  //         } else {
  //           toast.error(response.data.message || response.data.errors[0]?.msg);
  //         }
  //       } catch (error) {
  //         console.error('Error changing status:', error);
  //         toast.error('Failed to change status, please try again.');
  //       } finally {
  //         setLoading(false);
  //       }
  //     } else if (result.isDenied) {
  //       Swal.fire('Status change cancelled', '', 'info');
  //     }
  //   });
  // };
  const filterPricebandList = () => {
    if (searchTerm.trim() === '') {
      setFilteredPricebandList(aItemList);
    } else {
      const filteredList = aItemList.filter(item =>     
        // console.log(item,"--item")
        item?.item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
      setFilteredPricebandList(filteredList);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleModalSuccess = () => {
    fetchPricebandList();
  };

  const handleEditItemClick = (itemId) => {
    setEditItemData(itemId);
    setEditItemId(itemId);
    setEditModalShow(true);
  };

  const handleViewDetails = (itemId) => {
    setSelectedItemId(itemId);
    setViewItem(true);
  };

  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdminPopularModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
        <AdminPopularEditModal show={editmodalShow} onHide={() => setEditModalShow(false)} itemId={editItemId} onSuccess={handleModalSuccess} itemData={editItemData} />
        <DeleteConfirmationModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => handleDeleteItem(deleteItemId)}
          loading={loading}
        />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>Popular</h2>
                  </div>
                  <div>
                    <a href="#" role="button" onClick={() => setModalShow(true)} className="btn btn-primary">
                      Add New
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                      <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      {loading ? (
                        <div className="text-center py-5">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>Image</th>
                              <th>Item-code</th>
                              <th>Name</th>
                              <th>Stock</th>
                              <th>Price</th>
                              <th>Discount</th>
                              <th>Created at</th>
                              <th>Status</th>
                              <th>Delete &amp; Edit &amp; View</th>
                              </tr>
                          </thead>
                          <tbody>
                            {filteredPricebandList.length > 0 ? (
                              filteredPricebandList?.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                 {console.log(item.id)}
                                    <img
                                      src={item?.item?.itemImage[0]  == null && second ? second : item?.item?.itemImage[0]}
                                      alt=""
                                      className="icon-shape icon-md"
                                      style={{ width: '60px', height: '60px' }}
                                    />
                                  </td>
                                  <td>{item?.item?.item_code}</td>
                                  <td>
                                    <a className="text-reset">
                                      {item?.item?.name}
                                    </a>
                                  </td>
                                  <td>{item?.item?.stock}</td>
                                  <td>
                                    <span className="badge bg-light-primary text-dark-primary">
                                      {item?.item?.price}
                                    </span>
                                  </td>
                                  <td>{item?.item?.discount}</td>
                                  {/* <td>{item?.item?.description}</td> */}
                                  <td>{format(new Date(item.created_at), "dd MMMM yyyy")}</td>
                                  {/* <td>
                                    <button
                                      className="btn-link dropdown-item"
                                      onClick={() => handleItemStatus(item?.id)}>
                                      {itemStatus}
                                    </button>
                                  </td> */}
                                     <td> <button
                                    className={`btn btn-${item?.status == true ? 'success' : 'danger'} btn-sm m-2`}
                                    onClick={() => handleStatus(item?.id)}
                                  >
                                    {/* <BsPencilSquare /> */} {item?.status == true ? 'Active' : 'Deactive'}
                                  </button>
                                  </td>
                                  <td>
                                    <div className="dropdown">
                                      {/* <a
                                        href="categories.html#"
                                        className="text-reset"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <BsThreeDotsVertical />
                                      </a> */}
                                      {/* <ul className="dropdown-menu"> */}
                                        <td>
                                          <button
                                            className="btn-link dropdown-item text-danger"
                                            onClick={() => handleShowDeleteModal(item?.id)}>
                                            <BsTrash />
              
                                          </button>
                                        </td>
                                        <td>
                                          <button
                                            className="btn-link dropdown-item"
                                            onClick={() => handleEditItemClick(item)}>
                                            <BsPencilSquare />
          
                                          </button>
                                        </td>
                                        <td>
                                          <button
                                            className="btn-link dropdown-item"
                                            onClick={() => handleViewDetails(item?.id)}>
                                            <BsEye />
                                            
                                          </button>
                                        </td>
                                      {/* </ul> */}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                              <td colSpan="9" className="text-center">No Popular found.</td>
                            </tr>

                            )}
                            
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="border-top d-flex justify-content-between align-items-md-center px-6 py-6 flex-md-row flex-column gap-4">
                    <span>Showing {currentPage} to  {aItemList?.length} of {totalItems} entries</span>
                  
                       <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminPopular;
