import React, { useContext, useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { toast } from "react-toastify";
import useApi from "../../service/useApi";
import second from "../../assets/images/logo/freshcart-logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/libs/tiny-slider/dist/tiny-slider.css";
import "../../assets/libs/slick-carousel/slick/slick.css";
import "../../assets/libs/slick-carousel/slick/slick-theme.css";
import "../../assets/libs/simplebar/dist/simplebar.min.css";
import "../../assets/css/theme.min.css";
import { Link, useNavigate } from "react-router-dom";
import { PricebandContext } from "../../context/PricebandContext";
import constants, { getUser } from "../../service/constants";

function Navbar() {
 
  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-default py-0 py-lg-2 border-top navbar-offcanvas-color" aria-label="Offcanvas navbar large">
      <div className="container">
      </div>
    </nav>
  );
}

export default Navbar;
