import React, { useState } from 'react';
import register from '../../assets/images/banner/size332x332.png';
import { Link, useNavigate } from 'react-router-dom';
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import { saveUserLocally } from '../../service/constants';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
function UserSignUp() {
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const [emails, setEmailss] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [pincode, setPincode] = useState('');
    const [errors, setErrors] = useState({});
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const selectCountry = (val) => {
        setCountry(val);
        setRegion('');
    };

    const selectRegion = (val) => {
        setRegion(val);
    };
    const handleSignUp = (e) => {
        // setLoading(true);
        e.preventDefault();
        const requestBody = {
            name: name,
            email: emails,
            phone: phone,
            phone2: phone2,
            address: address,
            pincode: pincode,
            password: password,
            city: city,
            state: region,
            country: country,

        };
        fetchDataFromAPI("/user/register", "post", requestBody)
            .then((response) => {
                if (response.status) {
                    console.log(response,"---response");
                    toast.success(response?.message);
                    saveUserLocally(JSON.stringify(response?.data));
                    //   props.onHide();
                    navigate("/");
                } else {
                    if (response.errors && Array.isArray(response.errors)) {
                        response.errors.forEach((error) => {
                            toast.error(error);
                        });
                    } else {
                        toast.error(response.message || "Failed to update item.");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
                toast.error("An error occurred while uploading data");
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    return (
        <section className="my-lg-14 my-8">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <img src={register} alt="Sign In" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
                        <div className="mb-lg-9 mb-5">
                            <h1 className="mb-1 h2 fw-bold">Start Shopping</h1>
                            <p>Welcome to Snackhouseuk! Enter your email to get started.</p>
                        </div>
                        <form className="needs-validation" noValidate="" onSubmit={handleSignUp}>
                            <div className="row g-3">
                                <div className="col-12">
                                    <label htmlFor="name" className="form-label visually-hidden">Name</label>
                                    <input
                                        className="form-control"
                                        id="name"
                                        placeholder="Enter Your Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                    {errors['The name field is required.'] && (
                                        <div className="invalid-feedback d-block">Please enter name.</div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label htmlFor="email" className="form-label visually-hidden">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Enter Email address"
                                        required
                                        value={emails}
                                        onChange={(e) => setEmailss(e.target.value)}
                                    />
                                    {errors['The email field is required.'] && (
                                        <div className="invalid-feedback d-block">Please enter email.</div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label htmlFor="address" className="form-label visually-hidden">Address</label>
                                    <input
                                        className="form-control"
                                        id="address"
                                        placeholder="Enter Address"
                                        required
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                    {errors['The address field is required.'] && (
                                        <div className="invalid-feedback d-block">Please enter address.</div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label htmlFor="pincode" className="form-label visually-hidden">Postcode</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="pincode"
                                        placeholder="Enter Postcode"
                                        required
                                        value={pincode}
                                        onChange={(e) => setPincode(e.target.value)}
                                    />
                                    {errors['The pincode field is required.'] && (
                                        <div className="invalid-feedback d-block">Please enter Postcode.</div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label htmlFor="phone" className="form-label visually-hidden">Phone</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="phone"
                                        placeholder="Enter Phone"
                                        required
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    {errors['The phone field is required.'] && (
                                        <div className="invalid-feedback d-block">Please enter phone.</div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label htmlFor="phone" className="form-label visually-hidden">Mobile</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="phone"
                                        placeholder="Enter Mobile"
                                        required
                                        value={phone2}
                                        onChange={(e) => setPhone2(e.target.value)}
                                    />
                                    {errors['The phone field is required.'] && (
                                        <div className="invalid-feedback d-block">Please enter phone.</div>
                                    )}
                                </div>
                                <div className="col-12">
                                    {/* <label>Country</label> */}
                                    <CountryDropdown
                                        value={country}
                                        onChange={(val) => selectCountry(val)}
                                        style={{ width: '100%', padding: '8px',borderColor:'grey'}}
                                        className="form-label"
                                    />
                                </div>
                                {country && (
                                    <div style={{ marginBottom: '20px' }}>
                                        <label>State</label>
                                        <RegionDropdown
                                            country={country}
                                            value={region}
                                            onChange={(val) => selectRegion(val)}
                                            style={{ width: '100%', padding: '8px',  }}
                                        />
                                    </div>
                                )}
                                {/* </div> */}
                                <div className="col-12">
                                    <label htmlFor="city" className="form-label visually-hidden">City</label>
                                    <input
                                        type="city"
                                        className="form-control"
                                        id="city"
                                        placeholder="Enter City"
                                        required
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                    {errors['The city field is required.'] && (
                                        <div className="invalid-feedback d-block">Please enter city.</div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label htmlFor="password" className="form-label visually-hidden">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Enter Password"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    {errors['The password field is required.'] && (
                                        <div className="invalid-feedback d-block">Please enter password.</div>
                                    )}
                                </div>
                                <div className="col-12 d-grid">
                                    <button type="submit" className="btn btn-primary">
                                        Register
                                    </button>
                                </div>
                                <div className="mt-3 text-center">
                                    Already have an account?
                                    <Link to="/userSignin">
                                        <span> Sign In</span>
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UserSignUp;
