import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "../../service/useApi";
import { fetchDataFromAPI } from "../../service/api";
import { getUser, saveUserLocally } from "../../service/constants";
import SellerProfile from "../SellerProfile/SellerProfile";
import SellerNavbar from "../SellerNavbar/SellerNavbar";
import { useNavigate } from "react-router-dom";
import { BsStarFill, BsEye, BsFillPersonFill } from "react-icons/bs";
import Pagination from "../../commponets/Pagination";
import manifest from "../../assets/images/about/manifest.png";

function AllocatedCustomer() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { apiAction } = useApi();
    const [loading, setLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(null);
    const user = JSON.parse(getUser());

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const navigate = useNavigate()
    const [allCustomer, setAllCustomer] = useState([]);

    useEffect(() => {
        fetchcustomerList();
    }, []);
    const fetchcustomerList = () => {
        setLoading(true); // Show loader
        fetchDataFromAPI(`/sale/allocated/customer/listing`, "get", "", user?.token)
            .then((res) => {
                setLoading(false); // Hide loader
                if (res.status === true) {
                    const customerList = res.data;
                    setTotalItems(res?.data?.total)
                    setAllCustomer(customerList);
                } else {
                    toast.error(res.message);
                }
            })
            .catch((error) => {
                setLoading(false); // Hide loader on error
                console.error("Error fetching priceband list:", error);
            });
    };

    const btnCoustomer = (item) => {
        const res = {
            data: {
                ...item?.customer,
                sellerId: user?.id
            }
        };
        if (res) {
            saveUserLocally(JSON.stringify(res?.data));
            navigate('/dashboard')
        }
        console.log("res---", res);
    };

    return (
        <>
            <SellerProfile />
            <div className="main-wrapper">
                <SellerNavbar />
                <main className="main-content-wrapper">
                    <div className="container">
                        <div className="row mb-8">
                            <div className="col-md-12">
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                                    <div>
                                        <h2>Allocated Customer</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-12 mb-5">
                                <div className="card h-100 card-lg">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-centered table-borderless text-nowrap table-hover">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Number</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                {allCustomer.length > 0 ? (
                                                    allCustomer.map((customer) => {
                                                        return (
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <a class="text-reset">
                                                                            {customer.customer.name}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a class="text-reset">
                                                                            {customer.customer.email}
                                                                        </a>{" "}
                                                                    </td>
                                                                    <td>
                                                                        <a class="text-reset">
                                                                            {customer.customer.phone}
                                                                        </a>{" "}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            onClick={() => {
                                                                                btnCoustomer(customer);
                                                                            }}
                                                                            href="#" class="text-reset"
                                                                        >
                                                                           <img src={manifest} style={{width:'20%',height:'20%'}}/>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        );
                                                    })
                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="9" className="text-center">
                                                                No allocated customers found.
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                )}

                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-top d-md-flex justify-content-between align-items-center px-6 py-6">
                                    <span>Showing {currentPage} to {allCustomer?.length} of {totalItems} entries</span>
                                    {/* <nav className="mt-2 mt-md-0">
                        <ul className="pagination mb-0">
                          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                              Previous
                            </button>
                          </li>
                          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav> */}
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={setCurrentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default AllocatedCustomer;
