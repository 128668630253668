import React, { useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import { BsTrash, BsPencilSquare } from 'react-icons/bs';
import AdminCategoryModal from '../../commponets/AdminCategoryModal';
import { format } from 'date-fns';
import AdminCategoryEditModal from '../../commponets/AdminCategoryEditModal';
import { getUser } from '../../service/constants';
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import { ProgressBar } from 'react-bootstrap';
import second from '../../assets/images/avatar/coming_soon.jpg';
import Button from 'react-bootstrap/Button';
function AdminCategory() {
  const [modalShow, setModalShow] = useState(false);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const { apiAction } = useApi();
  const [editItemData, setEditItemData] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [editmodalShow, setEditModalShow] = useState(false);
  const user = JSON.parse(getUser());
  const [totalItems, setTotalItems] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress

  useEffect(() => {
    fetchPricebandList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    filterPricebandList();
  }, [searchTerm, allPricebandList]);

  const fetchPricebandList = async (page) => {
    setLoading(true);
    try {
      const response = await apiAction({
        url: `/admin/category/list?perPage=10&page=${page}&itemSearch=${searchTerm}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status) {
        const pricebandData = response?.data?.data;
        setTotalItems(response?.data?.total);
        setAllPricebandList(pricebandData);
        localStorage.setItem('category', JSON.stringify(pricebandData));
        setFilteredPricebandList(pricebandData);
        setTotalPages(response?.data?.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching category list:', error);
      toast.error('Failed to fetch category list.');
    } finally {
      setLoading(false);
    }
  };

  const handleModalSuccess = () => {
    fetchPricebandList(currentPage);
  };

  const handleDeleteItem = async (itemId) => {
    setLoading(true);
    try {
      const response = await apiAction({
        url: `/admin/category/delete/${itemId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response?.status) {
        toast.success(response?.message);
        fetchPricebandList(currentPage);
        setDeleteModalShow(false);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item.');
    } finally {
      setLoading(false);
      setDeleteModalShow(false);
      setDeleteItemId(null);
    }
  };

  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const filterPricebandList = () => {
    const filteredList = allPricebandList.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    setFilteredPricebandList(filteredList);
  };

  const handleEditItemClick = (itemId) => {
    setEditItemData(itemId);
    setEditItemId(itemId);
    setEditModalShow(true);
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch('https://laravel.snackhouseuk.com/api/admin/category/import', {
        ...requestOptions,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      });

      setLoading(false);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Upload failed');
      }

      const data = await response.json();
      if (data.status === true) {
        window.location.reload();
        toast.success(data.message);
      } else if (data.status === false) {
        window.location.reload();
        toast.error(data.error);
      }else {
        toast.error("Invalid format");
      }
      fetchPricebandList(currentPage);
    } catch (error) {
      setLoading(false);
      console.error('Upload error:', error);
      toast.error(error.message || 'Invalid format');
    } finally {
      setUploadProgress(0); // Reset progress bar
    }
  };

  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdminCategoryModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
        <AdminCategoryEditModal show={editmodalShow} onHide={() => setEditModalShow(false)} itemId={editItemId} onSuccess={handleModalSuccess} itemData={editItemData} />
        <DeleteConfirmationModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => handleDeleteItem(deleteItemId)}
          loading={loading}
        />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>All Categories</h2>
                  </div>
                  <div>
                    <input type="file" onChange={handleFileChange} className="btn btn-light ml-2 g-13 m-2" style={{ width: '116px' }} />
                    <a href="#" role="button" className="btn btn-primary" onClick={() => setModalShow(true)}>
                      Add New
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="p-6">
                    <div className="row justify-content-between">
                      <div className="col-md-4 col-12 mb-2 mb-md-0">
                      <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {loading ? (
                      <div className="text-center py-4">
                        <div className="loader-container" id="loader-container">
                          <div className="loader"></div>
                        </div>
                        {uploadProgress > 0 && (
                          <div className="mt-4">
                            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>
                                <div className="form-check">
                                  <label className="form-check-label" htmlFor="checkAll" />
                                </div>
                              </th>
                              <th>Image</th>
                              <th>Category Name</th>
                              <th>Priority</th>
                              <th>Date &amp; Time</th>
                              <th>Edit &amp; Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              filteredPricebandList.length > 0 ? (
                                filteredPricebandList?.map((item) => (
                                  <tr key={item?.id}>
                                    
                                    <td>
                                      <div className="form-check">
                                        <label className="form-check-label" htmlFor={`order-${item?.id}`} />
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        // src={item?.image}
                                        src={item?.image == null && second ? second : item?.image}
                                        alt=""
                                        className="icon-shape icon-md"
                                        style={{ width: '50px', height: '50px' }}
                                      />
                                    </td>
                                    <td>{item?.name}</td>
                                    <td>{item?.priority}</td>
                                    <td>{format(new Date(item?.created_at), "dd MMMM yyyy")}</td>
                                    <td>
                                      <div className="dropdown">
                                        <td>
                                          <button className="btn-link dropdown-item" onClick={() => handleEditItemClick(item)}>
                                            <a className="dropdown-item" href="#">
                                              <BsPencilSquare />
                                            </a>
                                          </button>
                                        </td>
                                        <td>
                                          <button className="btn-link dropdown-item text-danger" onClick={() => handleShowDeleteModal(item.id)}>
                                            <a className="dropdown-item" href="#">
                                              <BsTrash />
                                            </a>
                                          </button>
                                        </td>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="9" className="text-center">No categories found.</td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="border-top d-flex justify-content-between align-items-md-center px-6 py-6 flex-md-row flex-column gap-4">
                    <span>Showing {currentPage} to {totalPages} of {totalItems} entries</span>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminCategory;
