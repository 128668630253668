import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import grocerybanner from "../../assets/images/banner/696X460_2.png";
import grocerybanner2 from "../../assets/images/banner/696X460_4.png";
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import Processing from '../../commponets/Processing/Processing';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../../Screens/AllCategories/AllCategories.css';

function AllCategories() {
  const { apiAction } = useApi();
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    autoplay:false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: '0',
    centerMode: true,
    arrows: true,
    prevArrow: <FaChevronLeft className="slick-prev1" />,
    nextArrow: <FaChevronRight className="slick-next1" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };


  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    try {
      setLoading(true);
      const response = await apiAction({
        url: "/user/category/list",
        method: "GET",
      });
      if (response.status === true) {
        setCategory(response.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Processing />}
      <section className="my-lg-14 my-8">
        <div className="container">
          <div className="row align-items-center mb-6 ps-5">
            <h3 className="mb-0">Featured Categories</h3>
          </div>
          <div className="row mb-5" style={{ height: '229px' }}>
            <div className="col-12">
              <div className="position-relative">
                <Slider {...settings}>
                  {!category || category?.length === 0 ? (
                    <div className="col-12 mb-6 d-flex justify-content-center align-items-center">
                      <h5 className="mb-0 text-center">No Product</h5>
                    </div>
                  ) : (
                    category?.map(category => (
                      <Link to={`/categories/${category?.id}/${category?.name}`} className="text-decoration-none text-inherit" key={category?.id}>
                        <div className="card card-lift h-100" style={{width:'100%',height:'70%',alignItems:'center',marginLeft:-30}}>
                          <img src={category?.image} className='w-40 card-body text-center' alt={category?.name} />
                          <div className="card-body text-center">
                            <h5 className="card-title">{category?.name}</h5>
                          </div>
                        </div>
                      </Link>
                    ))
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container" style={{marginTop:100}}>
          <div className="row">
            <div className="col-12 col-md-6 mt-1">
              <div>
                <div
                  className="py-15 h-100 rounded"
                  style={{
                    background: `url(${grocerybanner})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                  }}
                >
                  <div>
                    <h3 className="fw-bold mb-1"></h3>
                    <p className="mb-4">
                      <span className="fw-bold"></span>
                    </p>
                    <a href="index.html#!" className="btn ">
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-1">
              <div>
                <div
                  className="py-15 h-100 rounded"
                  style={{
                    background: `url(${grocerybanner2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                  }}
                >
                  <div>
                    <h3 className="fw-bold mb-1"></h3>
                    <p className="mb-4">
                      <span className="fw-bold"></span>
                    </p>
                    <a href="index.html#!" className="btn">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AllCategories;


