// import React, { createContext, useContext, useReducer, useEffect } from 'react';

// const CartContext = createContext();
// const initialState = {
//   items: JSON.parse(localStorage.getItem('cart')) || []
// };

// const cartReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "ADD_TO_CART":
//       const product = action.payload;
//       const existingProduct = state.items.find(item => item.id === product.id);

//       if (existingProduct) {
//         return {
//           ...state,
//           items: state.items.map(item =>
//             item.id === product.id
//               ? { ...item, quantity: item.quantity + product.quantity }
//               : item
//           ),
//         };
//       } else {
//         return {
//           ...state,
//           items: [...state.items, { ...product, quantity: product.quantity }],
//         };
//       }

//     case "REMOVE_FROM_CART":
//       return {
//         ...state,
//         items: state.items.filter(item => item.id !== action.payload),
//       };

//     case "DECREASE_QUANTITY":
//       return {
//         ...state,
//         items: state.items.map(item =>
//           item.id === action.payload && item.quantity > 1
//             ? { ...item, quantity: item.quantity - 1 }
//             : item
//         ),
//       };

//     case "CLEAR_CART":
//       return initialState;

//     default:
//       return state;
//   }
// };

// export default cartReducer;

// export const CartProvider = ({ children }) => {
//   const [cart, dispatch] = useReducer(cartReducer, initialState);

//   useEffect(() => {
//     localStorage.setItem('cart', JSON.stringify(cart.items));
//   }, [cart.items]);

//   const addToCart = product => {
//     dispatch({ type: 'ADD_TO_CART', payload: product });
//   };

//   const removeFromCart = productId => {
//     dispatch({ type: 'REMOVE_FROM_CART', payload: productId });
//   };

//   const decreaseQuantity = productId => {
//     dispatch({ type: 'DECREASE_QUANTITY', payload: productId });
//   };

//   const clearCart = () => {
//     dispatch({ type: 'CLEAR_CART' });
//   };

//   return (
//     <CartContext.Provider value={{ cart: cart.items, addToCart, removeFromCart, decreaseQuantity, clearCart }}>
//       {children}
//     </CartContext.Provider>
//   );
// };

// export const useCart = () => useContext(CartContext);





// // import React, { createContext, useContext, useReducer } from 'react';

// // const CartContext = createContext();

// // const cartReducer = (state, action) => {
// //   let newState;
// //   switch (action.type) {
// //     case 'ADD_TO_CART':
// //       const existingProduct = state.find(product => product.id === action.payload.id);
// //       if (existingProduct) {
// //         newState = state.map(product =>
// //           product.id === action.payload.id
// //             ? { ...product, quantity: product.quantity + 1 }
// //             : product
// //         );
// //       } else {
// //         newState = [...state, { ...action.payload, quantity: 1 }];
// //       }
// //       break;
// //     case 'REMOVE_FROM_CART':
// //       newState = state.filter(product => product.id !== action.payload);
// //       break;
// //     case 'DECREASE_QUANTITY':
// //       newState = state.map(product =>
// //         product.id === action.payload
// //           ? { ...product, quantity: product.quantity - 1 }
// //           : product
// //       ).filter(product => product.quantity > 0);
// //       break;
// //     case 'PLACE_ORDER':
// //       newState = [];
// //       break;
// //     default:
// //       newState = state;
// //   }
// //   localStorage.setItem('cart', JSON.stringify(newState));
// //   return newState;
// // };

// // export const CartProvider = ({ children }) => {
// //   const initialCart = JSON.parse(localStorage.getItem('cart')) || [];
// //   const [cart, dispatch] = useReducer(cartReducer, initialCart);

// //   const addToCart = (product) => {
// //     dispatch({ type: 'ADD_TO_CART', payload: product });
// //   };

// //   const removeFromCart = (productId) => {
// //     dispatch({ type: 'REMOVE_FROM_CART', payload: productId });
// //   };

// //   const decreaseQuantity = (productId) => {
// //     dispatch({ type: 'DECREASE_QUANTITY', payload: productId });
// //   };

// //   const clearCart = () => {
// //     dispatch({ type: 'PLACE_ORDER' });
// //   };

// //   return (
// //     <CartContext.Provider value={{ cart, addToCart, removeFromCart, decreaseQuantity, clearCart }}>
// //       {children}
// //     </CartContext.Provider>
// //   );
// // };


// // export const useCart = () => {
// //   return useContext(CartContext);
// // };



// CartContext.js
// CartContext.js
// CartContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product, quantity = 1) => {
    setCart((prevCart) => {
      const existingProductIndex = prevCart.findIndex(item => item.id === product.id);
      if (existingProductIndex !== -1) {
        // Product already exists in the cart, increase its quantity
        const updatedCart = [...prevCart];
        updatedCart[existingProductIndex].quantity += quantity;
        return updatedCart;
      }
      // Product does not exist in the cart, add it with default quantity 1
      return [...prevCart, { ...product, quantity }];
    });
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  };

  const clearCart = () => {
    setCart([]);
  };

  const increaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decreaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const updateQuantity = (productId, quantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === productId ? { ...item, quantity: parseInt(quantity, 10) } : item
      )
    );
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart, increaseQuantity, decreaseQuantity, updateQuantity }}>
      {children}
    </CartContext.Provider>
  );
};
