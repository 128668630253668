import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../service/api';
import { getUser } from '../service/constants';

function AdminItemsModal(props) {
    const [name, setName] = useState('');
    const [stock, setStock] = useState('');
    const [price, setPrice] = useState('');
    const [priceband_id, setPriceband_id] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [description, setDescription] = useState('');
    const [discount, setDiscount] = useState('');
    const [discountWith, setDiscountWith] = useState('0');
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(getUser());
    const handleUploadData = (e) => {
        setLoading(true);
        e.preventDefault();
        const requestBody = {
            name: name,
            stock: stock,
            price: price,
            item_code: itemCode,
            description: description,
            discount: discount,
            discount_with: discountWith
        };
        fetchDataFromAPI("/admin/item/create", "post", requestBody, user?.token)
            .then((response) => {
                console.log(response, "---response");
                if (response.status) {
                    toast.success(response.message);
                    props.onHide();
                    props.onSuccess();
                } else {
                    if (response.data && response.data.item_code) {
                        toast.error(response.data.item_code[0]);
                    } else {
                        toast.error(response.message || "An error occurred");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
                toast.error("An error occurred while uploading data");
            })
            .finally(() => {
                setLoading(false);
            });
    };



    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Add Items</h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleUploadData}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    value={name}
                                    className="form-control"
                                    id="name"
                                    placeholder="Name"
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter item name.</div>
                            </div>
                            <div className="d-flex mb-3">
                                <div className="mb-3">
                                    <label htmlFor="stock" className="form-label">Stock</label>
                                    <input
                                        type='number'
                                        value={stock}
                                        className="form-control"
                                        id="stock"
                                        placeholder="Stock"
                                        onChange={(e) => setStock(e.target.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter stock.</div>
                                </div>
                                <div className="mb-3" style={{ marginLeft: 10 }}>
                                    <label htmlFor="price" className="form-label">Price</label>
                                    <input
                                        type="number"
                                        value={price}
                                        className="form-control"
                                        id="price"
                                        placeholder="Price"
                                        onChange={(e) => setPrice(e.target.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter price.</div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="item_code" className="form-label">Item Code</label>
                                <input
                                    value={itemCode}
                                    className="form-control"
                                    id="item_code"
                                    placeholder="Item Code"
                                    onChange={(e) => setItemCode(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter item code.</div>
                            </div>
                            {/* </div> */}
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <input
                                    value={description}
                                    className="form-control"
                                    id="description"
                                    placeholder="Description"
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter description.</div>
                            </div>
                            <div className="d-flex mb-3">
                                <div className="mb-3">
                                    <label htmlFor="discount" className="form-label">Discount</label>
                                    <input
                                        type="number"
                                        value={discount}
                                        className="form-control"
                                        id="discount"
                                        placeholder="Discount"
                                        onChange={(e) => setDiscount(e.target.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter discount.</div>
                                </div>
                                <div className="mb-3" style={{ marginLeft: 10 }}>
                                    <label htmlFor="discount_with" className="form-label">Discount With</label>
                                    <select
                                        value={discountWith}
                                        className="form-control"
                                        id="discount_with"
                                        onChange={(e) => setDiscountWith(e.target.value)}
                                        required
                                    >
                                        <option value="0">Percentage</option>
                                        <option value="1">Value Off</option>
                                    </select>
                                    <div className="invalid-feedback">Please select discount type.</div>
                                </div>
                            </div>
                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminItemsModal;
