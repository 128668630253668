import React, { useEffect, useState } from 'react'
import SellerProfile from '../SellerProfile/SellerProfile'
import SellerNavbar from '../SellerNavbar/SellerNavbar'
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import { getUser } from '../../service/constants';
import { format } from 'date-fns';
import { BsTrash, BsThreeDotsVertical, BsPencilSquare, BsEye } from "react-icons/bs";
import SellerCommisionModal from '../../commponets/SellerCommisionModal';
import SellerCommisionEditModal from '../../commponets/SellerCommisionEditModal';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import ProgressBar from '../../commponets/Processing/Processing';
import { Button } from 'react-bootstrap';
import second from '../../assets/images/avatar/coming_soon.jpg';
function SellerCustomer() {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [allPricebandList, setAllPricebandList] = useState([]);
    const [filteredPricebandList, setFilteredPricebandList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [editmodalShow, setEditModalShow] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [editItemData, setEditItemData] = useState(null);
    const [loading, setLoading] = useState(false); // State for loader
    const [editItemId, setEditItemId] = useState(null);
    const user = JSON.parse(getUser());
    const [totalItems, setTotalItems] = useState(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    useEffect(() => {
        fetchcustomerList(currentPage)
    }, [currentPage])
    const fetchcustomerList = (page) => {
        setLoading(true); // Show loader
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        fetchDataFromAPI(
            `/admin/commission/list?perPage=10&page=${page}&itemSearch=${encodedSearchTerm}`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false); // Hide loader
                if (response.status === true) {
                    const pricebandData = response.data.data;
                    setAllPricebandList(pricebandData);
                    setTotalItems(response?.data?.total)
                    setFilteredPricebandList(pricebandData);
                    setTotalPages(response?.data?.last_page);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                setLoading(false); // Hide loader on error
                console.error("Error fetching priceband list:", error);
            });
    };



    useEffect(() => {
        filterPricebandList();
    }, [searchTerm, allPricebandList]);

    const filterPricebandList = () => {
        if (searchTerm.trim() === '') {
            setFilteredPricebandList(allPricebandList);
        } else {
            const filteredList = allPricebandList.filter(item =>
                item?.item_code?.toLowerCase().includes(searchTerm?.toLowerCase())
            );
            setFilteredPricebandList(filteredList);
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleModalSuccess = () => {
        fetchcustomerList(currentPage);
    };
    const handleEditItemClick = (item) => {
        setEditItemData(item);
        setEditItemId(item.id);
        setEditModalShow(true);
    };
    const handleEditItemClickView = (item) => {
        navigate('/commission-Allocation-View', { state: { item } })
    };
    const handleDeleteItem = (itemId) => {
        setLoading(true); // Show loader for delete action
        fetchDataFromAPI(
            `/admin/commission/delete/${itemId}`,
            "delete",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false); // Hide loader after delete action
                if (response.status === true) {
                    toast.success(response.message);
                    fetchcustomerList(currentPage);
                    setDeleteModalShow(false);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                console.error("Error deleting item:", error);
                setLoading(false); // Hide loader
                setDeleteModalShow(false);
                setDeleteItemId(null);
            });
    };

    const handleShowDeleteModal = (itemId) => {
        setDeleteModalShow(true);
        setDeleteItemId(itemId);
    };
   
    const handleFileUpload = async (event) => {
        setLoading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
    
        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
          body: formData,
        };
    
        try {
          const response = await fetch('https://laravel.snackhouseuk.com/api/admin/commission/import', {
            ...requestOptions,
            onUploadProgress: (progressEvent) => {
              const totalLength = progressEvent.lengthComputable
                ? progressEvent.total
                : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
              if (totalLength !== null) {
                setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
              }
            },
          });
    
          setLoading(false);
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Upload failed');
          }
    
          const data = await response.json();      
          if (data.status === true) {
            window.location.reload();
            toast.success(data.message);
          } else if (data.status === false) {
            window.location.reload();
            toast.error(data.error);
          }else {
            toast.error("Invalid format");
          }
          fetchcustomerList(currentPage);
        } catch (error) {
          setLoading(false);
          console.error('Upload error:', error);
          toast.error(error.message || 'invalid format');
        } finally {
          setUploadProgress(0); // Reset progress bar
        }
      };
    
    return (
        <>
            <AdminProfile />
            <SellerCommisionModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
            <SellerCommisionEditModal show={editmodalShow} onHide={() => setEditModalShow(false)} itemId={editItemId} onSuccess={handleModalSuccess} itemData={editItemData} />
            <DeleteConfirmationModal
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                onDelete={() => handleDeleteItem(deleteItemId)}
                loading={loading}
            />
            <div className="main-wrapper">
                <AdminNavbar />
                <main className="main-content-wrapper">
                    <div className="container">
                        <div className="row mb-8">
                            <div className="col-md-12">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div>
                                        <h2>Commission Allocation</h2>
                                    </div>
                                    <div>
                                        <input type="file" onChange={handleFileUpload} className="btn btn-light ml-2 g-13 m-lg-3" style={{ width: '116px' }} />
                                        <button className="btn btn-primary ml-2" onClick={() => setModalShow(true)}>
                                            Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {uploadProgress > 0 && (
                            <div className="row mb-4">
                                <div className="col-12">
                                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-xl-12 col-12 mb-5">
                                <div className="card h-100 card-lg">
                                    <div className="px-6 py-6">
                                        <div className="row justify-content-between">
                                            <div className="col-lg-4 col-md-6 col-12 mb-2 mb-lg-0">
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        fetchcustomerList();
                                                    }}
                                                >
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Search for products"
                                                            aria-label="Search"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            onKeyPress={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                    fetchcustomerList();
                                                                }
                                                            }}
                                                        />
                                                        <Button onClick={() => fetchcustomerList()}>
                                                            <span>Search</span>
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {loading ? (
                                            <div className="text-center py-4">
                                                <div className="loader-container" id="loader-container">
                                                    <div className="loader"></div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="table-responsive">
                                                <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>Item code</th>
                                                            <th>Item name</th>
                                                            <th>sales1 commission </th>
                                                            <th>sales2 commission</th>
                                                            <th>sales3 commission</th>
                                                            <th>sales4 commission</th>
                                                            <th>sales5 commission</th>
                                                            <th>Date & Time</th>
                                                            <th>Delete &amp; Edit &amp; View</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            filteredPricebandList.length > 0 ?
                                                                (filteredPricebandList.map((item) => (
                                                                    <tr key={item.id}>
                                                                        <td>
                                                                       
                                                                            <img
                                                                                src={item?.item?.itemImage?.[0]  == null && second ? second : item?.item?.itemImage?.[0]}
                                                                                alt="#"
                                                                                className="icon-shape icon-md"
                                                                                style={{ width: '50px', height: '50px' }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {item?.item?.item_code}
                                                                        </td>
                                                                        <td>
                                                                            {item?.item_name}
                                                                        </td>
                                                                        {
                                                                            item?.sales1_commission ?
                                                                                <td>
                                                                                    {item?.sales1_commission}{item?.sales1_commission_type === "0" ? "%" : item?.sales1_commission_type === "1" ? "" : "-"} - {item?.sales1?.email}
                                                                                </td>
                                                                                : <td></td>
                                                                        }

                                                                        {
                                                                            item?.sales2_commission ?
                                                                                <td>
                                                                                    {item?.sales2_commission}{item?.sales2_commission_type === "0" ? "%" : item?.sales2_commission_type === "1" ? "" : "-"} - {item?.sales2?.email}
                                                                                </td>
                                                                                :
                                                                                <td></td>
                                                                        }
                                                                        {
                                                                            item?.sales3_commission ?
                                                                                <td>
                                                                                    {item?.sales3_commission}{item?.sales3_commission_type === "0" ? "%" : item?.sales3_commission_type === "1" ? "" : "-"}  {item?.sales3?.email}
                                                                                </td> : <td></td>
                                                                        }
                                                                        {
                                                                            item?.sales4_commission ?
                                                                                <td>
                                                                                    {item?.sales4_commission}{item?.sales4_commission_type === "0" ? "%" : item?.sales4_commission_type === "1" ? "" : "-"}  {item?.sales4?.email}
                                                                                </td> : <td></td>
                                                                        }
                                                                        {
                                                                            item?.sales5_commission ?
                                                                                <td>
                                                                                    {item?.sales5_commission}{item?.sales5_commission_type === "0" ? "%" : item?.sales5_commission_type === "1" ? "" : "-"}  {item?.sales5?.email}
                                                                                </td> : <td></td>
                                                                        }

                                                                        <td>{format(new Date(item.created_at), "dd MMMM yyyy")}</td>
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <td>
                                                                                    <button
                                                                                        className="btn-link dropdown-item text-danger"
                                                                                        onClick={() => handleShowDeleteModal(item?.id)}
                                                                                    >
                                                                                        <BsTrash />
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        className="btn-link dropdown-item"
                                                                                        onClick={() => handleEditItemClick(item)}
                                                                                    >
                                                                                        <BsPencilSquare />
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        className="btn-link dropdown-item"
                                                                                        onClick={() => handleEditItemClickView(item?.id)}>
                                                                                        <BsEye />
                                                                                    </button>
                                                                                </td>
                                                                                {/* </ul> */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )))
                                                                :
                                                                (
                                                                    <tr>
                                                                        <td colSpan="9" className="text-center">No Commission Allocation found.</td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                    <div className="border-top d-md-flex justify-content-between align-items-center px-6 py-6">
                                        <span>Showing {currentPage} to {allPricebandList?.length} of {totalItems} entries</span>
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={setCurrentPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>

        </>
    )
}

export default SellerCustomer


