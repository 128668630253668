import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import useApi from "../service/useApi";
import { fetchDataFromAPI } from "../service/api";
import { getUser } from "../service/constants";
import DatePicker from "react-datepicker";

function CustomerAllocationEditModal({ show, onHide, itemId, onSuccess, itemData }) {
    const [customer_id, setCustomer_id] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [priceBand, setPriceBand] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [ItemID, setItemID] = useState("");

    const [sales1_id, setSales1_id] = useState("");
    const [sales2_id, setSales2_id] = useState("");
    const [sales3_id, setSales3_id] = useState("");
    const [sales4_id, setSales4_id] = useState("");
    const [sales5_id, setSales5_id] = useState("");
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(getUser());
    useEffect(() => {
        if (itemData) {
            setItemID(itemData?.item_id || "");
            setCustomer_id(itemData.customer_id || "");
            setSales1_id(itemData.sales1_id || "");
            setSales2_id(itemData.sales2_id || "");
            setSales3_id(itemData.sales3_id || "");
            setSales4_id(itemData.sales4_id || "");
            setSales5_id(itemData.sales5_id || "");
        }
    }, [itemData]);
    useEffect(() => {
        fetchPriceband();
        fetchcustomerList()
    }, []);
    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const requestBody = {
            customer_id: customer_id,
            sales1_id: sales1_id,
            sales2_id: sales2_id,
            sales3_id: sales3_id,
            sales4_id: sales4_id,
            sales5_id: sales5_id,
            id: itemData?.id,

        };
        console.log("requestBody", requestBody)
        fetchDataFromAPI("/admin/salescustomer/allocation/create", "post", requestBody, user?.token)
            .then((response) => {
                console.log(response, "----responseresponse");
                if (response.status) {
                    toast.success("Item updated successfully!");
                    onHide();
                    if (onSuccess) {
                        onSuccess(response);
                    }
                } else {
                    if (response.data && typeof response.data === "object") {
                        Object.keys(response.data).forEach((key) => {
                            response.data[key].forEach((message) => {
                                toast.error(`${key}: ${message}`);
                            });
                        });
                    } else {
                        toast.error(response.message || "Failed to update item.");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
                toast.error("An error occurred while uploading data");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchPriceband = () => {
        setLoading(true); 
        fetchDataFromAPI(
            `/admin/listing`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false); 
                if (response?.status === true) {
                    setPriceBand(response.data)
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching priceband list:", error);
            });
    };
    const fetchcustomerList = () => {
        setLoading(true); // Show loader
        fetchDataFromAPI(
            `/admin/customer/listing`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false); // Hide loader
                if (response?.status === true) {
                    setCustomer(response.data)
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                setLoading(false); // Hide loader on error
                console.error("Error fetching priceband list:", error);
            });
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">
                            Edit Commission
                        </h5>
                        <Button variant="close" onClick={onHide} />
                    </div>
                    <div className="modal-body">
                        <form
                            className="needs-validation"
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <div className="mb-3">
                                <label htmlFor="sales1_id" className="form-label">Customer List</label>
                                <select
                                    value={customer_id}
                                    className="form-control"
                                    id="sales1_id"
                                    onChange={(e) => setCustomer_id(e?.target?.value)}
                                    required
                                >
                                    <option value="">Customer List</option>
                                    {customer?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{priceband?.email}</option>
                                    ))}
                                </select>
                            </div>

                            {/* 1 */}
                            <div className="mb-3">
                                <label htmlFor="sales1_id" className="form-label">Sales List 1</label>
                                <select
                                    value={sales1_id}
                                    className="form-control"
                                    id="sales1_id"
                                    onChange={(e) => setSales1_id(e?.target?.value)}
                                    required
                                >
                                    <option value="">Sales List</option>
                                    {priceBand?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                                    ))}
                                </select>
                            </div>

                            {/* 2 */}
                            <div className="mb-3">
                                <label htmlFor="sales1_id" className="form-label">Sales List 2</label>
                                <select
                                    value={sales2_id}
                                    className="form-control"
                                    id="sales2_id"
                                    onChange={(e) => setSales2_id(e?.target?.value)}
                                    required
                                >
                                    <option value="">Sales List</option>
                                    {priceBand?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                                    ))}
                                </select>
                            </div>

                            {/* 3 */}
                            <div className="mb-3">
                                <label htmlFor="sales3_id" className="form-label">Sales List 3</label>
                                <select
                                    value={sales3_id}
                                    className="form-control"
                                    id="sales3_id"
                                    onChange={(e) => setSales3_id(e?.target?.value)}
                                    required
                                >
                                    <option value="">Sales List</option>
                                    {priceBand?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                                    ))}
                                </select>
                            </div>

                            {/* 4 */}
                            <div className="mb-3">
                                <label htmlFor="sales4_id" className="form-label">Sales List 4</label>
                                <select
                                    value={sales4_id}
                                    className="form-control"
                                    id="sales4_id"
                                    onChange={(e) => setSales4_id(e?.target?.value)}
                                    required
                                >
                                    <option value="">Sales List</option>
                                    {priceBand?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                                    ))}
                                </select>
                            </div>

                            {/* 5 */}
                            <div className="mb-3">
                                <label htmlFor="sales5_id" className="form-label">Sales List 5</label>
                                <select
                                    value={sales5_id}
                                    className="form-control"
                                    id="sales5_id"
                                    onChange={(e) => setSales5_id(e?.target?.value)}
                                    required
                                >
                                    <option value="">Sales List</option>
                                    {priceBand?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="d-flex mb-3">
                                <Button variant="secondary" onClick={onHide}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={loading}
                                    style={{ marginLeft: 10 }}
                                >
                                    {loading ? "Save..." : "Save"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CustomerAllocationEditModal;
