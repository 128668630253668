import React, { useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import { BsTrash, BsPencilSquare, BsThreeDotsVertical } from "react-icons/bs";
import AdminItemCategoryModal from '../../commponets/AdminItemCategoryModal'; // Corrected path
import { format } from 'date-fns';
import AdminEditItemCategories from '../../commponets/AdminEditItemCategories'; // Corrected path
import { getUser } from '../../service/constants';
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import ProgressBar from '../../commponets/Processing/Processing';
import Button from 'react-bootstrap/Button';

function ItemCategories() {
  const [modalShow, setModalShow] = useState(false);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [subSubCategoryID, setSubSubCategoryID] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const { apiAction } = useApi();
  const subcategory = JSON.parse(localStorage.getItem("subcategory"));
  const [editItemData, setEditItemData] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [editmodalShow, setEditModalShow] = useState(false);
  const user = JSON.parse(getUser());
  const [totalItems, setTotalItems] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState('');

  useEffect(() => {
    setSubSubCategoryID(subcategory);
    // fetchPricebandList(currentPage);
    fetchCategoryList();
  }, [currentPage , searchTerm ,selectedCategory, selectedSubCategory, selectedSubSubCategory, currentPage]);

  useEffect(() => {
    fetchPricebandList(currentPage, selectedCategory, selectedSubCategory, selectedSubSubCategory);
  }, [ currentPage , searchTerm ,selectedCategory, selectedSubCategory, selectedSubSubCategory, currentPage]);

  // useEffect(() => {
  //   filterPricebandList();
  // }, [searchTerm, allPricebandList]);

  const fetchPricebandList = async (page = null, categoryId = '', subCategoryId = '', subSubCategoryId = '') => {
    setLoading(true);
  
    // Prepare query parameters based on selected values
    const queryParams = [];
    
    if (page !== null) queryParams.push(`page=${page}`);
    queryParams.push(`perPage=10`);  // Keep perPage default, remove if not required
    const encodedSearchTerm = encodeURIComponent(searchTerm);
  
    if (categoryId) queryParams.push(`category_id=${categoryId}`);
    if (subCategoryId) queryParams.push(`subcategory_id=${subCategoryId}`);
    if (subSubCategoryId) queryParams.push(`sub_subcategory_id=${subSubCategoryId}`);
    if (encodedSearchTerm) queryParams.push(`itemSearch=${encodedSearchTerm}`);
  console.log(encodedSearchTerm,"---encodedSearchTerm");
  
    const queryString = queryParams.join('&');
    
    try {
      const response = await apiAction({
        url: `/admin/item/category/list?${queryString}`,
        method: "GET",
        headers: {
          'Authorization': `Bearer ${user?.token}`
        }
      });
  
      if (response.status === true) {
        // const pricebandData = response?.data?.data;
        setTotalItems(response?.data?.total);
        setAllPricebandList(response?.data?.data);
        setFilteredPricebandList(response?.data?.data);
        setTotalPages(response?.data?.last_page || 1);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setLoading(false);
    }
  };
  
  const filterPricebandList = () => {
    if (searchTerm.trim() === '') {
      setFilteredPricebandList(allPricebandList);
    } else {
      const filteredList = allPricebandList.filter(item => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const itemCodeMatch = item?.item?.item_code?.toLowerCase().includes(lowerCaseSearchTerm);
        const itemNameMatch = item?.item?.name?.toLowerCase().includes(lowerCaseSearchTerm);
        const categoryNameMatch = item?.category?.name?.toLowerCase().includes(lowerCaseSearchTerm);
        const subcategoryNameMatch = item?.subcategory?.name?.toLowerCase().includes(lowerCaseSearchTerm);
        const subSubcategoryNameMatch = item?.sub_sub_category?.name?.toLowerCase().includes(lowerCaseSearchTerm);
        return itemCodeMatch || itemNameMatch || categoryNameMatch || subcategoryNameMatch || subSubcategoryNameMatch;
      });

      setFilteredPricebandList(filteredList);
    }
  };


  const handleModalSuccess = () => {
    fetchPricebandList(currentPage, selectedCategory, selectedSubCategory, selectedSubSubCategory);
  };

  const handleDeleteItem = async (itemId) => {
    try {
      const response = await apiAction({
        url: `/admin/item/category/delete/${itemId}`,
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${user?.token}`
        }
      });
      if (response.status) {
        toast.success(response?.message);
        fetchPricebandList(currentPage, selectedCategory, selectedSubCategory, selectedSubSubCategory);
        setDeleteModalShow(false);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item.");
    } finally {
      setDeleteModalShow(false);
      setDeleteItemId(null);
    }
  };

  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch('https://laravel.snackhouseuk.com/api/admin/item/category/import', {
        ...requestOptions,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      });

      setLoading(false);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Upload failed');
      }

      const data = await response.json();      
      if (data.status === true) {
        window.location.reload();
        toast.success(data.message);
      } else if (data.status === false) {
        window.location.reload();
        toast.error(data.error);
      }else {
        toast.error("Invalid format");
      }
      fetchPricebandList(currentPage,selectedCategory, selectedSubCategory, selectedSubSubCategory);
    } catch (error) {
      setLoading(false);
      console.error('Upload error:', error);
      toast.error(error.message || 'invalid format');
    } finally {
      setUploadProgress(0); // Reset progress bar
    }
  };

  const handleEditItemClick = (item) => {
    setEditItemData(item);
    setEditItemId(item.id);
    setEditModalShow(true);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    try {
      const response = await apiAction({
        url: `/admin/category/list`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status) {
        setCategories(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching category list:', error);
      toast.error('Failed to fetch category list.');
    } finally {
      setLoading(false);
    }
  };
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    console.log('Selected Category ID:', categoryId);
    setSelectedCategory(categoryId);
    setSelectedSubCategory('');
    setSelectedSubSubCategory('');
    if (categoryId) {
      fetchSubCategoryList(categoryId);
    }
  };

  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setSelectedSubCategory(subCategoryId);
    setSelectedSubSubCategory('');
    if (subCategoryId) {
      fetchSubSubCategoryList(subCategoryId);
    }
  };

  const handleSubSubCategoryChange = (e) => {
    setSelectedSubSubCategory(e.target.value);
  };

  const fetchSubCategoryList = async (categoryId) => {
    setLoading(true);
    try {
      const response = await apiAction({
        url: `/admin/subcategory/list/${categoryId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status) {
        setSubCategories(response?.data); // Ensure this sets the sub-categories correctly
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching subcategory list:', error);
      toast.error('Failed to fetch subcategory list.');
    } finally {
      setLoading(false);
    }
  };


  const fetchSubSubCategoryList = async (subCategoryId) => {
    setLoading(true);
    try {
      const response = await apiAction({
        url: `/admin/subsubcategory/list/${subCategoryId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status) {
        setSubSubCategories(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching sub-subcategory list:', error);
      // toast.error('Failed to fetch sub-subcategory list.');
    } finally {
      setLoading(false);
    }
  };

 
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default form submission
      fetchPricebandList(currentPage, selectedCategory, selectedSubCategory, selectedSubSubCategory);
    }
  };
  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdminItemCategoryModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onSuccess={handleModalSuccess}
        />
        <AdminEditItemCategories
          show={editmodalShow}
          onHide={() => setEditModalShow(false)}
          itemData={editItemData}
          itemId={editItemId}
          onSuccess={handleModalSuccess}
        />
        <DeleteConfirmationModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => handleDeleteItem(deleteItemId)}
        />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>Item Categories</h2>
                  </div>
                  <div>
                  <input
                      type="file"
                      onChange={handleFileChange}
                      className="btn btn-light ml-2 g-13 m-2"
                      style={{ width: "116px" }}
                    />
                    <a href="#" role="button" onClick={() => setModalShow(true)} className="btn btn-primary">
                      Add New
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-xl-12 col-12 mb-5">
            <div className="card h-100 card-lg">
                <div className="admin-dashboard-content">
                <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                      <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  // setSearchTerm(e.target.value)
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        value={selectedSubCategory}
                        onChange={handleSubCategoryChange}
                      >
                        <option value="">Select Subcategory</option>
                        {subCategories.map((subCategory) => (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        value={selectedSubSubCategory}
                        onChange={handleSubSubCategoryChange}
                      >
                        <option value="">Select Sub-Subcategory</option>
                        {subSubCategories.map((subSubCategory) => (
                          <option key={subSubCategory.id} value={subSubCategory.id}>
                            {subSubCategory.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox mt-2">
                    <thead className="bg-light">
                      <tr>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Category</th>
                        <th>Subcategory</th>
                        <th>Sub-Subcategory</th>
                        <th>Edit &amp; Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredPricebandList?.length > 0 ? (
                        filteredPricebandList?.map((item) => (
                          <tr key={item.id}>
                            <td>{item.item.item_code}</td>
                            <td>{item.item.name}</td>
                            <td>{item.category.name}</td>
                            <td>{item.subcategory.name}</td>
                            <td>{item.sub_sub_category.name}</td>
                            <td>
                            <div className="dropdown">

                            <td>
                              <button
                                variant="warning"
                                 className="btn-link dropdown-item"
                                size="sm"
                                onClick={() => handleEditItemClick(item)}
                              >
                                <BsPencilSquare />
                              </button>
                              </td>
                              <td>
                              <button
                               className="btn-link dropdown-item text-danger"
                                variant="danger"
                                size="sm"
                                onClick={() => handleShowDeleteModal(item.id)}
                           
                              >
                                <BsTrash />
                              </button>
                                </td>
                            </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9" className="text-center">
                            No items found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="border-top d-flex justify-content-between align-items-md-center px-6 py-6 flex-md-row flex-column gap-4">
                    <span>Showing {currentPage}  of {totalItems} entries</span>
                  
                       <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                      />
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </main>

        {loading && <ProgressBar progress={uploadProgress} />}
      </div>
    </>
  );
}

export default ItemCategories;
