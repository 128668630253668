import React, { useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../../service/api';
import { getUser } from '../../service/constants';
import Pagination from '../../commponets/Pagination';
import { Button } from 'react-bootstrap';

function Contact() {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPricebandList, setFilteredPricebandList] = useState([]);
    const user = JSON.parse(getUser());
    const [loading, setLoading] = useState(false);
    const [aItemList, setAllItemList] = useState([]);
    const [allPricebandList, setallPricebandList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(null);
    const fetchPricebandList = () => {
        setLoading(true);
        fetchDataFromAPI(
            `/admin/contact/listing?page=${currentPage}&searchKey=${searchTerm}`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false);
                if (response.status === true) {
                    const pricebandData = response?.data;
                    setAllItemList(pricebandData);
                    setFilteredPricebandList(pricebandData);
                    setTotalItems(response?.data?.total)
                    console.log("priceband data: ", response?.data?.total);
                    setallPricebandList(pricebandData)
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error, "---error");
            });
    };

    useEffect(() => {
        fetchPricebandList();
    }, [currentPage]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    useEffect(() => {
        filterPricebandList();
    }, [searchTerm, aItemList]);

    const filterPricebandList = () => {
        if (searchTerm.trim() === '') {
            setFilteredPricebandList(aItemList);
        } else {
            const filteredList = aItemList?.filter(item =>
                item?.email?.toLowerCase().includes(searchTerm.toLowerCase()) || item?.content?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredPricebandList(filteredList);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };


    return (
        <>
            <AdminProfile />
            <div className="main-wrapper">
                <AdminNavbar />
                <main className="main-content-wrapper">
                    <div className="container">
                        <div className="row mb-8">
                            <div className="col-md-12">
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                                    <div>
                                        <h2>Contact</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-12 mb-5">
                                <div className="card h-100 card-lg">
                                    <div className="px-6 py-6">
                                        <div className="row justify-content-between">
                                            <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        fetchPricebandList();
                                                    }}
                                                >
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Search for emails"
                                                            aria-label="Search"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            onKeyPress={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault(); // Prevents the default form submission
                                                                    fetchPricebandList();
                                                                }
                                                            }}
                                                        />
                                                        <Button onClick={() => fetchPricebandList()}>
                                                            <span>Search</span>
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {loading ? (
                                            <div className="text-center py-5">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="table-responsive">
                                                <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>Email</th>
                                                            <th />
                                                            <th>Content</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            filteredPricebandList.length > 0 ?
                                                                (filteredPricebandList?.map((item) => (
                                                                    <tr key={item.id}>
                                                                        <td>
                                                                            <span className="text-reset">
                                                                                {item?.email}
                                                                            </span>
                                                                        </td>
                                                                        <td />
                                                                        <td>
                                                                            <a className="text-reset">
                                                                                {item?.content}
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )))
                                                                : (
                                                                    <tr>
                                                                        <td colSpan="9" className="text-center">No Contact found.</td>
                                                                    </tr>
                                                                )
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                    <div className="border-top d-md-flex justify-content-between align-items-center px-6 py-6">
                                        <span>Showing {currentPage} to {allPricebandList?.length} of {totalItems} entries</span>
                                        {/* <nav className="mt-2 mt-md-0">
                                    <ul className="pagination mb-0">
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                            Previous
                                        </button>
                                        </li>
                                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                            Next
                                        </button>
                                        </li>
                                    </ul>
                                    </nav> */}
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={setCurrentPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Contact;
