import React, { useState } from 'react';
import signin from "../../assets/images/banner/size332x332.png";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../../service/api';
import { saveUserLocally } from '../../service/constants';
import useApi from '../../service/useApi';
import Spinner from 'react-bootstrap/Spinner';
import Processing from '../../commponets/Processing/Processing';
function UserSignin({ onLogin }) {
    const [modalShow, setModalShow] = useState(false);
    const [registerModalShow, setRegisterModalShow] = useState(false);
    const { apiAction } = useApi();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false); // Loader state

    const handleSignIn = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when sign-in process starts

        const body = {
            email: email,
            password: password,
        };
        fetchDataFromAPI("/user/login", "post", body, "")
            .then((res) => {
                const userLogin = res?.data
                if (res.status === true && userLogin) {
                    saveUserLocally(JSON.stringify(res?.data));
                    toast.success("Login successful!");
                    navigate("/");
                } else {
                    console.log(res,"----res");
                    toast.error(res?.data || res.errors[0]);
                }
            })
            .catch((error) => {
                console.log("error", error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false when sign-in process ends
            });
    };

    return (
        <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
            <section className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <img src={signin} alt="Sign In" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 offset-lg-1">
                        <div className="mb-4">
                            <h1 className="h2 fw-bold mb-1">Sign in to Snackhouseuk</h1>
                            <p>Welcome back to Snackhouseuk! Enter your email and password to get started.</p>
                        </div>
                        <form className="needs-validation" noValidate="" onSubmit={handleSignIn}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="text"
                                    className={`form-control`}
                                    id="email"
                                    placeholder="Email"
                                    required=""
                                />
                                <div className="invalid-feedback">Please enter email.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="formSigninPassword" className="form-label">Password</label>
                                <input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    className={`form-control`}
                                    id="formSigninPassword"
                                    placeholder="*****"
                                    required=""
                                />
                                <div className="invalid-feedback">Please enter password.</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="form-check">
                                </div>
                                <div>
                                    Forgot password?
                                    <Link to={'/forgot-password'}>
                                    <a href="forgot-password.html">Reset It</a>
                                    </Link>
                                </div>
                            </div>
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading ? (
                                        <Processing type="ThreeDots" color="#fff" height={10} width={30} />
                                    ) : (
                                        'Sign In'
                                    )}
                                </button>
                            </div>
                            <div className="mt-3 text-center">
                                Don't have an account?
                                <Link to="/userSignUp">
                                    <span> Sign Up</span>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default UserSignin;
