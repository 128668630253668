import React from 'react';
import {
  BsArrowRightShort,
  BsArrowLeftShort,
} from "react-icons/bs";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPages = () => {
    const pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, '...', totalPages);
      } else if (currentPage > 3 && currentPage < totalPages - 2) {
        pages.push(1, currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      } else {
        pages.push(1, '...', totalPages - 2, totalPages - 1, totalPages);
      }
    }
    return pages;
  };

  const pages = getPages();

  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a
            className="page-link"
            href="#"
            onClick={(e) => { e.preventDefault(); onPageChange(currentPage - 1); }}
            aria-label="Previous"
          >
            <BsArrowLeftShort />
          </a>
        </li>
        {pages.map((page, index) => (
          <li key={index} className={`page-item ${currentPage === page ? 'active' : ''} ${page === '...' ? 'disabled' : ''}`}>
            <a
              className="page-link"
              href="#"
              onClick={(e) => { e.preventDefault(); if (page !== '...') onPageChange(page); }}
            >
              {page}
            </a>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a
            className="page-link"
            href="#"
            onClick={(e) => { e.preventDefault(); onPageChange(currentPage + 1); }}
            aria-label="Next"
          >
            <BsArrowRightShort />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
