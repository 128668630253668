import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RegisterModal from './RegisterModal';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../../src/service/useApi';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../service/api';
import { saveUserLocally } from '../service/constants';
import Spinner from 'react-bootstrap/Spinner';

function LoginModal(props) {
  const [registerModalShow, setRegisterModalShow] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      email: email,
      password: password,
    };
    fetchDataFromAPI("/user/login", "post", body, "")
      .then((res) => {
        console.log("res", res);
        const userLogin = res?.data
        if (res.status === true && userLogin) {
          saveUserLocally(JSON.stringify(res?.data));
          toast.success("Login successful!");
          navigate("/");
        } else {
          toast.error(res.data || res.errors[0]);
        }
      })
      .catch((res) => {
        console.log("error", res);
        toast.error("Login failed! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <RegisterModal show={registerModalShow} onHide={() => setRegisterModalShow(false)} />
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="userModalLabel">Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="needs-validation" noValidate onSubmit={handleSignIn}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="invalid-feedback">Please enter email.</div>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="invalid-feedback">Please enter password.</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="form-check"></div>
              <div>
                Forgot password?
                <Link to={'/forgot-password'}> Reset It</Link>
              </div>
            </div>
            <Button type="submit" className="btn btn-primary w-100" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Sign In'}
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="border-0 justify-content-center">
            Don't have an account?
          <Link to={'/userSignUp'} >
            <a href="#" role="button"> Sign Up</a>
          </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginModal;
