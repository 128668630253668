import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { getUser } from '../service/constants';
import useApi from '../service/useApi';

function AdminEditSubSubCategoryModal({ show, onHide, itemData, onSuccess }) {
  const [name, setName] = useState('');
  const [subCategoriesID, setSubCategoriesID] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { apiAction } = useApi();
  const user = JSON.parse(getUser());
  useEffect(() => {
    if (itemData) {
      setName(itemData?.name || '');
      setSubCategoriesID(itemData?.subcategory_id || '')
    }
  }, [itemData]);
  useEffect(()=>{
    fetchSubCategoryList()
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${user?.token}`);

      const formdata = new FormData();
      formdata.append("name", name);
      formdata.append("subcategory_id", subCategoriesID);
      formdata.append("id", itemData?.id);
      const requestOptions = {
        method: "POST", // Use "PUT" or "PATCH" if you are updating existing data
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`https://laravel.snackhouseuk.com/api/admin/subsubcategory/create`, requestOptions);
      const result = await response.json();

      if (response.ok) {
        toast.success('Subsubcategory updated successfully!');
        onHide(); // Close modal on success
        if (onSuccess) {
          onSuccess(result); // Optional: Pass result to parent component if needed
        }
      } else {
        toast.error(result.message || 'Failed to update subsubcategory.');
      }
    } catch (error) {
      console.error('Failed to update subsubcategory:', error);
      toast.error('Failed to update subsubcategory.');
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };


  const fetchSubCategoryList = async (page) => {
    setLoading(true);
    try {
      const response = await apiAction({
        url: `/admin/subcategory/list`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status) {
        const pricebandData = response.data;
        setSubCategories(pricebandData)
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching pricebands:', error);
    }
    finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Body>
        <div className="modal-content p-1">
          <div className="modal-header border-0">
            <h5 className="modal-title fs-3 fw-bold">Edit Subsubcategorie</h5>
            <Button variant="close" onClick={onHide} />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="priceband_id" className="form-label">Select subCategorie</label>
              <select
                  value={subCategoriesID}
                  className="form-control"
                  id="priceband_id"
                  onChange={(e) => setSubCategoriesID(e.target.value)}
                  required
              >
                  <option value="">Select subCategorie</option>
                  {subCategories?.map((Categories) => (
                      <option key={Categories?.id} value={Categories?.id}>
                          {Categories?.name}
                      </option>
                  ))}
              </select>
              <div className="invalid-feedback">Please select subCategorie.</div>
          </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Sub-subCategorie name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter subsubCategorie name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="d-flex">
                <Button
                  variant="secondary"
                  onClick={onHide}
                  disabled={loading}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="ms-2"
                  disabled={loading}
                >
                  {loading ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AdminEditSubSubCategoryModal;
