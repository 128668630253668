import React, { useEffect, useState } from "react";
import sliderImage1 from "../../../src/assets/images/banner/Sales Panel Banner 787x300.png";
import "../../Screens/AdminDashbords/style.css";
import { fetchDataFromAPI } from "../../service/api";
import { getUser, saveUserLocally } from "../../service/constants";
import { toast } from "react-toastify";
import SellerNavbar from "../SellerNavbar/SellerNavbar";
import { Link, useNavigate } from "react-router-dom";
import { BsEye } from "react-icons/bs";

function SellerDashborad() {
    const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [allCustomer, setAllCustomer] = useState();
  const cardStyle = {
    backgroundImage: `url(${sliderImage1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "right",
  };

  const user = JSON.parse(getUser());

  useEffect(() => {
    fetchcustomerList();
  }, []);
  const fetchcustomerList = () => {
    setLoading(true); // Show loader
    fetchDataFromAPI(`/sale/dashboard`, "get", "", user?.token)
      .then((res) => {
        setLoading(false); // Hide loader
        if (res.status === true) {
          const customerList = res;
          setAllCustomer(customerList);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error("Error fetching priceband list:", error);
      });
  };

 
  return (
    <>
      <SellerNavbar isSidebarVisible={isSidebarVisible} />
      <main className="main-content-wrapper">
        <section className="container">
          <div className="border-0 card">
            <img src={sliderImage1} className="align-content-center"/>
          </div>
          <div className="table-responsive-xl mb-6 mb-lg-0 m-10">
            <div className="row flex-nowrap pb-3 pb-lg-0">
              <div className="col-lg-4 col-12 mb-6">
                <div className="card h-100 card-lg">                  
                  <div className="card-body p-6 bg-light-danger">                   
                    <div className="d-flex justify-content-between align-items-center mb-6">
                      <div>
                        <h4 className="mb-0 fs-5">Allocated Customers</h4>
                      </div>
                      <div className="icon-shape icon-md bg-light-danger text-dark-danger rounded-circle">
                        <i className="bi bi-currency-dollar fs-5" />
                      </div>
                    </div>
                    <div className="lh-1">
                      <h1 className="mb-2 fw-bold fs-2">{allCustomer?.allocatedCustomers}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 mb-6">
                <div className="card h-100 card-lg">
                  <div className="card-body p-6 bg-light-warning">
                    <div className="d-flex justify-content-between align-items-center mb-6">
                      <div>
                        <h4 className="mb-0 fs-5">Orders</h4>
                      </div>
                      <div className="icon-shape icon-md bg-light-warning text-dark-warning rounded-circle">
                        <i className="bi bi-cart fs-5" />
                      </div>
                    </div>
                    <div className="lh-1">
                      <h1 className="mb-2 fw-bold fs-2">{allCustomer?.orderCount}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 mb-6">
                <div className="card h-100 card-lg">
                  <div className="card-body p-6 bg-light-info">
                    <div className="d-flex justify-content-between align-items-center mb-6">
                      <div>
                        <h4 className="mb-0 fs-5">Total Commission</h4>
                      </div>
                      <div className="icon-shape icon-md bg-light-info text-dark-info rounded-circle">
                        <i className="bi bi-people fs-5" />
                      </div>
                    </div>
                    <div className="lh-1">
                      <h1 className="mb-2 fw-bold fs-2">{allCustomer?.totalCommissionCount}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default SellerDashborad;
