import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { PricebandContext } from "../context/PricebandContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchDataFromAPI } from "../service/api";
import { getUser } from "../service/constants";

function CustomerAllocationModal(props) {
    const [Sales1_ID, setSales1_ID] = useState("");
    const [Sales2_ID, setSales2_ID] = useState("");
    const [Sales3_ID, setSales3_ID] = useState("");
    const [Sales4_ID, setSales4_ID] = useState("");
    const [Sales5_ID, setSales5_ID] = useState("");
    const [loading, setLoading] = useState(false);
    const [salesData, setSalesData] = useState([]);
    const [priceBand, setPriceBand] = useState([]);
    const [selectedPricebandID, setSelectedPricebandID] = useState("");
    const user = JSON.parse(getUser());

    useEffect(() => {
        fetchPricebandList();
        fetchSalesList();
    }, []);

    const handleUploadData = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        // formData.append("sales1_id", Item_ID);
        formData.append("customer_id", selectedPricebandID);
        formData.append("sales1_id", Sales1_ID);
        formData.append("sales2_id", Sales2_ID);
        formData.append("sales3_id", Sales3_ID);
        formData.append("sales4_id", Sales4_ID);
        formData.append("sales5_id", Sales5_ID);

        try {
            const response = await fetch(
                "https://laravel.snackhouseuk.com/api/admin/salescustomer/allocation/create",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: formData,
                }
            );

            const result = await response.json();
            console.log(result, "-------result");
            if (response.ok) {
                toast.success(result.message || "Data saved successfully");
                props.onHide();
                props.onSuccess();
            } else {
                toast.error(result.message || "An error occurred");
            }
        } catch (error) {
            console.error("error", error);
            toast.error("An error occurred while uploading data");
        } finally {
            setLoading(false);
        }
    };

   

    const fetchSalesList = () => {
        fetchDataFromAPI(
            `/admin/listing`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                if (response.status === true) {
                    setSalesData(response?.data);
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                console.log(error, "---error");
            });
    };

    const fetchPricebandList = (page) => {
        fetchDataFromAPI(
            `/admin/customer/listing`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                if (response.status === true) {
                    setPriceBand(response?.data);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                console.error("Error fetching priceband list:", error);
            });
    };

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">
                            Add Customer Allocation
                        </h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form
                            className="needs-validation"
                            noValidate
                            onSubmit={handleUploadData}
                        >
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">
                                    Customer
                                </label>
                                <select
                                    value={selectedPricebandID}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setSelectedPricebandID(e.target.value)}
                                    required
                                >
                                    <option value="">Customer List</option>
                                    {priceBand?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {`${priceband.name} - ${priceband.email}`}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please select a customer.</div>
                            </div>

                            {/* Sales List 1 */}
                            <div className="mb-3">
                                <label htmlFor="sales1_id" className="form-label">
                                    Sales List 1
                                </label>
                                <select
                                    value={Sales1_ID}
                                    className="form-control"
                                    id="sales1_id"
                                    onChange={(e) => setSales1_ID(e.target.value)}
                                    required
                                >
                                    <option value="">Select Item</option>
                                    {salesData?.map((priceband) => (
                                        // console.log(priceband,"-----priceband")
                                        <option key={priceband.id} value={priceband.id}>
                                            {`${priceband.sales_code} - ${priceband.email}`}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please select a sales item.</div>
                            </div>

                            {/* Sales List 2 */}
                            <div className="mb-3">
                                <label htmlFor="sales2_id" className="form-label">
                                    Sales List 2
                                </label>
                                <select
                                    value={Sales2_ID}
                                    className="form-control"
                                    id="sales2_id"
                                    onChange={(e) => setSales2_ID(e.target.value)}
                                    required
                                >
                                    <option value="">Select Item</option>
                                    {salesData?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {`${priceband.sales_code} - ${priceband.email}`}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please select a sales item.</div>
                            </div>


                            {/* Sales List 3 */}
                            <div className="mb-3">
                                <label htmlFor="sales3_id" className="form-label">
                                    Sales List 3
                                </label>
                                <select
                                    value={Sales3_ID}
                                    className="form-control"
                                    id="sales3_id"
                                    onChange={(e) => setSales3_ID(e.target.value)}
                                    required
                                >
                                    <option value="">Select Item</option>
                                    {salesData?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {`${priceband.sales_code} - ${priceband.email}`}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please select a sales item.</div>
                            </div>

                            {/* Sales List 4 */}
                            <div className="mb-3">
                                <label htmlFor="sales4_id" className="form-label">
                                    Sales List 4
                                </label>
                                <select
                                    value={Sales4_ID}
                                    className="form-control"
                                    id="sales4_id"
                                    onChange={(e) => setSales4_ID(e.target.value)}
                                    required
                                >
                                    <option value="">Select Item</option>
                                    {salesData?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {`${priceband.sales_code} - ${priceband.email}`}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please select a sales item.</div>
                            </div>


                            {/* Sales List 5 */}
                            <div className="mb-3">
                                <label htmlFor="sales5_id" className="form-label">
                                    Sales List 5
                                </label>
                                <select
                                    value={Sales5_ID}
                                    className="form-control"
                                    id="sales5_id"
                                    onChange={(e) => setSales5_ID(e.target.value)}
                                    required
                                >
                                    <option value="">Select Item</option>
                                    {salesData?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {`${priceband.sales_code} - ${priceband.email}`}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please select a sales item.</div>
                            </div>

                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-50"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-50"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? "Saving..." : "Save"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CustomerAllocationModal;
