import React, { useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from 'react-toastify';
import { BsTrash, BsPencilSquare, BsEye } from "react-icons/bs";
import { format } from 'date-fns';
import { fetchDataFromAPI } from '../../service/api';
import AdminItemPricebandModal from '../../commponets/AdminItemPricebandModal';
import AdminItemPriceBandEdit from '../../commponets/AdminItemPriceBandEdit';
import { getUser } from '../../service/constants';
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import ProgressBar from '../../commponets/Processing/Processing';
import second from '../../assets/images/avatar/coming_soon.jpg';
import Button from 'react-bootstrap/Button';
function AdminItemPriceband() {
  const [modalShow, setModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [viewItem, setViewItem] = useState(false);
  const [aItemList, setAllItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [priceBandID, setpriceBandID] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemData, setEditItemData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const user = JSON.parse(getUser());
  const [totalItems, setTotalItems] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  useEffect(() => {
    fetchPricebandList(currentPage);
    fetchPricebandIdList()
  }, [currentPage ,searchTerm]);

  // useEffect(() => {
  //   // filterPricebandList();
  //   fetchPricebandIdList();
  // }, [searchTerm, aItemList]);

  // price band item list
  const fetchPricebandList = (page) => {
    setLoading(true); // Set loading to true when fetching data
    const encodedSearchTerm = encodeURIComponent(searchTerm);
    fetchDataFromAPI(
      `/admin/itempriceband/list?perPage=10&page=${page}&itemSearch=${encodedSearchTerm}`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false); // Set loading to false when data is fetched
        if (response.status === true) {
          setTotalItems(response?.data?.total);
          setAllItemList(response?.data?.data);
          setTotalPages(response?.data?.last_page);
        } else {
          // toast.error(response?.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Set loading to false on error
        console.log(error, "---error");
      });
  };

  // delete
  const handleDeleteItem = (itemId) => {
    fetchDataFromAPI(
      `/admin/itempriceband/delete/${itemId}`,
      "delete",
      "",
      user?.token
    )
      .then((response) => {
        if (response.status === true) {
          toast.success(response?.data);
          fetchPricebandList();
          setDeleteModalShow(false);
        } else {
          toast.error(response?.data);
        }
      })
      .catch((error) => {
        console.log(error, "---error");
        setLoading(false); // Hide loader
        setDeleteModalShow(false);
        setDeleteItemId(null);
      });
  };
  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };
  const filterPricebandList = () => {
    if (searchTerm.trim() === '') {
      setFilteredPricebandList(aItemList);
    } else {
      const filteredList = aItemList.filter(item =>
        item?.item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPricebandList(filteredList);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleModalSuccess = () => {
    fetchPricebandList();
  };

  const handleEditItemClick = (item) => {
    setEditItemData(item);
    setEditItemId(item.id);
    setEditModalShow(true);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch('https://laravel.snackhouseuk.com/api/admin/itempriceband/import', {
        ...requestOptions,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      });

      setLoading(false);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Upload failed');
      }

      const data = await response.json();      
      if (data.status === true) {
        window.location.reload();
        toast.success(data.message);
      } else if (data.status === false) {
        window.location.reload();
        toast.error(data.error);
      }else {
        toast.error("Invalid format");
      }
      fetchPricebandList(currentPage);
    } catch (error) {
      setLoading(false);
      console.error('Upload error:', error);
      toast.error(error.message || 'invalid format');
    } finally {
      setUploadProgress(0); // Reset progress bar
    }
  };


  const fetchPricebandIdList = () => {
    setLoading(true); // Show loader
    fetchDataFromAPI(
      `/admin/item/priceband/list`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false); // Hide loader
        if (response.status === true) {
          setpriceBandID(response?.data);
        } else {
          // toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error("Error fetching priceband list:", error);
      });
  };

  const getPricebandNameById = (pricebandId) => {
    const priceband = priceBandID.find((pb) => pb.id === pricebandId);
    return priceband ? priceband.type : 'Unknown';
  };

  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdminItemPricebandModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
        <AdminItemPriceBandEdit show={editmodalShow} onHide={() => setEditModalShow(false)} itemId={editItemId} onSuccess={handleModalSuccess} itemData={editItemData} />
        <DeleteConfirmationModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => handleDeleteItem(deleteItemId)}
          loading={loading}
        />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>Item Priceband</h2>
                  </div>
                  <div>
                    <input type="file" onChange={handleFileChange} className="btn btn-light ml-2 g-13 m-2" style={{ width: '116px' }} />
                    <a href="#" role="button" onClick={() => setModalShow(true)} className="btn btn-primary">
                      Add New
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {uploadProgress > 0 && (
              <div className="row mb-4">
                <div className="col-12">
                  <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                      <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                          <input
  className="form-control"
  placeholder="Search for products"
  aria-label="Search"
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
  onKeyPress={(e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default form submission
      fetchPricebandList(1); // Fetch data starting from the first page
    }
  }}
/>
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {loading ? (
                      <div className="text-center py-5">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>Image</th>
                              <th>Item-code</th>
                              <th>Name</th>
                              <th>Stock</th>
                              <th>Price</th>
                              {/* <th>Priceband</th> */}
                              <th>Special price</th>
                              <th>From date</th>
                              <th>To date</th>
                              <th>Delete & Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {aItemList?.length > 0 ? (
                              aItemList?.map((item) => (
                                <tr key={item.id}>                                  
                                  <td><img
                                    // src={item?.item?.itemImage[0]}
                                    src={item?.item?.itemImage[0] == null && second ? second : item?.item?.itemImage[0]}
                                    alt=""
                                    className="icon-shape icon-md"
                                    style={{ width: '60px', height: '60px' }}
                                  /></td>
                                  <td>{item.item.item_code}</td>
                                  <td>{item.item.name}</td>
                                  <td>{item?.item?.stock}</td>
                                  <td>{item?.price}</td>
                                  {/* <td>{getPricebandNameById(item?.priceband_id)}</td> */}
                                  <td>{item.special_price}</td>
                                  <td>{format(new Date(item?.from_date), 'dd-MM-yyyy')}</td>
                                  <td>{format(new Date(item?.to_date), 'dd-MM-yyyy')}</td>
                                  <td className="text-end">
                                    <div className="dropdown">
                                      <td>
                                        <a className="dropdown-item text-danger" href="#" onClick={() => handleShowDeleteModal(item.id)}>
                                          <BsTrash className="me-2" />
                                        </a>
                                      </td>
                                      <td>
                                        <a className="dropdown-item" href="#" onClick={() => handleEditItemClick(item)}>
                                          <BsPencilSquare className="me-2" />
                                        </a>
                                      </td>
                                      {/* </ul> */}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                              <td colSpan="9" className="text-center">No Item priceband found.</td>
                            </tr>
                            )}
                            
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="card-footer p-6">
                    <div className="d-flex justify-content-between">
                      <span>Showing {currentPage} to {filteredPricebandList.length} of {totalItems}  Products found</span>
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminItemPriceband;
