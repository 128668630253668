import React, { useEffect, useState } from 'react';
import { getUser } from '../../service/constants';
import { useCart } from '../../context/CartContext';
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import second from "../../assets/images/avatar/coming_soon.jpg";
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Processing from '../../commponets/Processing/Processing';

function Wishlist() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apiAction } = useApi();
  const user = JSON.parse(getUser());
  const { addToCart, removeFromCart } = useCart();
console.log('allPricebandList:::',allPricebandList);
  useEffect(() => {
    fetchItemList();
  }, [currentPage]);

  const fetchItemList = async () => {
    setIsLoading(true);
    try {
      const response = await apiAction({
        url: `/user/item/like/list?page=${currentPage}`,
        method: "GET",
        headers: {
          'Authorization': `Bearer ${user?.token}`
        }
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setAllPricebandList(pricebandData);
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteItem = async (itemID) => {
    const requestBody = {
      item_id: itemID,
    };
    try {
      const requestOptions = {
        url: "/user/item/like",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${user?.token}`,
        },
        body: JSON.stringify(requestBody),
      };
      const response = await apiAction(requestOptions);
      if (response.status === true) {
        fetchItemList();
        toast.success("Item removed from wishlist");
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
      toast.error("Failed to remove item from wishlist");
    }
  };

  const handleAddToCart = (product) => {
    // addToCart(product);  
    addToCart(product, 1)
    toast.success("Product added to cart");
  };

  return (
    <>
      <Header />
      <Navbar />
      <section className="mt-8 mb-14">
        
        <div className="container">
        <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{'My Wishlist'}</li>
                    </ol>
                </nav>
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-8 mt-6">
                <h1 className="mb-1">My Wishlist</h1>
                <p>There are {allPricebandList?.length} products in this wishlist.</p>
              </div>
              <div>
                {isLoading ? (
                  <Processing/>
                ) : (
                  <div className="table-responsive">
                    <table className="table text-nowrap table-with-checkbox">
                      <thead className="table-light">
                        <tr>
                          <th></th>
                          <th>Product</th>
                          <th>Amount</th>                          
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allPricebandList?.map((product) => {
                          return (
                            <tr key={product?.item?.id}>
                              {console.log('product--whishlist--',product)}
                              <td className="align-middle">
                                <Link   to={`/productList/${product?.item?.id}${product?.item?.item_category?.[0]?.category_id ? `/${product?.item?.item_category?.[0].category_id}` : ''}`} >
                                  <a href="shop-wishlist.html#">
                                    <img
                                      src={product?.item?.itemImage?.[0] || second}
                                      className="icon-shape icon-xxl"
                                      alt=""
                                    />
                                  </a>
                                </Link>
                              </td>
                              <td className="align-middle">
                                <div>
                                  <h5 className="fs-6 mb-0">
                                    <small className="text-inherit">
                                      {product?.item?.name}
                                    </small>
                                  </h5>
                                  <small>{product?.item?.item_code}</small>
                                </div>
                              </td>
                              <td className="align-middle">{product?.item?.currency}{product?.item?.price}</td>
                              <td className="align-middle">
                                <a href="#" className="align-middle" onClick={() => handleDeleteItem(product?.item?.id)} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Delete">
                                  <span className="me-1 align-text-bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2 text-success ">
                                      <polyline points="3 6 5 6 21 6"></polyline>
                                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                      <line x1="10" y1="11" x2="10" y2="17"></line>
                                      <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                  </span>
                                </a>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Wishlist;