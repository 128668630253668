import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import { fetchDataFromAPI } from "../../service/api";
import { toast } from "react-toastify";
import { BsEye } from "react-icons/bs";
import { Link, Navigate } from "react-router-dom";
import SideOrdeList from "../../commponets/SideOrdeList";
import constants, { getUser } from "../../service/constants";
import Processing from "../../commponets/Processing/Processing";
import Footer from "../Footer/Footer";
import second from '../../assets/images/avatar/coming_soon.jpg';
function CancelOrder() {
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(getUser());
  useEffect(() => {
    fetchOrderCancel();
  }, []);
  const fetchOrderCancel = () => {
    setLoading(true);
    fetchDataFromAPI(
      `/user/customer/item/order/cancel/list`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        if (response.status == true) {
          const pricebandData = response?.data;
          setAllPricebandList(pricebandData);
          setLoading(false);
        } else {
          toast.error(response?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "---error");
        setLoading(false);
      });
  };

  return (
    <>
      <Header />
      <Navbar />
      {loading && <Processing />}
      <main >
        <section>
          <div className="container">
            <div className="row">
              <SideOrdeList />
              <div className="col-lg-9 col-md-8 col-12">
                <div className="py-6 p-md-6 p-lg-10">
                <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{"Cancel Orders"}</li>
                      </ol>
                    </nav>
                  <h2 className="mb-6">Cancel Orders</h2>
                  <div className="table-responsive-xxl border-0">
                    <table className="table mb-0 text-nowrap table-centered">
                      <thead className="bg-light">
                        <tr>
                          <th>Image</th>
                          <th>Product</th>
                          <th>Order ID</th>
                          <th>Date</th>
                          <th>Quantity</th>
                          <th>Amount</th>
                          {/* <th>Delete Order</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {allPricebandList?.map((product) => {
                          return (
                            <tr>
                              <td className="align-middle border-top-0 w-0">
                                <a href="account-orders.html#">
                                  <img
                                    src={
                                      product?.order_line[0].item?.itemImage[0] || second
                                    }
                                    alt="Product Image"
                                    loading="lazy"
                                    className="icon-shape icon-xl"
                                  />
                                  {/* </Link> */}
                                </a>
                              </td>
                              <td className="align-middle border-top-0">
                                <h6 className="mb-0">
                                  {product?.order_line[0].item?.name}
                                </h6>
                                <span>
                                  <small className="text-muted">
                                    {product?.order_line[0]?.item?.item_code}
                                  </small>
                                </span>
                              </td>
                              <td className="align-middle border-top-0">
                                <small>
                                  {product?.order_number}
                                </small>
                              </td>
                              <td className="align-middle border-top-0">
                                {product.dateTime}
                              </td>
                              <td className="align-middle border-top-0">
                                {product?.order_line[0]?.quantity}
                              </td>
                              <td className="align-middle border-top-0">
                                {product?.order_line[0].item?.currency}
                                {product?.order_line[0].item?.price}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer/>
   
    </>
  );
}

export default CancelOrder;
