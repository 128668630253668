import React, { useContext, useEffect, useState } from "react";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import useApi from "../../service/useApi";
import AdminItemsModal from "../../commponets/AdminItemsModal";
import { BsTrash, BsPencilSquare, BsEye } from "react-icons/bs";
import AdminItemsEditModal from "../../commponets/AdminItemsEditModal";
import { format } from "date-fns";
import AdminViewItem from "../../commponets/AdminViewItem";
import { fetchDataFromAPI } from "../../service/api";
import { PricebandContext } from "../../context/PricebandContext";
import { getUser } from "../../service/constants";
import Pagination from "../../commponets/Pagination";
import DeleteConfirmationModal from "../../commponets/DeleteConfirmationModal";
import ProgressBar from "../../commponets/Processing/Processing";
import { Button } from "react-bootstrap";
import second from '../../assets/images/avatar/coming_soon.jpg';
function AdminItems() {
  const [modalShow, setModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [viewItem, setViewItem] = useState(false);
  const [aItemList, setAllItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [editItemData, setEditItemData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loading, setLoading] = useState(false); // State for loader
  const { setPricebandData } = useContext(PricebandContext);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { apiAction } = useApi();

  const user = JSON.parse(getUser());

  useEffect(() => {
    fetchPricebandList(currentPage);
  }, [currentPage,searchTerm]);


  const fetchPricebandList = async (page) => {
    setLoading(true); // Show loader before API call
    const encodedSearchTerm = encodeURIComponent(searchTerm);

    try {
      const response = await fetchDataFromAPI(
        `/admin/item/list?perPage=10&page=${page}&itemSearch=${encodedSearchTerm}`,
        "get",
        "",
        user?.token
      );

      if (response.status === true) {
        setPricebandData(response?.data?.data);
        setTotalItems(response?.data?.total);
        setAllItemList(response?.data?.data);
        setFilteredPricebandList(response?.data?.data);
        setTotalPages(response?.data?.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch items.");
    } finally {
      setLoading(false); // Hide loader after API call completes
    }
  };

  const handleDeleteItem = async (itemId) => {
    setLoading(true); // Show loader before API call
    try {
      const response = await apiAction({
        url: `/admin/item/delete/${itemId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response?.status) {
        toast.success(response?.message);
        fetchPricebandList(currentPage); // Fetch with the current page
        setDeleteModalShow(false);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item.");
    } finally {
      setLoading(false); // Hide loader after API call completes
      setDeleteModalShow(false);
      setDeleteItemId(null);
    }
  };

  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };


  const handleModalSuccess = () => {
    fetchPricebandList(currentPage); // Fetch with the current page
  };

  const handleEditItemClick = (item) => {
    setEditItemData(item);
    setEditItemId(item.id);
    setEditModalShow(true);
  };

  const handleViewDetails = (itemId) => {
    setSelectedItemId(itemId);
    setViewItem(true);
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch('https://laravel.snackhouseuk.com/api/admin/items/import', {
        ...requestOptions,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      });

      setLoading(false);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Upload failed');
      }

      const data = await response.json();      
      if (data.status === true) {
        window.location.reload();
        toast.success(data.message);
      } else if (data.status === false) {
        window.location.reload();
        toast.error(data.error);
      }else {
        toast.error("Invalid format");
      }
      fetchPricebandList(currentPage);
    } catch (error) {
      setLoading(false);
      console.error('Upload error:', error);
      toast.error(error.message || 'invalid format');
    } finally {
      setUploadProgress(0); // Reset progress bar
    }
  };


  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdminItemsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onSuccess={handleModalSuccess}
        />
        <AdminItemsEditModal
          show={editmodalShow}
          onHide={() => setEditModalShow(false)}
          itemId={editItemId}
          onSuccess={handleModalSuccess}
          itemData={editItemData}
        />
        <AdminViewItem
          show={viewItem}
          onHide={() => setViewItem(false)}
          itemId={selectedItemId}
        />
        <DeleteConfirmationModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => handleDeleteItem(deleteItemId)}
          loading={loading}
        />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>Items</h2>
                  </div>
                  <div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="btn btn-light ml-2 g-13 m-2"
                      style={{ width: "116px" }}
                    />
                    <a
                      href="#"
                      role="button"
                      onClick={() => setModalShow(true)}
                      className="btn btn-primary"
                    >
                      Add New
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {uploadProgress > 0 && (
              <div className="row mb-4">
                <div className="col-12">
                  <ProgressBar
                    now={uploadProgress}
                    label={`${uploadProgress}%`}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-xxl-5 col-lg-5 d-none d-lg-block">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      {loading ? (
                        <div className="text-center py-5">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>Image</th>
                              <th>Item-code</th>
                              <th>Name</th>
                              <th>Stock</th>
                              <th>Price</th>
                              <th>Discount</th>
                              <th>Discount with</th>
                              <th>Created at</th>
                              <th>Delete &amp; Edit &amp; View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredPricebandList.length > 0 ? (
                              filteredPricebandList.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    <img
                                      src={item.itemImage[0] == null && second ? second : item?.itemImage[0]}
                                      alt=""
                                      className="icon-shape icon-md"
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  </td>
                                  <td>{item.item_code}</td>
                                  <td>
                                    <a className="text-reset">{item.name}</a>
                                  </td>
                                  <td>{item.stock}</td>
                                  <td>
                                    <span className="badge bg-light-primary text-dark-primary">
                                      {item.price}
                                    </span>
                                  </td>
                                  <td>{item.discount}</td>
                                  <td>
                                    {item.discount_with === "0"
                                      ? "%"
                                      : item.discount_with === "1"
                                        ? ""
                                        : "-"}
                                  </td>
                                  <td>
                                    {format(
                                      new Date(item.created_at),
                                      "dd MMMM yyyy"
                                    )}
                                  </td>
                                  <td>
                                    <div className="dropdown">
                                      <td>
                                        <button
                                          className="btn-link dropdown-item text-danger"
                                          onClick={() =>
                                            handleShowDeleteModal(item.id)
                                          }
                                        >
                                          <BsTrash />
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          className="btn-link dropdown-item"
                                          onClick={() =>
                                            handleEditItemClick(item)
                                          }
                                        >
                                          <BsPencilSquare />
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          className="btn-link dropdown-item"
                                          onClick={() =>
                                            handleViewDetails(item.id)
                                          }
                                        >
                                          <BsEye />
                                        </button>
                                      </td>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9" className="text-center">
                                  No Items found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="border-top d-flex justify-content-between align-items-md-center px-6 py-6 flex-md-row flex-column gap-4">
                    <span>
                      Showing {currentPage} to {aItemList.length} of{" "}
                      {totalItems} entries
                    </span>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminItems;
