import React, { useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import { BsTrash, BsPencilSquare, BsThreeDotsVertical } from "react-icons/bs";
import AdimnTax from '../../commponets/AdimnTax';
import { format } from 'date-fns';
import AdminEditTax from '../../commponets/AdminEditTax';
import { getUser } from '../../service/constants';
import Pagination from '../../commponets/Pagination';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
function AdminTax() {
  const [modalShow, setModalShow] = useState(false);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [subSubCategoryID, setSubSubCategoryID] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false); // State for loader
  const { apiAction } = useApi();
  const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
  const subcategory = JSON.parse(localStorage.getItem("subcategory"));
  const [editItemData, setEditItemData] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [editmodalShow, setEditModalShow] = useState(false);
  const user = JSON.parse(getUser());
  const [totalItems, setTotalItems] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  useEffect(() => {
    setSubSubCategoryID(subcategory);
    fetchPricebandList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    filterPricebandList();
  }, [searchTerm, allPricebandList]);

  const fetchPricebandList = async (page) => {
    setLoading(true); // Show loader
    try {
      const response = await apiAction({
        url: `/admin/tax/list?perPage=10&page=${page}&itemSearch=${searchTerm}`,
        method: "GET",
        headers: {
          'Authorization': `Bearer ${user?.token}`
        }
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setTotalItems(response?.data?.total)
        console.log("priceband data: ", response?.data?.total);
        setAllPricebandList(pricebandData);
        setFilteredPricebandList(pricebandData);
        setTotalPages(response?.data?.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error fetching tax list:", error);
      toast.error("Failed to fetch tax list.");
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const filterPricebandList = () => {
    if (searchTerm.trim() === '') {
      setFilteredPricebandList(allPricebandList);
    } else {
      const filteredList = allPricebandList.filter(item =>
        item?.tax_rate?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setFilteredPricebandList(filteredList);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleModalSuccess = () => {
    fetchPricebandList(currentPage);
  };

  const handleDeleteItem = async (itemId) => {
    setLoading(true); // Show loader
    try {
      const response = await apiAction({
        url: `/admin/tax/delete/${itemId}`,
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${user?.token}`
        }
      });
      if (response.status) {
        toast.success(response?.message);
        fetchPricebandList(currentPage); // Update the list after successful deletion
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item.");
    } finally {
      setLoading(false); // Hide loader
      setDeleteModalShow(false);
      setDeleteItemId(null);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleEditItemClick = (item) => {
    setEditItemData(item);
    setEditItemId(item.id);
    setEditModalShow(true);
  };

  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };

  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdimnTax show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
        <AdminEditTax show={editmodalShow} onHide={() => setEditModalShow(false)} itemId={editItemId} onSuccess={handleModalSuccess} itemData={editItemData} />
        <DeleteConfirmationModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => handleDeleteItem(deleteItemId)}
          loading={loading}
        />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>Taxes</h2>
                  </div>
                  <div>
                    <a href="#" role="button" className="btn btn-primary" onClick={() => setModalShow(true)}>
                      Add New
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="p-6">
                    <div className="row justify-content-between">
                      <div className="col-md-4 col-12 mb-2 mb-md-0">
                      <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {loading ? (
                      <div className="text-center py-4">
                        <div className="loader-container" id="loader-container">
                          <div className="loader"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>
                                <div className="form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkAll"
                                  />
                                </div>
                              </th>
                              <th>Tax Rate</th>
                              <th>Date &amp; Time</th>
                              <th>Delete &amp; Edit</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              filteredPricebandList.length > 0 ? (
                                filteredPricebandList?.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="form-check">
                                        <label
                                          className="form-check-label"
                                          htmlFor="orderOne"
                                        />
                                      </div>
                                    </td>
                                    <td>{item?.tax_rate}</td>
                                    <td>{format(new Date(item.created_at), "dd MMMM yyyy")}</td>
                                    <td>
                                      <div className="dropdown">
                                        {/* <a
                                      href="order-list.html#"
                                      className="text-reset"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <BsThreeDotsVertical />
                                    </a> */}
                                        {/* <ul className="dropdown-menu"> */}
                                        <td>
                                          <button
                                            className="btn-link dropdown-item text-danger"
                                            onClick={() => handleShowDeleteModal(item.id)}
                                          >
                                            <BsTrash />

                                          </button>
                                        </td>
                                        <td>
                                          <button
                                            className="btn-link dropdown-item"
                                            onClick={() => handleEditItemClick(item)}
                                          >
                                            <BsPencilSquare />

                                          </button>
                                        </td>
                                        {/* </ul> */}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">No Taxes found.</td>
                                </tr>
                              )
                            }

                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="border-top d-flex justify-content-between align-items-md-center px-6 py-6 flex-md-row flex-column gap-4">
                    <span>Showing {currentPage} to {allPricebandList?.length} of {totalItems} entries</span>
                    {/* <nav>
                    <ul className="pagination mb-0">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                          Previous
                        </button>
                      </li>
                      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav> */}
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminTax;
