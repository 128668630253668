import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import useApi from '../service/useApi';
import { PricebandContext } from '../context/PricebandContext';
import { getUser } from '../service/constants';
import { fetchDataFromAPI } from '../service/api';

function AdminItemCategoryModal(props) {
    const [Item_ID, setItem_ID] = useState('');
    const [Category_Id, setCategory_Id] = useState('');
    const [subCategory_Id, setSubCategory_Id] = useState('');
    const [subSubCategory_Id, setSubSubCategory_Id] = useState('');
    const [ItemData, setitemData] = useState([]);
    const [categorie, setCategorieData] = useState([]);
    const [subSubCategories, setSubSubCategories] = useState([]);
    const [subSubCategoriesList, setSubSubCategoriesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(getUser());
    const { apiAction } = useApi();

    useEffect(() => {
        fetchPricebandList()
        fetchPriceCategoryList()
        fetchPriceSubCategoriesList()
        fetchSub_subCategoriesList()
    }, [])
    
    // item list
    const fetchPricebandList = async() => {
        try {
            const response = await apiAction({
              url: `/admin/item/dropdwon/list`,
              method: 'get',
              headers: {
                Authorization: `Bearer ${user?.token}`,
              },
            });
            console.log("response list",response)
            if (response.status) {
                setitemData(response?.data)
            } else {
              toast.error(response?.message);
            }
          } catch (error) {
            console.error('Error fetching dropdwon list:', error);
            toast.error('Failed to fetch dropdwon list.');
          }
    };


// category list
    const fetchPriceCategoryList = async (page) => {
        try {
          const response = await apiAction({
            url: `/admin/category/list`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          });
          if (response.status) {
            setCategorieData(response?.data)
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          console.error('Error fetching category list:', error);
          toast.error('Failed to fetch category list.');
        }
      };

// subCategories List
      const fetchPriceSubCategoriesList = async (page) => {
        try {
          const response = await apiAction({
            url: `/admin/subcategory/list`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          });
          if (response.status) {
            setSubSubCategories(response?.data)
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          console.error('Error fetching subcategory:', error);
        //   toast.error('Failed to fetch pricebands.');
        }
      };
    

      const fetchSub_subCategoriesList = async (page) => {
        try {
          const response = await apiAction({
            url: `/admin/subsubcategory/list`,
            method: "GET",
            headers: {
              'Authorization': `Bearer ${user?.token}`
            }
          });
          if (response.status) {
            setSubSubCategoriesList(response?.data)
           
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          console.log(error, "------error");
        }
      };

      const handleUploadData = async (e) => {
        e.preventDefault();
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${user?.token}`);

        const formdata = new FormData();
        formdata.append("item_id", Item_ID);
        formdata.append("category_id", Category_Id);
        formdata.append("subcategory_id", subCategory_Id);
        formdata.append("sub_subcategory_id", subSubCategory_Id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://laravel.snackhouseuk.com/api/admin/item/category/create", requestOptions);
            const result = await response.json();
            if (response.ok) {
                console.log(result, "----result");
                props.onHide();
                props.onSuccess();
                toast.success("Item created successfully!");

            } else {
                toast.error(result.message || "Failed to create item.");
            }
        } catch (error) {
            console.error(error);
            toast.error("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };



      
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Add Item Categories</h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleUploadData}>

                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">Item</label>
                                <select
                                    value={Item_ID}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setItem_ID(e?.target?.value)}
                                    required
                                >
                                    <option value="">Item</option>
                                    {ItemData?.map((priceband) => (
                                        <>
                                            <option key={priceband?.id} value={priceband?.id}>{priceband?.name}</option>
                                        </>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">Categorie</label>
                                <select
                                    value={Category_Id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setCategory_Id(e.target.value)}
                                    required
                                >
                                    <option value="">Categorie</option>
                                    {categorie?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">sub-Categorie</label>
                                <select
                                    value={subCategory_Id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setSubCategory_Id(e.target.value)}
                                    required
                                >
                                    <option value="">Sub-Categorie</option>
                                    {subSubCategories?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">sub-SubCategory</label>
                                <select
                                    value={subSubCategory_Id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setSubSubCategory_Id(e.target.value)}
                                    required
                                >
                                    <option value="">Sub-SubCategorie</option>
                                    {subSubCategoriesList?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminItemCategoryModal;
