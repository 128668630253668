import React, { useEffect, useState } from 'react';
import sliderImage1 from '../../../src/assets/images/banner/ADMIN PANEL BANNER 787x300.png';
import "../../Screens/AdminDashbords/style.css";
import { fetchDataFromAPI } from '../../service/api';
import { getUser } from '../../service/constants';
import { toast } from 'react-toastify';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import { BsFillPeopleFill,BsCart ,BsFileBarGraph} from "react-icons/bs";


function AdminDashboards() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const cardStyle = {
    backgroundImage: `url(${sliderImage1})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    margin: '0 auto',
    marginTop: '8rem',
    maxWidth: '75%',
  };

  const user = JSON.parse(getUser());

  useEffect(() => {
    fetchDashboardList();
  }, []);

  const fetchDashboardList = () => {
    setLoading(true);
    fetchDataFromAPI(
      `/admin/home/count`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false);
        if (response.status === true) {
          console.log(response, "--------------response");
          setData(response);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching priceband list:", error);
      });
  };

  return (
    <>
      <AdminNavbar isSidebarVisible={isSidebarVisible} />
      <main className="main-content-wrapper">
        <section className="container">
          <div className="card border-0 rounded-4 align-content-center">
            <img src={sliderImage1} />
          </div>
          <div className="table-responsive-xl mb-6 mb-lg-0 mt-10">
            <div className="row flex-nowrap pb-3 pb-lg-0">
              <div className="col-lg-4 col-12 mb-6 bg ">
                <div className="card h-100 card-lg bg-light-danger">
                  <div className="card-body p-6">
                    <div className="d-flex justify-content-between align-items-center mb-6">
                      <div>
                        <h4 className="mb-0 fs-5">Item Count</h4>
                      </div>
                      <div className="icon-shape icon-md bg-light-danger text-dark-danger rounded-circle">
                        <i className="bi bi-currency-dollar fs-5" />
                        <BsFileBarGraph/>
                      </div>
                    </div>
                    <div className="lh-1">
                      <h1 className="mb-2 fw-bold fs-2">{data?.itemCount}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 mb-6">
                <div className="card h-100 card-lg bg-light-warning">
                  <div className="card-body p-6">
                    <div className="d-flex justify-content-between align-items-center mb-6">
                      <div>
                        <h4 className="mb-0 fs-5">Orders</h4>
                      </div>
                      <div className="icon-shape icon-md bg-light-warning text-dark-warning rounded-circle">
                        <i className="bi bi-cart fs-5" />
                        <BsCart/>
                      </div>
                    </div>
                    <div className="lh-1">
                      <h1 className="mb-2 fw-bold fs-2">{data?.orderCount}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 mb-6">
                <div className="card h-100 card-lg bg-light-info">
                  <div className="card-body p-6">
                    <div className="d-flex justify-content-between align-items-center mb-6">
                      <div>
                        <h4 className="mb-0 fs-5">Customer</h4>
                      </div>
                      <div className="icon-shape icon-md bg-light-info text-dark-info rounded-circle">
                        <i className="bi bi-people fs-5" />
                        <BsFillPeopleFill/>
                      </div>
                    </div>
                    <div className="lh-1">
                      <h1 className="mb-2 fw-bold fs-2">{data?.customerCount}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AdminDashboards;
