import React, { useEffect, useState, useRef } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import { getUser } from '../../service/constants';
import { Container, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../../service/api';
import Processing from '../../commponets/Processing/Processing';

function AdminImage() {
    const user = JSON.parse(getUser());
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]); // For image previews
    const [uploadedImages, setUploadedImages] = useState([]); // Persisted images
    const [loading, setLoading] = useState(false);
    const [itemCode, setItemCode] = useState([]);

    useEffect(() => {
        fetchPricebandList();
    }, []);

    // Create a ref for the file input
    const fileInputRef = useRef(null);

    const handleFile = event => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);

        const previews = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviews(previews);
    };

    const uploadImages = () => {
        const formData = new FormData();
        if (fileInputRef.current.files.length > 0) {
            Array.from(fileInputRef.current.files).forEach((file, index) => {
                formData.append(`file[${index}]`, file);
            });
        }

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${user.token}`);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
        };

        setLoading(true); // Set loading to true before starting the upload

        fetch("https://laravel.snackhouseuk.com/api/admin/bunch/images/upload", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result.data, "---resulttt"); // Log the entire response to inspect its structure
                toast.success(result.data);
                window.location.reload();
                if (Array.isArray(result.images)) {
                    setUploadedImages(prevImages => [...prevImages, ...result.images]);
                } else {
                    console.error('Expected an array of images, but got:', result.images);
                }
                setFiles([]); // Clear the file input after upload
                setPreviews([]);
            })
            .catch(error => console.error('Error uploading images:', error))
            .finally(() => setLoading(false)); // Set loading to false after the upload is complete
    };

    const fetchPricebandList = (page) => {
        setLoading(true);
        fetchDataFromAPI(
            `/admin/bunch/unimages/list`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false);
                if (response.status === true) {
                    console.log('pricebandData', response);
                    setItemCode(response.data);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching priceband list:", error);
            });
    };

    return (
        <>
            <AdminProfile />
            <div className="main-wrapper">
                <AdminNavbar />
                <main className="main-content-wrapper">
                    <div className="container">
                        <div className="row mb-8">
                            <h2>Uploaded Images</h2>
                            <div className="uploaded-images">
                                {uploadedImages.map((image, index) => (
                                    <img key={index} src={image.url} alt={`Uploaded ${index}`} />
                                ))}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-12 mb-5">
                                <div className="card h-100 card-lg">
                                    <Container fluid>
                                        <Card.Body>
                                            <Form.Group>
                                                <label>Upload Images</label>
                                                <Form.Control
                                                    type="file"
                                                    multiple
                                                    required
                                                    ref={fileInputRef}
                                                    onChange={handleFile}
                                                />
                                            </Form.Group>
                                            {previews.map((preview, index) => (
                                                <img key={index} src={preview} alt={`Preview ${index}`} style={{ width: '70px', height: '70px' }} />
                                            ))}
                                            {
                                                loading ? (
                                                    <div className='d-flex justify-content-center align-items-center mt-6'>
                                                        {/* <Spinner animation="border" role="status"> */}
                                                           <Processing/>
                                                        {/* </Spinner> */}
                                                    </div>
                                                ) : (
                                                    
                                                        files.length === 0 ? 
                                                        <div className='d-flex justify-content-end align-items-end mt-6'>
                                                        <Button className='btn btn-secondary' disabled={true}>
                                                            Upload Images
                                                        </Button>
                                                    </div>
                                                    
                                                    :
                                                    <div className='d-flex justify-content-end align-items-end mt-6'>
                                                        <Button onClick={uploadImages} >
                                                            Upload Images
                                                        </Button>
                                                    </div>
                                                    
        
                                                    // <div className='d-flex justify-content-end align-items-end mt-6'>
                                                    //     <Button
                                                    //         onClick={uploadImages}
                                                    //         disabled={files.length === 0}
                                                    //     >
                                                    //         Upload Images
                                                    //     </Button>
                                                    // </div>
                                                )
                                            }
                                        </Card.Body>
                                    </Container>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-12 mb-5">
                                <div className="card h-100 card-lg">
                                    <Container fluid>
                                        <Card.Body>
                                            <h6>All Itemcodes</h6>
                                            <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th />
                                                        <th>All Itemcodes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {itemCode.map((preview, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td />
                                                            <td>{preview}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default AdminImage;
