import React, { useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import { BsTrash, BsPencilSquare, BsThreeDotsVertical } from 'react-icons/bs';
import AdminSubcategoryModal from '../../commponets/AdminSubcategoryModal';
import AdimnEditSubCategoryModal from '../../commponets/AdimnEditSubCategoryModal';
import { format } from 'date-fns';
import { getUser } from '../../service/constants';
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import Button from 'react-bootstrap/Button';
function AdminSubcategory() {
  const [modalShow, setModalShow] = useState(false);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false); // State for loader
  const { apiAction } = useApi();
  const [editItemData, setEditItemData] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [editmodalShow, setEditModalShow] = useState(false);
  const user = JSON.parse(getUser());
  const [totalItems, setTotalItems] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  useEffect(() => {
    // setCategorys(category);
    fetchCategoryList()
    fetchPricebandList(currentPage);
  }, [currentPage]);

  const fetchPricebandList = async (page) => {
    setLoading(true);
    try {
      const response = await apiAction({
        url: `/admin/subcategory/list?perPage=10&page=${page}&itemSearch=${searchTerm}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status) {
        const pricebandData = response.data.data;
        setAllPricebandList(pricebandData);
        setTotalItems(response?.data?.total)
        localStorage.setItem('subcategory', JSON.stringify(pricebandData));
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching pricebands:', error);
    }
    finally {
      setLoading(false); // Hide loader
    }
  };

  const handleModalSuccess = () => {
    fetchPricebandList(); // Refresh the list after adding a new subcategory
  };

  const handleDeleteItem = async (itemId) => {
    setLoading(true); // Show loader
    try {
      const response = await apiAction({
        url: `/admin/subcategory/delete/${itemId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response?.status) {
        toast.success(response?.message);
        fetchPricebandList();
        setDeleteModalShow(false);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item.');
    } finally {
      setLoading(false); // Hide loader
      setDeleteModalShow(false);
      setDeleteItemId(null);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // second list 
  const handleChangeCategory = async (categoryId) => {
    setSelectedCategory(categoryId);
    setLoading(true); // Show loader
    try {

      let url = '/admin/subcategory/list';
      if (categoryId) {
        url = `/admin/subcategory/list/${categoryId}`;
      }

      const response = await apiAction({
        url: url,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status) {
        setAllPricebandList(response.data);
      } else {
        toast.error(response?.message);
        setDeleteModalShow(false);
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      toast.error('Failed to fetch subcategories.');
    }
    finally {
      setLoading(false); // Hide loader
      setDeleteModalShow(false);
      setDeleteItemId(null);
    }
  };

  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredPricebandList = allPricebandList?.filter(item =>
    item?.category?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const handleEditItemClick = (item) => {
    setEditItemData(item);
    setEditItemId(item.id);
    setEditModalShow(true);
  };

  // Function to handle file upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true); // Show loader
      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch("https://laravel.snackhouseuk.com/api/admin/subcategory/import", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
          body: formData,
        });

        const result = await response.json();
        if (result?.status == true) {
          window.location.reload();
          toast.success('File imported successfully');
          fetchPricebandList();
        } else if (result.status === false) {
          window.location.reload();
          toast.error(result.error);
        } else {
          toast.error("Invalid format");
        }
      } catch (error) {
        console.error('Error importing file:', error);
      }
      finally {
        setLoading(false);
      }
    }
  };



  const fetchCategoryList = async (page) => {
    setLoading(true); // Show loader
    try {
      const response = await apiAction({
        url: `/admin/category/list`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status) {
        const pricebandData = response?.data;
        setCategorys(pricebandData)
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching categories list:', error);
    } finally {
      setLoading(false); // Hide loader
    }
  };
  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdminSubcategoryModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
        <AdimnEditSubCategoryModal show={editmodalShow} onHide={() => setEditModalShow(false)} itemId={editItemId} onSuccess={handleModalSuccess} itemData={editItemData} />
        <DeleteConfirmationModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => handleDeleteItem(deleteItemId)}
          loading={loading}
        />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>Subcategories</h2>
                  </div>
                  <div>
                    <input type="file" onChange={handleFileChange} className="btn btn-light ml-2 g-13 m-2" style={{ width: '116px' }} />
                    <button className="btn btn-primary" onClick={() => setModalShow(true)}>
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="p-6">
                    <div className="row justify-content-between">
                      <div className="col-md-4 col-12 mb-2 mb-md-0">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                      <div className="col-lg-2 col-md-4 col-12">
                        <select
                          value={selectedCategory}
                          className="form-control"
                          id="discount_with"
                          onChange={(e) => handleChangeCategory(e.target.value)}
                          required
                        >
                          <option value="">Select categories</option>
                          {categorys?.map((priceband) => (
                            <option key={priceband.id} value={priceband.id}>
                              {priceband.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {loading ? (
                      <div className="text-center py-4">
                        <div className="loader-container" id="loader-container">
                          <div className="loader"></div>
                        </div>
                      </div>
                    ) : (

                      <div className="table-responsive">
                        <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>
                                <div className="form-check">
                                  <label className="form-check-label" htmlFor="checkAll" />
                                </div>
                              </th>
                              <th>Categories Name</th>
                              <th>Sub-Categories Name</th>
                              <th>Date &amp; Time</th>
                              <th>Delete &amp; Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              filteredPricebandList.length > 0 ? (
                                filteredPricebandList?.map((item) => (
                                  <tr key={item.id}>
                                    <td>
                                      <div className="form-check">
                                        <label className="form-check-label" htmlFor="orderOne" />
                                      </div>
                                    </td>
                                    <td>{item?.category?.name}</td>
                                    <td>{item.name}</td>
                                    <td>{format(new Date(item.created_at), "dd MMMM yyyy")}</td>
                                    <td>
                                      <div className="dropdown">
                                        <td>
                                          <button className=" btn-link  dropdown-item text-danger" onClick={() => handleShowDeleteModal(item.id)}>
                                            <BsTrash />
                                          </button>
                                        </td>
                                        <td>
                                          <button className=" btn-link  dropdown-item" onClick={() => handleEditItemClick(item)}>
                                            <BsPencilSquare />
                                          </button>
                                        </td>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">No subcategories found.</td>
                                </tr>
                              )
                            }

                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="border-top d-flex justify-content-between align-items-md-center px-6 py-6 flex-md-row flex-column gap-4">
                    <span>Showing {currentPage} to {totalPages} of  {totalItems} entries</span>

                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminSubcategory;
