import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { getUser } from '../service/constants';
import { fetchDataFromAPI } from '../service/api';
import useApi from '../service/useApi';

function AdminEditItemCategories({ show, onHide, itemData, onSuccess }) {
    const [name, setName] = useState(itemData?.category?.name);
    const [Item_ID, setItem_ID] = useState('');
    const [Category_Id, setCategory_Id] = useState('');
    const [subCategory_Id, setSubCategory_Id] = useState('');
    const [subSubCategory_Id, setSubSubCategory_Id] = useState('');
    const [ItemData, setitemData] = useState([]);
    const [categorie, setCategorieData] = useState([]);
    const [subSubCategories, setSubSubCategories] = useState([]);
    const [subSubCategoriesList, setSubSubCategoriesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const category = JSON.parse(localStorage.getItem('category'));
    const subcategory = JSON.parse(localStorage.getItem('subcategory'));
    const subsubCategory = JSON.parse(localStorage.getItem('subsubCategory'));
    
    const { apiAction } = useApi();
    const user = JSON.parse(getUser());
    useEffect(() => {
        if (itemData) {
            setItem_ID(itemData.item_id || '');
            setCategory_Id(itemData?.category_id || '');
            setSubCategory_Id(itemData.subcategory_id || '');
            setSubSubCategory_Id(itemData?.sub_subcategory_id || '');
        }
    }, [itemData]);
    useEffect(() => {
        fetchPricebandList()
        fetchPriceCategoryList()
        fetchPriceSubCategoriesList()
        fetchSub_subCategoriesList()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${user?.token}`);

            const formdata = new FormData();
            formdata.append("item_id", Item_ID);
            formdata.append("category_id", Category_Id);
            formdata.append("subcategory_id", subCategory_Id);
            formdata.append("sub_subcategory_id", subSubCategory_Id);
            formdata.append("id", itemData?.id);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            const response = await fetch(`https://laravel.snackhouseuk.com/api/admin/item/category/create`, requestOptions);
            const result = await response.json();

            if (response.ok) {
                toast.success('Subcategory updated successfully!');
                onHide(); // Close modal on success
                if (onSuccess) {
                    onSuccess(result); // Optional: Pass result to parent component if needed
                }
            } else {
                toast.error(result.message || 'Failed to update subcategory.');
            }
        } catch (error) {
            console.error('Failed to update subcategory:', error);
            toast.error('Failed to update subcategory.');
        } finally {
            setLoading(false); // Reset loading state regardless of success or failure
        }
    };

    // item list
    const fetchPricebandList = () => {
        fetchDataFromAPI(
            `/admin/item/dropdwon/list`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                // setLoading(true);
                console.log("response--->>", response);
                if (response.status == true) {
                    setitemData(response?.data)
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                // setLoading(false)
                console.log(error, "---error");
            });
    };


    // category list
    const fetchPriceCategoryList = async (page) => {
        try {
            const response = await apiAction({
                url: `/admin/category/list`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user?.token}`,
                },
            });
            if (response.status) {
                setCategorieData(response?.data)
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error fetching category list:', error);
            toast.error('Failed to fetch category list.');
        }
    };

    // subCategories List
    const fetchPriceSubCategoriesList = async (page) => {
        try {
            const response = await apiAction({
                url: `/admin/subcategory/list`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user?.token}`,
                },
            });
            if (response.status) {
                setSubSubCategories(response?.data)
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error fetching pricebands:', error);
            //   toast.error('Failed to fetch pricebands.');
        }
    };


    const fetchSub_subCategoriesList = async (page) => {
        try {
            const response = await apiAction({
                url: `/admin/subsubcategory/list`,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });
            if (response.status) {
                setSubSubCategoriesList(response?.data)

            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.log(error, "------error");
        }
    };


    return (
        <Modal show={show} onHide={onHide} size="sm" centered>
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold">Edit Item Categories</h5>
                        <Button variant="close" onClick={onHide} />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">Item</label>
                                <select
                                    value={Item_ID}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setItem_ID(e?.target?.value)}
                                    required
                                >
                                    <option value="">{itemData?.item?.name}</option>
                                    {ItemData?.map((priceband) => (
                                        <>
                                            <option key={priceband?.id} value={priceband?.id}>{priceband?.name}</option>
                                        </>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">Categorie</label>
                                <select
                                    value={Category_Id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setCategory_Id(e.target.value)}
                                    required
                                >
                                    <option value="">{itemData?.subcategory?.name}</option>
                                    {categorie?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">subCategorie</label>
                                <select
                                    value={subCategory_Id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setSubCategory_Id(e.target.value)}
                                    required
                                >
                                    <option value="">{itemData?.subcategory?.name}</option>
                                    {subcategory?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">sub-SubCategorie</label>
                                <select
                                    value={subSubCategory_Id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setSubSubCategory_Id(e.target.value)}
                                    required
                                >
                                    <option value="">{itemData?.sub_sub_category?.name}</option>
                                    {subsubCategory?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    onClick={onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="ms-2"
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminEditItemCategories;
