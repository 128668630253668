import React, { useState } from 'react';
import graphics from "../../assets/images/banner/size332x332.png";
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); // State to manage loading state

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when starting the request
        const body = {
            email: email,
        };
        fetchDataFromAPI("/user/forget/password", "post", body, "")
            .then((res) => {
                if (res.status === true) {
                    toast.success(res?.message);
                    navigate("/reset-password", { state: { email } });
                } else {
                    toast.error(res.errors[0]);
                }
            })
            .catch((error) => {
                console.log("error", error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after request completes
            });
    };

    return (
        <main>
            <section className="my-lg-14 my-8">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
                            <img
                                src={graphics}
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1 d-flex align-items-center">
                            <div>
                                <div className="mb-lg-9 mb-5">
                                    <h1 className="mb-2 h2 fw-bold">Forgot your password?</h1>
                                    <p>
                                        Please enter the email address associated with your account and
                                        We will email you a link to reset your password.
                                    </p>
                                </div>
                                <form className="needs-validation" noValidate="" onSubmit={handleForgotPassword}>
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <label
                                                htmlFor="formForgetEmail"
                                                className="form-label visually-hidden"
                                            >
                                                Email address
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="formForgetEmail"
                                                placeholder="Email"
                                                required=""
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <div className="invalid-feedback">
                                                Please enter correct password.
                                            </div>
                                        </div>
                                        <div className="col-12 d-grid gap-2">
                                            {loading ? (
                                                <button className="btn btn-primary" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn btn-primary">
                                                    Reset Password
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ForgotPassword;
