import React, { useState } from 'react'
import about3 from "../../assets/images/banner/Contactus-468x194.png";
import Header from '../Header/Header';
import { fetchDataFromAPI } from '../../service/api';
import { getUser } from '../../service/constants';
import { toast } from 'react-toastify';
import Footer from '../Footer/Footer';
import Processing from '../../commponets/Processing/Processing';
import { useNavigate } from 'react-router-dom';
// hero-img-slider-2.jpg
function ContactUs() {
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false); // Loader state
    const [errors, setErrors] = useState({}); // Validation errors state
    const user = JSON.parse(getUser());
    const navigate = useNavigate();
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!email) {
            formErrors.email = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formErrors.email = "Email is invalid.";
            isValid = false;
        }

        if (!content) {
            formErrors.content = "Message content is required.";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleUploadData = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return; // If the form is not valid, don't submit
        }

        setLoading(true); // Show loader
        const requestBody = {
            email: email,
            content: content
        };
        fetchDataFromAPI("/user/contact/us", "post", requestBody, user?.token)
            .then((response) => {
                if (response.status === true) {
                    toast.success(response?.message);
                    navigate("/");
                } else {
                    if (response.data?.type) {
                        toast.error(response.data.type[0]);
                    } else {
                        toast.error(response.data?.message || "An error occurred");
                    }
                }
                setLoading(false); // Hide loader after response
            })
            .catch((error) => {
                console.log("error", error);
                toast.error("An error occurred. Please try again.");
                setLoading(false); // Hide loader on error
            });
    };

    return (
        <>
            <Header />
            <hr />
            <main>
                <section className="mt-8">
                    <div className="container">
                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                </ol>
                            </nav>
                            <div className="offset-lg-1 col-lg-10 col-12 mt-7">
                                <h2>Contact Us</h2>
                                <div className="row justify-content-between mb-14 mt-7">
                                    <div className="col-md-6">
                                        <div>
                                            <img
                                                style={{ width: '600px' }}
                                                src={about3}
                                                alt=""
                                                className="img-fluid rounded"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-7 col-lg-12">
                                            <form className="needs-validation" noValidate onSubmit={handleUploadData}>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email:*</label>
                                                    <input
                                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                        id="email"
                                                        type="email"
                                                        placeholder="Email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="content" className="form-label">Message:*</label>
                                                    <textarea
                                                        className={`form-control ${errors.content ? 'is-invalid' : ''}`}
                                                        id="content"
                                                        placeholder="Message"
                                                        value={content}
                                                        onChange={(e) => setContent(e.target.value)}
                                                        required
                                                    />
                                                    {errors.content && <div className="invalid-feedback">{errors.content}</div>}
                                                </div>
                                                <div className="d-flex mb-3">
                                                    <div className="mb-3">
                                                        <button className="btn btn-primary" type="submit" disabled={loading}>
                                                            {loading ? 'Sending...' : 'Send'}
                                                        </button>
                                                    </div>
                                                </div>
                                                {loading && <Processing />} {/* Show loader */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default ContactUs;