import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo/116x116.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import useApi from "../../service/useApi";
import constants, { getUser } from "../../service/constants";
import { toast } from "react-toastify";
import { PricebandContext } from "../../context/PricebandContext";
import LoginModal from "../../commponets/LoginModal";
import { BsChevronDown } from "react-icons/bs";
import RegisterModal from "../../commponets/RegisterModal";
import "../../Screens/Header/Header.css";
import { fetchDataFromAPI } from "../../service/api";
import { Button } from "react-bootstrap";
import { BsFillPersonPlusFill } from "react-icons/bs";

// import '../../Screens/Header/Header.css'
function Header() {
  const [modalShow, setModalShow] = useState(false);
  const [singupModalShow, setSingupModalShow] = useState(false);
  const [modalShowRegistre, setModalShowregistre] = useState(false);
  const { cart } = useCart();
  const cartCount = cart?.length;
  const { apiAction } = useApi();
  const user = JSON.parse(getUser());
  const [currentPage, setCurrentPage] = useState(1);
  const [itemData, setItemData] = useState([]);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [category, setCategory] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { setCategoryID, setSubCategoryID, setSubSubCategoryID } =
    useContext(PricebandContext);
  const { setCategoryName, setSubCategoryName, setSubSubCategoryName } =
    useContext(PricebandContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const { pathname } = useLocation();
  const [suggations, setSuggations] = useState([]);

  useEffect(() => {
    suggationsFun();
  }, [search]);

  const suggationsFun = () => {
    fetchDataFromAPI(
      `/user/item/search/suggestion?itemSearch=${search}`,
      "get",
      "",
      user?.token
    )
      .then((res) => {
        setSuggations(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleInputChange = (e) => {
    navigate("/product", { state: { search: search } });
    // pathname: "/product",
    // state: { prevTitle: "Items" },
    // fetchDataFromAPI(
    //   `/user/customer/item/search?itemSearch=${searchTerm}`,
    //   "get",
    //   "",
    //   user?.token
    // )
    //   .then((res) => {
    //     console.log("res", res);
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  };
  useEffect(() => {
    fetchItemList();
    fetchItemList1();
  }, [searchQuery]);

  const fetchItemList1 = async () => {
    try {
      let apiUrl = `/user/item/list?page=${currentPage}&itemSearch=${searchQuery}`;

      const response = await apiAction({
        url: apiUrl,
        method: "GET",
      });

      if (response.status === true) {
        const pricebandData = response?.data?.data;
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      // setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchItemList = async () => {
    try {
      const response = await apiAction({
        url: `/user/item/list?page=${currentPage}`,
        method: "GET",
      });
      if (response.status === true) {
        setItemData(response?.data?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    }
  };

  const btnLogOut = () => {
    localStorage.removeItem(constants.USER);
    toast.success("Successfully logged out.");
    navigate("/dashboard");
    window.location.reload();
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    try {
      const response = await apiAction({
        url: "/user/category/list",
        method: "GET",
      });
      if (response.status === true) {
        setCategory(response.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("Error fetching categories:", error);
    }
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await apiAction({
        url: "/user/cate/sub/sub-sub",
        method: "GET",
      });
      if (response.status === true) {
        const cleanedCategories = response.data
          .map((cat) => ({
            ...cat,
            name: cat.name ? cat.name.trim().replace(/\s{2,}/g, "") : "",
            subcategory: cat.subcategory
              ? cat.subcategory
                  .map((subcat) => ({
                    ...subcat,
                    name: subcat.name
                      ? subcat.name.trim().replace(/\s{2,}/g, "")
                      : "",
                    subsubcategory: subcat.subsubcategory
                      ? subcat.subsubcategory
                          .map((subsubcat) => ({
                            ...subsubcat,
                            name: subsubcat.name
                              ? subsubcat.name.trim().replace(/\s{2,}/g, "")
                              : "",
                          }))
                          .filter((subsubcat) => subsubcat.name)
                      : [],
                  }))
                  .filter(
                    (subcat) => subcat.name || subcat.subsubcategory.length > 0
                  )
              : [],
          }))
          .filter((cat) => cat.name || cat.subcategory.length > 0);
        setCategories(cleanedCategories);
      } else {
        console.error("Failed to fetch categories:", response.message);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryClick = (cat) => {
    setCategoryID(cat.id);
    setCategoryName(cat.name);
    setSubCategoryName("");
    setSubSubCategoryName("");
    navigate(`/product`);
    setSubCategoryID("");
    setSubSubCategoryID("");
  };

  const handleSubCategoryClick = (subcategory) => {
    setSubCategoryID(subcategory.id);
    setSubCategoryName(subcategory.name);
    setSubSubCategoryName("");
    setCategoryName("");
    navigate(`/product`);
    setSubSubCategoryID("");
    setCategoryID("");
  };

  const handleSubSubCategoryClick = (subsubcategory) => {
    setSubSubCategoryID(subsubcategory?.id);
    setSubSubCategoryName(subsubcategory?.name);
    navigate(`/product`);
    setSubCategoryID("");
    setCategoryID("");
    setSubCategoryName("");
    setCategoryName("");
  };

  const renderCategoriesResponsive = () => (
    <div className="offcanvas-body ps-lg-2 pt-lg-0">
      <div className="mb-8">
        {/* title */}
        <h5 className="mb-3">Categories</h5>
        {/* nav */}
        <ul className={`nav nav-category`} id="categoryCollapseMenu">
          {categories.map((cat) => (
            <li key={cat.id} className="nav-item border-bottom w-100">
              <a
                href="#"
                className="nav-link collapsed"
                data-bs-toggle="collapse"
                data-bs-target={`#categoryFlush${cat.id}`}
                aria-expanded="false"
                aria-controls={`categoryFlush${cat.id}`}
                onClick={() => handleCategoryClick(cat)}
              >
                {cat?.name}
                <i className="feather-icon icon-chevron-right" />
              </a>
              {/* accordion collapse */}
              {cat.subcategory.length > 0 && (
                <div
                  id={`categoryFlush${cat?.id}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#categoryCollapseMenu"
                >
                  <div>
                    {/* nav */}
                    <ul className="nav flex-column ms-3">
                      <li className="nav-item border-bottom w-100">
                        <Link
                          to="/product"
                          className="nav-link text-decoration-none text-inherit"
                          onClick={() => handleCategoryClick(cat)}
                        >
                          All {cat?.name}
                        </Link>
                      </li>
                      {cat.subcategory.map((subcat) => (
                        <li
                          key={subcat.id}
                          className="nav-item border-bottom w-100"
                        >
                          <a
                            href="#"
                            className="nav-link collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target={`#subcategoryFlush${subcat.id}`}
                            aria-expanded="false"
                            aria-controls={`subcategoryFlush${subcat.id}`}
                            onClick={() => handleSubCategoryClick(subcat)}
                          >
                            {subcat?.name}
                            {subcat?.subsubcategory?.length > 0 && (
                              <i className="feather-icon icon-chevron-right" />
                            )}
                          </a>
                          {subcat?.subsubcategory?.length > 0 && (
                            <div
                              id={`subcategoryFlush${subcat.id}`}
                              className="accordion-collapse collapse"
                              data-bs-parent={`#categoryFlush${cat.id}`}
                            >
                              <ul className="nav flex-column ms-3">
                                {subcat?.subsubcategory?.map((subsubcat) => (
                                  <li key={subsubcat?.id} className="nav-item">
                                    <Link
                                      to="/product"
                                      className="nav-link text-decoration-none text-inherit"
                                      onClick={() =>
                                        handleSubSubCategoryClick(subsubcat)
                                      }
                                    >
                                      {subsubcat?.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  const navigationData = () => {
    navigate("/dashboard");
    window.location.reload();
  };

  const handleSearch = async () => {
    try {
      const response = await apiAction({
        url: `/user/customer/item/search?itemSearch=${searchTerm}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status === true) {
        // navigate(`/product?search=${searchTerm}`);
        setSearchTerm(response.data.data);
      } else {
        // toast.error(response?.message);
      }
    } catch (error) {
      console.log("Error fetching categories:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
    handleInputChange();
  };

  const btnClick = (item) => {
    navigate(`/productList/${item?.id}`);
    window.location.reload();

  };

  return (
    <>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      <RegisterModal
        show={modalShowRegistre}
        onHide={() => setModalShowregistre(false)}
      />
      <div className="py-5">
        <div className="container">
          <div className="row w-100 align-items-center justify-content-center gx-lg-2 gx-0">
            <div className="col-xxl-2 col-lg-3 col-md-6 col-5">
              <a className="navbar-brand d-none d-lg-block" href="index.html">
                <Link onClick={navigationData}>
                  <img
                    src={logo}
                    alt="eCommerce HTML Template"
                    className="navbar-brand img-fluid"
                    style={{ maxWidth: "60px" }}
                  />
                </Link>
              </a>
              <div className="d-flex justify-content-between w-100 d-lg-none">
                <a className="navbar-brand" href="index.html">
                  <Link onClick={navigationData}>
                    <img
                      src={logo}
                      alt="eCommerce HTML Template"
                      className="navbar-brand img-fluid"
                      style={{ maxWidth: "60px" }}
                    />
                  </Link>
                </a>
              </div>
            </div>

            <div className="search-container col-xxl-5 col-lg-5 d-none d-lg-block">
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    className="form-control"
                    placeholder="Search for products"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button onClick={() => handleInputChange()}>
                    <span>Search</span>
                  </Button>
                </div>
              </form>
              <div className="suggestions-dropdown">
                <ul className="submenu">
                  {suggations?.map((item, index) => (
                    <li key={index}>
                      <div onClick={() => btnClick(item)}>
                        <a
                          className="text-muted position-relative h-10 w-10"
                          href="#"
                          role="button"
                        >
                          <small>{item?.name}</small>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-xxl-2 text-end col-md-6 col-7">
              <div className="list-inline">
                {/* wishlist */}
                {user == null ? (
                  <div className="list-inline-item me-5">
                    <Link to={"/userSignUp"}>
                      <a
                        className="text-muted position-relative h-100 w-100"
                        href="#"
                        role="button"
                      >
                        <BsFillPersonPlusFill
                          style={{ width: "170%", height: "170%" }}
                        />
                      </a>
                    </Link>
                  </div>
                ) : (
                  <div className="list-inline-item me-5">
                    <Link
                      to={"/wishlist"}
                      className="text-muted position-relative"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-heart"
                      >
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                      </svg>
                    </Link>
                  </div>
                )}

                {/* cart */}
                {user === null ? null : (
                  <div className="list-inline-item me-5 me-lg-0">
                    <Link to={"/cart"} className="text-muted position-relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-shopping-bag"
                      >
                        <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <path d="M16 10a4 4 0 0 1-8 0"></path>
                      </svg>
                      {cartCount > 0 && (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                          {cartCount}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      )}
                    </Link>
                  </div>
                )}

                <div className="list-inline-item me-5 me-lg-0">
                  <ul className="navbar-nav align-items-center d-flex justify-content-between">
                    <li className="dropdown w-100 w-lg-auto">
                      <a
                        className="nav-link  w-100"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="grey"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to={"/orderList"}>
                            <a
                              className="dropdown-item"
                              href="pages/signup.html"
                            >
                              Orders
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/settings"}>
                            <a
                              className="dropdown-item"
                              href="pages/signup.html"
                            >
                              Settings
                            </a>
                          </Link>
                        </li>

                        {user === null ? (
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => setModalShow(true)}
                            >
                              Login
                            </a>
                          </li>
                        ) : (
                          <li onClick={() => btnLogOut()}>
                            <a className="dropdown-item" href="#" role="button">
                              Logout
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>

                {/* menu bar */}
                <div className="list-inline-item d-inline-block d-lg-none">
                  <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#navbar-default"
                    aria-controls="navbar-default"
                    aria-label="Toggle navigation"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={32}
                      height={32}
                      fill="currentColor"
                      className="bi bi-text-indent-left text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* nav */}
      <div className="py-5">
        <nav
          className="navbar navbar-expand-lg navbar-light navbar-default py-0 pb-lg-4"
          aria-label="Offcanvas navbar large"
        >
          <div className="container">
            <div className="row w-100 align-items-center justify-content-center gx-lg-2 gx-0">
              <div className="col-auto">
                <div
                  className="offcanvas offcanvas-start"
                  tabIndex={-1}
                  id="navbar-default"
                  aria-labelledby="navbar-defaultLabel"
                >
                  <div className="offcanvas-header pb-1">
                    <Link to={`/dashboard`}>
                      <img
                        src={logo}
                        alt="eCommerce HTML Template"
                        className="navbar-brand img-fluid"
                        style={{ maxWidth: "60px" }}
                      />
                    </Link>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <div className="d-block d-lg-none mb-4">
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <input
                            className="form-control"
                            placeholder="Search for products"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <Button onClick={() => handleInputChange()}>
                            <span>Search</span>
                          </Button>
                        </div>
                      </form>
                    </div>

                    <div className="d-block d-lg-none mb-4">
                      <a
                        className="btn btn-primary w-100 d-flex justify-content-center align-items-center"
                        data-bs-toggle="collapse"
                        href="index.html#collapseExample"
                        role="button"
                        aria-controls="collapseExample"
                        onClick={() => setSelectedCategory(!selectedCategory)}
                        aria-expanded={selectedCategory ? "true" : "false"}
                      >
                        <span className="me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-grid"
                          >
                            <rect x={3} y={3} width={7} height={7} />
                            <rect x={14} y={3} width={7} height={7} />
                            <rect x={14} y={14} width={7} height={7} />
                            <rect x={3} y={14} width={7} height={7} />
                          </svg>
                        </span>
                        All Categories
                      </a>
                      <div className="collapse mt-2" id="collapseExample">
                        <div className="card card-body">
                          {renderCategoriesResponsive()}
                        </div>
                      </div>
                    </div>
                    <ul className="navbar-menu dropdown me-3 d-none d-lg-block ">
                      <li className="has-children">
                        <button
                          className="btn btn-primary px-6"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-grid"
                            >
                              <rect x={3} y={3} width={7} height={7} />
                              <rect x={14} y={3} width={7} height={7} />
                              <rect x={14} y={14} width={7} height={7} />
                              <rect x={3} y={14} width={7} height={7} />
                            </svg>
                          </span>
                          All Categories
                        </button>
                        {
                          <ul className="dropdown-menu">
                            {categories.map((cat) => (
                              <li key={cat.id} className="">
                                <Link
                                  to={`/product`}
                                  className="dropdown-item"
                                  onClick={() => handleCategoryClick(cat)}
                                >
                                  {cat.name}
                                </Link>
                                {cat.subcategory.length > 0 && (
                                  <ul className="dropdown-menu">
                                    <li>
                                      <Link
                                        to={`/product`}
                                        onClick={() => handleCategoryClick(cat)}
                                      >
                                        All {cat.name}
                                      </Link>
                                    </li>
                                    {cat.subcategory.map((subcat) => (
                                      <li
                                        key={subcat.id}
                                        className="dropdown-submenu"
                                      >
                                        <Link
                                          to={`/product`}
                                          onClick={() =>
                                            handleSubCategoryClick(subcat)
                                          }
                                          className="dropdown-item"
                                        >
                                          {subcat.name}
                                        </Link>
                                        {subcat.subsubcategory.length > 0 && (
                                          <ul className="dropdown-menu">
                                            {subcat.subsubcategory.map(
                                              (subsubcat) => (
                                                <li key={subsubcat.id}>
                                                  <Link
                                                    to={`/product`}
                                                    onClick={() =>
                                                      handleSubSubCategoryClick(
                                                        subsubcat
                                                      )
                                                    }
                                                    className="dropdown-item"
                                                  >
                                                    {subsubcat.name}
                                                  </Link>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        }
                      </li>
                    </ul>

                    <div>
                      <ul className="navbar-nav align-items-center d-flex justify-content-between">
                        {categories.map((cat) => (
                          <li
                            key={cat.id}
                            className="nav-item dropdown w-100 w-lg-auto"
                          >
                            <Link
                              to={`/product`}
                              className="text-decoration-none text-inherit"
                              onClick={() => handleCategoryClick(cat)}
                            >
                              <a className="nav-link  w-100">{cat.name}</a>
                            </Link>
                            {cat.subcategory.length > 0 && (
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    to={`/product`}
                                    onClick={() => handleCategoryClick(cat)}
                                    className="dropdown-item"
                                  >
                                    All {cat.name}
                                  </Link>
                                </li>
                                {cat.subcategory.map((subcat) => (
                                  <li
                                    key={subcat.id}
                                    className="dropdown-submenu"
                                  >
                                    <Link
                                      to={`/product`}
                                      onClick={() =>
                                        handleSubCategoryClick(subcat)
                                      }
                                      className="dropdown-item"
                                    >
                                      {subcat.name}
                                    </Link>
                                    {subcat.subsubcategory.length > 0 && (
                                      <ul className="dropdown-menu">
                                        {subcat.subsubcategory.map(
                                          (subsubcat) => (
                                            <li key={subsubcat.id}>
                                              <Link
                                                to={`/product`}
                                                onClick={() =>
                                                  handleSubSubCategoryClick(
                                                    subsubcat
                                                  )
                                                }
                                                className="dropdown-item"
                                              >
                                                {subsubcat.name}
                                              </Link>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* account */}
                    <div>
                      <ul className="navbar-nav align-items-center d-flex justify-content-between">
                        <li className="nav-item dropdown w-100 w-lg-auto">
                          <a
                            className="nav-link  w-100"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Contact Us
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link to={"/contact-us"}>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  role="button"
                                >
                                  Contact Us
                                </a>
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;
