import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { fetchDataFromAPI } from "../service/api";
import { getUser } from "../service/constants";
import bike from "../assets/images/avatar/man-scooter.png";

function SalesOrderPayment({ show, onHide, onSuccess, itemId, onUpdate }) {
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(getUser());

  const handleUploadData = (newStatus) => {
    console.log("newStatus", newStatus);
    console.log("id", itemId);

    setLoading(true);

    const formData = new FormData();
    formData.append("id", itemId);
    formData.append("orderStatus", newStatus);
    // const requestBody = {
    //   id: itemId,
    //   orderStatus: newStatus,
    // };

    // console.log("requestBody",requestBody)

    fetchDataFromAPI("/sale/order/status", "post", formData, user?.token)
      .then((response) => {
        console.log("response", response);

        setLoading(false);
        if (response.status) {
          console.log("response", response);
          toast.success(response.message);
          // onSuccess(); // Pass the new status to the parent component
          onHide();
          onUpdate(); // Update the order list
        } else {
          console.log("error",response.errors)
          // toast.error(response.errors?.id[0] || "An error occurred");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occurred");
        console.log("error", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content p-1">
          <div className="modal-header border-0">
            <h4 className="modal-title fs-3 fw-bold" id="userModalLabel">
              Change Delivery Status
            </h4>
            <Button variant="close" onClick={onHide} />
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center mb-3">
              <img src={bike} alt="Product" className="icon-shape icon-xxxl" />
            </div>
            <div className="d-flex mb-3">
              <Button
                type="button"
                className="btn btn-primary w-100"
                disabled={loading}
                style={{ marginLeft: 7 }}
                onClick={() => handleUploadData(1)}
              >
                Accepted
              </Button>
              <Button
                type="button"
                className="btn btn-secondary w-100"
                disabled={loading}
                style={{ marginLeft: 7 }}
                onClick={() => handleUploadData(2)}
              >
                Cancelled
              </Button>
              <Button
                type="button"
                className="btn btn-secondary w-100"
                disabled={loading}
                style={{ backgroundColor: "#ba8b0b", marginLeft: 7 }}
                onClick={() => handleUploadData(3)}
              >
                Delivered
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SalesOrderPayment;
