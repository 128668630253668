import React, { createContext, useState } from "react";

// Create the context
export const PricebandContext = createContext();

// Create the provider component
export const PricebandProvider = ({ children }) => {
  const [pricebandData, setPricebandData] = useState([]);
  const [pricebandID, setPricebandID] = useState([]);
  const [subCategoryid, setSubCategoryId] = useState("");
  const [subSubCategoryid, setSubSubCategoryId] = useState("");

  const [categoryID, setCategoryID] = useState("");
  const [suggestionsId, setSuggestionsID] = useState("");
  const [subCategoryID, setSubCategoryID] = useState("");
  const [subSubCategoryID, setSubSubCategoryID] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subSubCategoryName, setSubSubCategoryName] = useState("");
  const [suggestionsName, setSuggestionsName] = useState("");

  return (
    <PricebandContext.Provider
      value={{
        pricebandData,
        setPricebandData,
        pricebandID,
        setPricebandID,
        subCategoryid,
        setSubCategoryId,
        subSubCategoryid,
        setSubSubCategoryId,
        subSubCategoryID,
        setSubSubCategoryID,
        subCategoryID,
        setSubCategoryID,
        categoryID,
        setCategoryID,
        suggestionsId,
        setSuggestionsID,
        categoryName,
        setCategoryName,
        subCategoryName,
        setSubCategoryName,
        subSubCategoryName,
        setSubSubCategoryName,
        suggestionsName,
        setSuggestionsName
        
      }}
    >
      {children}
    </PricebandContext.Provider>
  );
};
