import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { getUser } from '../service/constants';
import useApi from '../service/useApi';

function AdminSubcategoryModal(props) {
    const [name, setName] = useState('');
    const { apiAction } = useApi();
    const [priceband_id, setPriceband_id] = useState('');
    const [loading, setLoading] = useState(false);
    const [storedPricebands, setStoredPricebands] = useState([])

    useEffect(() => {
        fetchCategoryList()
    }, [])
    const user = JSON.parse(getUser());
    const handleUploadData = async (e) => {
        e.preventDefault();
        setLoading(true);

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${user?.token}`);
        const formdata = new FormData();
        formdata.append("name", name);
        formdata.append("category_id", priceband_id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://laravel.snackhouseuk.com/api/admin/subcategory/create", requestOptions);
            const result = await response.json();
            if (response.ok) {
                console.log(response, "----responseresponse");
                toast.success(response.message);
                props.onHide();
                props.onSuccess();
            } else {
                toast.error(result.message || "Failed to create item.");
            }
        } catch (error) {
            console.error(error);
            toast.error("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const fetchCategoryList = async (page) => {
        setLoading(true); // Show loader
        try {
            const response = await apiAction({
                url: `/admin/category/list`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user?.token}`,
                },
            });
            if (response.status) {
                const pricebandData = response?.data;
                setStoredPricebands(pricebandData)
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error fetching categories list:', error);
        } finally {
            setLoading(false); // Hide loader
        }
    };
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Add Subcategories</h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleUploadData}>
                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">Select categories</label>
                                <select
                                    value={priceband_id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setPriceband_id(e.target.value)}
                                    required
                                >
                                    <option value="">Select categories</option>
                                    {storedPricebands?.map((priceband) => (
                                        <option key={priceband.id} value={priceband.id}>
                                            {priceband.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">Please Select categories.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="SubCategoryname" className="form-label">Subcategorie name</label>
                                <input
                                    value={name}
                                    className="form-control"
                                    id="SubCategoryname"
                                    placeholder="SubCategory Name"
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter Subcategorie name.</div>
                            </div>

                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminSubcategoryModal;
