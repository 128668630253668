const BASE_URL = "https://laravel.snackhouseuk.com/api";

const useApi = () => {
  const apiAction = async ({ url, method, headers = {}, body = null }) => {
    try {
      const requestOptions = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        body: body,
      };

      const response = await fetch(`${BASE_URL}${url}`, requestOptions);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }

      return data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  };

  return { apiAction };
};

export default useApi;
