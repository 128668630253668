import React, { useState, useEffect } from 'react';
import secondMobile from '../../assets/images/banner/User-mobile-size-320x228.png';
import secondDesktop from '../../assets/images/banner/User-desktop-size_1099x140_1.png';
import threeMobile from '../../assets/images/banner/User-mobile-size-320x228-2.png';
import threeDesktop from '../../assets/images/banner/User-desktop-size-1099x140_2.png';
import Slider from "react-slick";
import "../../Screens/MainScreen/MainScreen.css";

function MainScreen() {
  const [images, setImages] = useState({
    second: secondMobile,
    three: threeMobile
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setImages({
          second: secondDesktop,
          three: threeDesktop
        });
      } else {
        setImages({
          second: secondMobile,
          three: threeMobile
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000 
  };

  return (
    <main>
      <section className='homeSlider'>
        <div className='container-fluid'>
          <Slider {...settings} className='home_slider_main'>
            <div className="item w-100 ml-n25">
              <img src={images.second} className="w-100" alt="Banner" />
            </div>
            <div className="item w-100 ml-n24">
              <img src={images.three} className="w-100" alt="Banner" />
            </div>
          </Slider>
        </div>
      </section>
    </main>
  );
}

export default MainScreen;
