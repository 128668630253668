import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Termsconditions() {
    return (
        <>
            <Header />
            <div className='navbar navbar-expand-lg navbar-light navbar-default py-0 py-lg-2 border-top navbar-offcanvas-color'></div>
            <div className="container mt-5">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{'Terms and Conditions'}</li>
                    </ol>
                </nav>
                <h1 className="text-center mb-4">Terms and Conditions</h1>
                <div className="card p-4">
                    <p>
                        These terms and conditions govern the use of Snack House UK website/app. Please carefully read these terms and conditions before proceeding to register with us or place an online order.
                    </p>
                    <h5 className="mt-4">Eligibility</h5>
                    <p>
                        You must be at least 18 years old to use our website/app. Please be aware that in accordance with our terms and conditions children under the age of 18 are not permitted to access our website/app. If you do not meet this qualification kindly refrain from using this website/app.
                    </p>
                    <h5 className="mt-4">About Us</h5>
                    <p>
                        Our company is operated by Right Price Wholesale Ltd. We are a registered company in England and Wales with company number 3871964. Our registered office is located at Unit C Swallowfield Way Hayes UB3 1DQ.
                    </p>
                    <h5 className="mt-4">Website/app Access</h5>
                    <p>
                        The Snack House UK website/app may not be used for any fraudulent purposes. Individuals engaging in fraudulent activities will face legal proceedings. The images on this website/app are for illustrative and informational purposes only and the actual product may vary from the representations provided.
                    </p>
                    <p>
                        Copying or downloading information from the website/app for personal use or for the benefit of third parties including other merchants is strictly prohibited. The website/app must not be used in a manner that disrupts its operation or violates any laws. Sending illegal materials offensive content abusive language obscenities chain letters mass mailings or software viruses is illegal and prohibited.
                    </p>
                    <p>
                        The Snack House UK website/app or any portion thereof may not be duplicated copied sold resold visited or otherwise exploited for commercial purposes without our express written consent. The use of Snack House UK logo or other proprietary graphics or trademarks as part of a link is prohibited without our express written consent.
                    </p>
                    <h5 className="mt-4">Purchase Contract</h5>
                    <p>
                        By placing an order through our website/app or telesales you are making an offer to enter into a contract with Right Price Wholesale Ltd based on these Terms and Conditions. This contract becomes effective when Right Price Wholesale Ltd acknowledges the acceptance of your order or delivers the products to you whichever occurs earlier. The contract is subject to stock availability. We reserve the right to amend published prices and offers at any time before the contract is formed as specified in this condition. Any current price list supersedes all previous price lists. Prices quoted are inclusive of VAT where applicable, and this is the maximum price you will be charged.
                    </p>
                    <p>
                        For the goods supplied, you will receive your invoice on the next delivery. If you require it before the specified delivery date, you are able to collect it from our premises at Right Price Wholesale Ltd. Unit C Swallowfield Way Hayes UB3 1DQ.
                    </p>
                    <p>
                        Right Price Wholesale Ltd reserves the right at its sole discretion to amend these Terms and Conditions at any time. These conditions will apply to all orders placed at the time of amendment. We will notify you by email/app to the address you have provided as soon as possible to confirm the receipt of your order and email/notify via app again to confirm the details. Our acceptance of your order occurs upon dispatch of the product(s) ordered at which point the purchase contract is established and we will supply the goods to you in accordance with these Terms and Conditions.Prices on the website/app may differ from prices in our warehouse. If you have any queries your customer ID will be easily accessible when speaking to our telesales team. In some cases stock may be limited or not available and we ask that you either wait until it becomes available or contact us.
                    </p>
                    <h5>Payment & Order Cancellation</h5>
                    <p>Payments for orders placed are due within 5 days of receiving your email invoice; otherwise a late fee may apply. You will receive the email/app invoice within 12 hours after placing the order. We reserve the right to cancel any transaction. Partial payments are not accepted. We accept credit debit and master cards as well as checks for payment. In the event of a failed payment an administration charge of not less than £20 will be applied. On occasion we may request documents for verification which should be sent to Unit C Swallowfield Way Hayes UB3 1DQ along with your customer ID.
                        If under any circumstances an order needs to be cancelled or additional products need to be added to an existing order this must be done within 12 hours of placing the order. Order cancellations made after 12 hours will incur a cancellation fee of more than £20.
                    </p>
                    <h5>Delivery & Returns</h5>
                    <p>Orders will be processed within 24 hours and dispatched upon receiving full payment confirmation. A confirmation email/app notification with dispatch details will be sent to you. Changes to delivery details will be accepted if notified prior to the delivery date. Once goods are dispatched for delivery no changes can be made to the delivery date or order cancellation. If such changes are attempted a fee of £50 will be charged.
                        Returns are accepted within 5 days from the date of order dispatch provided the returned product is unused in its original unopened packaging (with any seal or shrink-wrap intact) with labels still attached or otherwise in a condition enabling us to resell the product as new. Failure to comply with these conditions will result in the goods not being accepted. If the conditions are met we will send a replacement delivery for damaged products or credit for the damaged items. This policy does not affect your statutory rights as a consumer.
                    </p>
                    <h5>Privacy</h5>
                    <p>This privacy policy outlines how Right Price uses and protects any information you provide when using this website/app. We are committed to ensuring your privacy is protected. When asked to provide certain information that can identify you while using this website/app rest assured that it will only be used in accordance with this privacy statement. We may periodically update this policy by making changes to this page so we recommend checking it from time to time to ensure your satisfaction.</p>
                    <h5>What We Collect</h5>
                    <p>We may collect the following information: Full name and job title Contact information including email address and mobile number(s) Demographic information such as postcode and full trading address Other information relevant to customer surveys and/or offers</p>
                    <h5>How We Use Your Information</h5>
                    <p>We collect this information to understand your needs and provide you with better service including: Internal record-keeping Improving our products and services Periodically sending promotional emails about new products special offers or other information we believe may interest you Contacting you for market research purposes by email phone fax or mail Customizing the website/app according to your interests</p>
                    <h5>Security</h5>
                    <p>We are committed to ensuring your information's security. To prevent unauthorized access or disclosure we have implemented suitable physical electronic and managerial procedures to safeguard and secure the information we collect online.</p>
                    <h5>Cookies</h5>
                    <p>We use cookies small files that request permission to be placed on your computer's hard drive and/or your mobile phone. By agreeing to this the file is added and the cookie helps analyse web traffic or informs you when you visit a specific site. Cookies allow web applications to respond to you as an individual customizing their operations based on your preferences. We use traffic log cookies to identify pages used for statistical analysis purposes. This information is then removed from the system. Overall cookies help us provide a better website/app by monitoring which pages you find useful and which you do not. A cookie does not provide access to your computer or any personal information unless you choose to share it.

                    </p>
                    <p>
                        You can choose to accept or decline cookies. Most web browsers automatically accept cookies but you can usually modify your browser settings to decline them if you prefer. However this may prevent you from fully utilizing the website/app.
                    </p>
                    <h5>Controlling Your Personal Information</h5>
                    <p>You may restrict the collection or use of your personal information by: Indicating your preference not to have your information used for direct marketing purposes when filling in a form on the website/app.</p>
                    <p>Changing your mind about allowing your personal information to be used for direct marketing purposes if you have previously agreed to it. You can do so by writing to or emailing us at sales@snackhouseuk.com.</p>
                    <p>We will not sell distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. If you provide permission we may use your personal information to send you promotional information about third parties we believe may interest you.</p>
                    <p>You can request details of the personal information we hold about you under the Data Protection Act 1998. A small fee may apply. If you would like a copy of your information please write to Right Price at Unit C Swallowfield Way Hayes UB3 1DQ. If you believe any information we hold about you is incorrect or incomplete please notify us as soon as possible at the above address and we will promptly correct any inaccuracies.</p>
                    <h5>Complaints or Suggestions</h5>
                    <p>If you are dissatisfied with our service or wish to provide suggestions regarding our website/app please contact us at Right Price at Unit C Swallowfield Way Hayes UB3 1DQ. We value your feedback and aim to continually improve our services.</p>
                    <p>Please note all references to ‘Right Price’ are short for ‘Right Price Wholesale Ltd’</p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Termsconditions;
