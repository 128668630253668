import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import useApi from '../service/useApi';
import { fetchDataFromAPI } from '../service/api';
import { getUser } from '../service/constants';

function AdminViewItem({ show, onHide, itemId }) {
    const [loading, setLoading] = useState(true);  // Start with loading as true
    const [viewAllItem, setViewAllItem] = useState(null);
    const { apiAction } = useApi();
    const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
    const storedPricebands = JSON.parse(localStorage.getItem('allPriceband'));
    const user = JSON.parse(getUser());
    useEffect(() => {
        handleViewItem();
    }, [itemId]);  // Trigger effect when itemId changes


    const handleViewItem = async () => {
        setLoading(true);
        try {
            const response = await apiAction({
                url: `/admin/item/view/${itemId}`,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });
            console.log(response, "-------response");
            if (response.status) {
                setViewAllItem(response.data);
                toast.success(response?.message);
            } else {
                console.log(response?.message, "----response?.message");
                // toast.error(response?.message);
            }
        } catch (error) {
            console.error("Error fetching item:", error);
            toast.error("Failed to fetch item.");
        } finally {
            setLoading(false);
        }
    };

    const selectedPriceband = storedPricebands?.find(priceband => priceband.id === viewAllItem?.priceband_id);

    return (
        <Modal show={show} onHide={onHide} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">View Items</h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                                <Spinner animation="border" />
                            </div>
                        ) : viewAllItem ? (
                            <form className="needs-validation" noValidate>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <p className='form-control'>{viewAllItem?.name}</p>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="mb-3">
                                        <label htmlFor="stock" className="form-label">Stock</label>
                                        <p className='form-control'>{viewAllItem?.stock}</p>
                                    </div>
                                    <div className="mb-3" style={{ marginLeft: 10 }}>
                                        <label htmlFor="price" className="form-label">Price</label>
                                        <p className='form-control'>{viewAllItem?.price}</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="mb-3">
                                        <label htmlFor="priceband_id" className="form-label">Priceband ID</label>
                                        <select
                                            className="form-control"
                                            id="priceband_id"
                                            required
                                        >
                                            <option value={viewAllItem?.priceband_id}>
                                                {selectedPriceband ? selectedPriceband.type : "Select Priceband"}
                                            </option>
                                        </select>
                                        <div className="invalid-feedback">Please select priceband ID.</div>
                                    </div>
                                    <div className="mb-3" style={{ marginLeft: 10 }}>
                                        <label htmlFor="item_code" className="form-label">Item Code</label>
                                        <p className='form-control'>{viewAllItem?.item_code}</p>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <p className='form-control'>{viewAllItem?.description}</p>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="mb-3">
                                        <label htmlFor="discount" className="form-label">Discount</label>
                                        <p className='form-control'>{viewAllItem?.discount}</p>
                                    </div>
                                    <div className="mb-3" style={{ marginLeft: 10 }}>
                                        <label htmlFor="discount_with" className="form-label">Discount With</label>
                                        <p className='form-control'>
                                            {viewAllItem?.discount_with == 0 ? 'Percentage' : viewAllItem?.discount_with == 1 ? 'Value Off' : ''}
                                        </p>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                                <p>No data available</p>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AdminViewItem;
