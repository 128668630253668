import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { PricebandContext } from "../context/PricebandContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchDataFromAPI } from "../service/api";
import { getUser } from "../service/constants";

function AdminItemPricebandModal(props) {
  const [Item_ID, setItem_ID] = useState("");
  const [price, setPrice] = useState("");
  const [specialPrice, setSpecialPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [ItemData, setitemData] = useState([]);
  const [priceBand, setPriceBand] = useState([]);
  const [selectedPricebandID, setSelectedPricebandID] = useState("");
  const adminLogin = JSON.parse(localStorage.getItem("adminLogin"));
  const { pricebandData } = useContext(PricebandContext);
  const { pricebandID } = useContext(PricebandContext);
  const [fromdate, setFromdate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const user = JSON.parse(getUser());
useEffect(()=>{
  fetchPricebandList()
  fetchCategoriesList()
},[])

  const handleUploadData = async (e) => {
    e.preventDefault();

    if (!Item_ID || !price || !specialPrice || !selectedPricebandID) {
      toast.error("All fields are required");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("item_id", Item_ID);
    formData.append("priceband_id", selectedPricebandID);
    formData.append("price", price);
    formData.append("from_date", fromdate.toISOString().split("T")[0]);
    formData.append("to_date", toDate.toISOString().split("T")[0]);
    formData.append("special_price", specialPrice);

    try {
      const response = await fetch(
        "https://laravel.snackhouseuk.com/api/admin/itempriceband/create",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
          body: formData,
        }
      );

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message || "Data saved successfully");
        props.onHide();
        props.onSuccess();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("error", error);
      // toast.error("An error occurred while uploading data");
    } finally {
      setLoading(false);
    }
  };


  const fetchCategoriesList = () => {
    fetchDataFromAPI(
        `/admin/item/dropdwon/list`,
        "get",
        "",
        user?.token
    )
        .then((response) => {
            // setLoading(true);
            if (response.status == true) {
                console.log("response--->>", response);
                setitemData(response?.data)
            } else {
                // toast.error(response?.message);
            }
        })
        .catch((error) => {
            // setLoading(false)
            console.log(error, "---error");
        });
};

const fetchPricebandList = (page) => {
  fetchDataFromAPI(
    `/admin/item/priceband/list`,
    "get",
    "",
    user?.token
  )
    .then((response) => {
      if (response.status === true) {
       setPriceBand(response?.data)
      } else {
        // toast.error(response.message);
      }
    })
    .catch((error) => {
      console.error("Error fetching priceband list:", error);
    });
};
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content p-1">
          <div className="modal-header border-0">
            <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">
              Add Item Priceband
            </h5>
            <Modal.Header closeButton />
          </div>
          <div className="modal-body">
            <form
              className="needs-validation"
              noValidate
              onSubmit={handleUploadData}
            >
              <div className="mb-3">
                <label htmlFor="item_id" className="form-label">
                  Item
                </label>
                <select
                  value={Item_ID}
                  className="form-control"
                  id="item_id"
                  onChange={(e) => setItem_ID(e.target.value)}
                  required
                >
                  <option value="">Select Item</option>
                  {ItemData?.map((priceband) => (
                    <option key={priceband.id} value={priceband.id}>
                      {priceband.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="priceband_id" className="form-label">
                  Priceband
                </label>
                <select
                  value={selectedPricebandID}
                  className="form-control"
                  id="priceband_id"
                  onChange={(e) => setSelectedPricebandID(e.target.value)}
                  required
                >
                  <option value="">All Pricebands</option>
                  {priceBand?.map((priceband) => (
                    <option key={priceband.id} value={priceband.id}>
                      {priceband.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="price" className="form-label">
                  Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  placeholder="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
                <div className="invalid-feedback">Please enter price.</div>
              </div>
              <div className="mb-3">
                <label htmlFor="specialPrice" className="form-label">
                  Special Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="specialPrice"
                  placeholder="Special Price"
                  value={specialPrice}
                  onChange={(e) => setSpecialPrice(e.target.value)}
                  required
                />
                <div className="invalid-feedback">
                  Please enter special price.
                </div>
              </div>
              <div className="d-flex gap-3">
                <div className="mb-3">
                  <label htmlFor="fromDate" className="form-label me-2">
                    From Date
                  </label>
                  <DatePicker
                    selected={fromdate}
                    className="form-control"
                    onChange={(date) => setFromdate(date)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="toDate" className="form-label me-2">
                    To Date
                  </label>
                  <DatePicker
                    selected={toDate}
                    className="form-control"
                    onChange={(date) => setToDate(date)}
                  />
                </div>
              </div>

              <div className="d-flex mb-3">
                <Button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={props.onHide}
                  disabled={loading}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary w-100"
                  style={{ marginLeft: 7 }}
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AdminItemPricebandModal;
