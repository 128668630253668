import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar';
import Header from '../Header/Header';
import { Link, useLocation } from 'react-router-dom';
import { BsStarFill, BsEye, BsHeart } from "react-icons/bs";
import { BsStar } from "react-icons/bs";
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import Sider from '../../commponets/Sider';
import { getUser } from '../../service/constants';

function ItemsList() {
  const location = useLocation();
  const { prevTitle } = location.state || {};
  const { apiAction } = useApi();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState({ key: 'name', order: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  const user = JSON.parse(getUser());
  useEffect(() => {
    fetchItemList();
  }, [currentPage, sorting, searchQuery]);

  const fetchItemList = async () => {

    setIsLoading(true);
    try {
      const response = await apiAction({
        url: `/user/item/list?page=${currentPage}&sorting=${sorting.order}&sortingKey=${sorting.key}&itemSearch=${searchQuery}`,
        method: "GET",
        headers: {
          'Authorization': `Bearer ${user?.token}`
        }
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setAllPricebandList(pricebandData);
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    const [key, order] = value.split(':');
    setSorting({ key, order });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const ProductCard = ({ product }) => (
    <div className="col">
      <div className="card card-product">
        <div className="card-body">
          <div className="text-center position-relative">
            <a href="index.html#!">
              <img
                src={product.itemImage[0] || 'placeholder-image.jpg'}
                alt="Product"
                className="mb-3 img-fluid"
              />
            </a>
            <div className="card-product-action">
              <a
                href="index.html#!"
                className="btn-action"
                data-bs-toggle="tooltip"
                data-bs-html="true"
                title="Quick View"
              >
                <BsEye />
              </a>
              <a
                href="index.html#!"
                className="btn-action"
                data-bs-toggle="tooltip"
                data-bs-html="true"
                title="Wishlist"
              >
                <BsHeart />
              </a>
            </div>
          </div>
          <div className="text-small mb-1">
            <a href="index.html#!" className="text-decoration-none text-muted">
              <small>{product.name}</small>
            </a>
          </div>
          <h2 className="fs-6">
            <a href="pages/shop-single.html" className="text-inherit text-decoration-none">
              {product.item_code}
            </a>
          </h2>
          <div>
            <small className="text-warning">
              {product.rating && [...Array(Math.floor(product.rating))].map((_, index) => (
                <i key={index} className="bi bi-star-fill"></i>
              ))}
              {product.rating && product.rating % 1 !== 0 && (
                <i className="bi bi-star-half"></i>
              )}
            </small>
            <span className="text-muted small">{product.numReviews}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
              <span className="text-dark">{product.currency} {product.price}</span>
            </div>
            <div>
              <a href="index.html#!" className="btn btn-primary btn-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-plus"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                Add
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Header />
      <Navbar />
      <div className="mt-8 mb-lg-14 mb-8">
        <div className="container">
          <div className="row gx-10">
            <Sider />
            <section className="col-lg-9 col-md-12">
              <div className="card mb-4 bg-light border-0">
                <div className="card-body p-9">
                  <h2 className="mb-0 fs-1">Items</h2>
                </div>
              </div>
              <div className="d-lg-flex justify-content-between align-items-center">
                <div className="mb-3 mb-lg-0">
                  <p className="mb-0">
                    <span className="text-dark">{allPricebandList.length}</span>
                    Products found
                  </p>
                </div>
                <div className="d-md-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <a href="shop-list.html" className="text-muted me-3"><i className="bi bi-list-ul"></i></a>
                      <a href="shop-grid.html" className="me-3 active"><i className="bi bi-grid"></i></a>
                      <a href="shop-grid-3-column.html" className="me-3 text-muted"><i className="bi bi-grid-3x3-gap"></i></a>
                    </div>
                    <div className="ms-2 d-lg-none">
                      <a className="btn btn-outline-gray-400 text-muted" data-bs-toggle="offcanvas" href="shop-grid.html#offcanvasCategory" role="button" aria-controls="offcanvasCategory">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-filter me-2">
                          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                        </svg>
                        Filters
                      </a>
                    </div>
                  </div>

                  <div className="d-flex mt-2 mt-lg-0">
                    <div className="me-2 flex-grow-1">
                      <select className="form-select" onChange={handleSortChange}>
                        <option value="name:asc">Sort by: Name (A-Z)</option>
                        <option value="name:desc">Sort by: Name (Z-A)</option>
                        <option value="price:asc">Sort by: Price (Low to High)</option>
                        <option value="price:desc">Sort by: Price (High to Low)</option>
                      </select>
                    </div>
                    <div>
                      <input type="text" className="form-control" placeholder="Search..." onChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- row --> */}
              {isLoading ? (
                <div className="text-center mt-5">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
              
              <div className="row g-4 row-cols-xl-4 row-cols-lg-3 row-cols-2 row-cols-md-2 mt-2">
                {allPricebandList?.map((product) => (
                
                  <Link to={`/productList/${product?.id}`} className="text-decoration-none text-inherit">
                    <ProductCard key={product.id} product={product} />
                  </Link>
                ))}
              </div>
              
              )}
              <div className="row mt-8">
                <div className="col">
                  <nav>
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a className="page-link mx-1" href="#" onClick={() => setCurrentPage(currentPage - 1)} aria-label="Previous">
                          <i className="feather-icon icon-chevron-left"></i>
                        </a>
                      </li>
                      {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                        <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                          <a className="page-link mx-1" href="#" onClick={() => setCurrentPage(page)}>{page}</a>
                        </li>
                      ))}
                      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <a className="page-link mx-1" href="#" onClick={() => setCurrentPage(currentPage + 1)} aria-label="Next">
                          <i className="feather-icon icon-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default ItemsList