import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import useApi from '../service/useApi';
import { fetchDataFromAPI } from '../service/api';
import { PricebandContext } from '../context/PricebandContext';
import { getUser } from '../service/constants';

function AdminPopularEditModal({ show, onHide, itemId, onSuccess, itemData }) {
    const [Priority, setPriority] = useState('');
    const [item_id, setItem_id] = useState('');
    const [ItemData, setitemData] = useState([]);
    const [loading, setLoading] = useState(false);
    const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
    const { pricebandData } = useContext(PricebandContext);
    const user = JSON.parse(getUser());
    useEffect(() => {
        if (itemData) {
            setPriority(itemData.priority || '');
            setItem_id(itemData.item_id || '');

        }
    }, [itemData]);
    useEffect(()=>{
        fetchPricebandList()
    },[])

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const requestBody = {
            priority: Priority,
            item_id: item_id,
            id: itemData?.id
        };
        fetchDataFromAPI("/admin/popular/create", "post", requestBody, user?.token)
            .then((response) => {
                if (response.status) {
                    toast.success('Item updated successfully!');
                    onHide();
                    if (onSuccess) {
                        onSuccess(response);
                    }
                } else {
                    if (response.data && typeof response.data === 'object') {
                        Object.keys(response.data).forEach((key) => {
                            response.data[key].forEach((message) => {
                                toast.error(`${key}: ${message}`);
                            });
                        });
                    } else {
                        toast.error(response.message || 'Failed to update item.');
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
                toast.error("An error occurred while uploading data");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    
    const fetchPricebandList = () => {
        fetchDataFromAPI(
            `/admin/item/dropdwon/list`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                // setLoading(true);
                if (response.status == true) {
                    console.log("response--->>", response);
                    setitemData(response?.data)
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                // setLoading(false)
                console.log(error, "---error");
            });
    };



    return (
        <Modal show={show} onHide={onHide} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Edit Items</h5>
                        <Button variant="close" onClick={onHide} />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleSubmit}>

                        <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">Item</label>
                                <select
                                    value={item_id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setItem_id(e?.target?.value)}
                                    required
                                >
                                    <option value="">Item</option>
                                    {ItemData?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{priceband?.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="Priority" className="form-label">Priority</label>
                                <input
                                    className="form-control"
                                    id="Priority"
                                    placeholder="Priority"
                                    value={Priority}
                                    onChange={(e) => setPriority(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter item Priority.</div>
                            </div>

                          

                            <div className="d-flex mb-3">
                                <Button variant="secondary" onClick={onHide} >
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit" disabled={loading} style={{ marginLeft: 10 }}>
                                    {loading ? 'Save...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminPopularEditModal;
