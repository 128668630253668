import React, { useEffect, useState } from 'react'
import AdminProfile from '../AdminProfile/AdminProfile'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import { Link, useLocation } from 'react-router-dom';
import { getUser } from '../../service/constants';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../../service/api';

function AdminCustomerAllocationView() {
    const location = useLocation();
    const { item } = location.state || {};
    const [salesData, setSalesData] = useState([]);
    const user = JSON.parse(getUser());
    const [vewItems, setViewItems] = useState()

    useEffect(() => {
        ViewItem()
        fetchSalesList()
    }, [])
    const ViewItem = async (e) => {

        const formData = new FormData();
        formData.append("id", item);
        try {
            const response = await fetch(
                "https://laravel.snackhouseuk.com/api/admin/commission/view",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: formData,
                }
            );

            const result = await response.json();
            if (response.ok) {
                setViewItems(result.data);

            } else {
                toast.error(result.message || "An error occurred");
            }
        } catch (error) {
            console.error("error", error);
            toast.error("An error occurred while uploading data");
        } finally {
            // setLoading(false);
        }
    };
    const fetchSalesList = () => {
        fetchDataFromAPI(
            `/admin/listing`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                if (response.status === true) {
                    setSalesData(response?.data);
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                console.log(error, "---error");
            });
    };
    return (
        <>
            <AdminProfile />
            <div className="main-wrapper">
                <AdminNavbar />
                <main className="main-content-wrapper">
                    <div className="container">
                        <div className="row mb-8">
                            <div className="col-md-12">
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                                    <div>
                                        <h2>{vewItems?.item_name}</h2>
                                    </div>

                                    <div>
                                        <Link to={'/commission-Allocation'}>
                                            <a href="order-single.html#" className="btn btn-primary">
                                                Back to all orders
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="card h-100 card-lg">
                                <div className="modal-body">
                                    <form
                                        className="needs-validation"
                                        noValidate
                                    >
                                        <div className="mb-3 d-flex justify-content-between">
                                            <div>
                                                <label htmlFor="item_id" className="form-label">
                                                    Item code
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="item_id"
                                                    value={vewItems?.item?.item_code}
                                                    disabled
                                                >
                                                </input>

                                            </div>
                                            <div>
                                                <label htmlFor="item_id" className="form-label">
                                                    Item stock
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="item_id"
                                                    value={vewItems?.item?.stock}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                            <div>
                                                <label htmlFor="item_id" className="form-label">
                                                    Item price
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="item_id"
                                                    value={vewItems?.item?.price}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        {/* Sales List 1 */}
                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3">
                                                <label htmlFor="sales1_id" className="form-label">
                                                    sales1
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="sales1_id"
                                                    value={vewItems?.sales1?.sales_code ? vewItems?.sales1?.sales_code : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount" className="form-label">sales1 name</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount"
                                                    placeholder={vewItems?.sales1?.name ? vewItems?.sales1?.name : '-'}
                                                    value={vewItems?.sales1?.name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount_with" className="form-label">sales1 email</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with"
                                                    value={vewItems?.sales1.email ? vewItems?.sales1.email : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%' }}>
                                                <label htmlFor="sales2_id" className="form-label">
                                                    Sales1 details
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="sales2_id"
                                                    value={vewItems?.sales1_id ? vewItems?.sales1_id : ''}
                                                    required
                                                    disabled
                                                >
                                                    <option value=""></option>
                                                    {salesData?.map((priceband) => (
                                                        <option key={priceband.id} value={priceband.id}>
                                                            {`${priceband.sales_code} - ${priceband.email}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount2" className="form-label">Sales1 Commission</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount2"
                                                    placeholder={
                                                        vewItems?.sales1_commission_type === "0"
                                                            ? "%"
                                                            : vewItems?.sales1_commission_type === "1"
                                                                ? ""
                                                                : "-"
                                                    }
                                                    value={
                                                        vewItems?.sales1_commission_type === "0"
                                                            ? "%"
                                                            : vewItems?.sales1_commission_type === "1"
                                                                ? ""
                                                                : "-"
                                                    }
                                                    required
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3 flex-grow-1" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount_with2" className="form-label">Sales1 Commission type</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with2"
                                                    value={vewItems?.sales1_commission ? vewItems?.sales1_commission : '-'}
                                                    disabled
                                                    required
                                                />
                                            </div>
                                        </div>

                                        {/* sales list 2 */}
                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3">
                                                <label htmlFor="sales1_id" className="form-label">
                                                    sales2
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="sales1_id"
                                                    value={vewItems?.sales2?.sales_code ? vewItems?.sales2?.sales_code : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount" className="form-label">sales2 name</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount"
                                                    placeholder={vewItems?.sales2?.name ? vewItems?.sales2?.name : "-"}
                                                    value={vewItems?.sales2?.name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount_with" className="form-label">sales2 email</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with"
                                                    value={vewItems?.sales2?.email ? vewItems?.sales2?.email : "-"}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        {/* Sales List 2 */}
                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%' }}>
                                                <label htmlFor="sales2_id" className="form-label">
                                                    Sales2 details
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="sales2_id"
                                                    value={vewItems?.sales2_id ? vewItems?.sales2_id : '-'}
                                                    required
                                                    disabled
                                                >
                                                    <option value=""></option>
                                                    {salesData?.map((priceband) => (
                                                        <option key={priceband.id} value={priceband.id}>
                                                            {`${priceband.sales_code} - ${priceband.email}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount2" className="form-label">Sales2 Commission</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount2"
                                                    placeholder={
                                                        vewItems?.sales2_commission_type === "0"
                                                            ? "%"
                                                            : vewItems?.sales2_commission_type === "1"
                                                                ? ""
                                                                : "-"
                                                    }
                                                    value={
                                                        vewItems?.sales2_commission_type === "0"
                                                            ? "%"
                                                            : vewItems?.sales2_commission_type === "1"
                                                                ? ""
                                                                : "-"
                                                    }
                                                    required
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount_with2" className="form-label">Sales2 Commission type</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with2"
                                                    value={vewItems?.sales2_commission ? vewItems?.sales2_commission : '-'}
                                                    disabled
                                                    required
                                                >
                                                </input>
                                            </div>
                                        </div>


                                        {/* sales list 3 */}
                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3">
                                                <label htmlFor="sales1_id" className="form-label">
                                                    sales3
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="sales1_id"
                                                    value={vewItems?.sales3?.sales_code ? vewItems?.sales3?.sales_code : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount" className="form-label">sales3 name</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount"
                                                    placeholder={vewItems?.sales3?.name ? vewItems?.sales3?.name : "-"}
                                                    value={vewItems?.sales3?.name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount_with" className="form-label">sales3 email</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with"
                                                    value={vewItems?.sales3?.email ? vewItems?.sales3?.email : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', }}>
                                                <label htmlFor="sales2_id" className="form-label">
                                                    Sales3 details
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="sales2_id"
                                                    value={vewItems?.sales3_id ? vewItems?.sales3_id : ''}
                                                    required
                                                    disabled
                                                >
                                                    <option value=""></option>
                                                    {salesData?.map((priceband) => (
                                                        <option key={priceband.id} value={priceband.id}>
                                                            {`${priceband.sales_code} - ${priceband.email}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount2" className="form-label">Sales3 Commission</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount2"
                                                    placeholder={vewItems?.sales3_commission_type === "0" ? "%" : ""}
                                                    value={vewItems?.sales3_commission_type === "0" ? "%" : ""}
                                                    required
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount_with2" className="form-label">Sales3 Commission type</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with2"
                                                    value={vewItems?.sales3_commission}
                                                    disabled
                                                    required
                                                >
                                                </input>

                                            </div>
                                        </div>

                                        {/* Sales List 4 */}
                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3">
                                                <label htmlFor="sales1_id" className="form-label">
                                                    sales4
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="sales1_id"
                                                    value={vewItems?.sales4?.sales_code ? vewItems?.sales4?.sales_code : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount" className="form-label">sales4 name</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount"
                                                    placeholder={vewItems?.sales4?.name ? vewItems?.sales4?.name : '-'}
                                                    value={vewItems?.sales4?.name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount_with" className="form-label">sales4 email</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with"
                                                    value={vewItems?.sales4?.email ? vewItems?.sales4?.email : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                        </div>



                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', }}>
                                                <label htmlFor="sales2_id" className="form-label">
                                                    Sales4 details
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="sales2_id"
                                                    value={vewItems?.sales4_id ? vewItems?.sales4_id : ''}
                                                    required
                                                    disabled
                                                >
                                                    <option value=""></option>
                                                    {salesData?.map((priceband) => (
                                                        <option key={priceband.id} value={priceband.id}>
                                                            {`${priceband.sales_code} - ${priceband.email}` ? `${priceband.sales_code} - ${priceband.email}` : '-'}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount2" className="form-label">Sales4 Commission</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount2"
                                                    // placeholder={vewItems?.sales4_commission_type === "0" ? "%" : ""}
                                                    placeholder={
                                                        vewItems?.sales4_commission_type === "0"
                                                            ? "%"
                                                            : vewItems?.sales4_commission_type === "1"
                                                                ? ""
                                                                : "-"
                                                    }
                                                    value={
                                                        vewItems?.sales4_commission_type === "0"
                                                            ? "%"
                                                            : vewItems?.sales4_commission_type === "1"
                                                                ? ""
                                                                : "-"
                                                    }
                                                    required
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount_with2" className="form-label">Sales4 Commission type</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with2"
                                                    value={vewItems?.sales4_commission ? vewItems?.sales4_commission : '-'}
                                                    disabled
                                                    required
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3">
                                                <label htmlFor="sales1_id" className="form-label">
                                                    sales5
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="sales1_id"
                                                    value={vewItems?.sales5?.sales_code ? vewItems?.sales5?.sales_code : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount" className="form-label">sales5 name</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount"
                                                    placeholder={vewItems?.sales5?.name ? vewItems?.sales5?.name : '-'}
                                                    value={vewItems?.sales5?.name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="discount_with" className="form-label">sales5 email</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with"
                                                    value={vewItems?.sales5?.email ? vewItems?.sales5?.email : '-'}
                                                    disabled
                                                >
                                                </input>
                                            </div>
                                        </div>



                                        <div className="d-flex mb-3 justify-content-between">
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', }}>
                                                <label htmlFor="sales2_id" className="form-label">
                                                    Sales5 details
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="sales2_id"
                                                    value={vewItems?.sales5_id ? vewItems?.sales5_id : ''}
                                                    required
                                                    disabled
                                                >
                                                    <option value=""></option>
                                                    {salesData?.map((priceband) => (
                                                        <option key={priceband.id} value={priceband.id}>
                                                            {`${priceband.sales_code} - ${priceband.email}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount2" className="form-label">Sales5 Commission</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="discount2"
                                                    placeholder={
                                                        vewItems?.sales5_commission_type === "0"
                                                            ? "%"
                                                            : vewItems?.sales5_commission_type === "1"
                                                                ? ""
                                                                : "-"
                                                    }
                                                    value={
                                                        vewItems?.sales5_commission_type === "0"
                                                            ? "%"
                                                            : vewItems?.sales5_commission_type === "1"
                                                                ? ""
                                                                : "-"
                                                    }
                                                    required
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3 flex-grow-1 mr-2" style={{ flexBasis: '30%', marginLeft: 4 }}>
                                                <label htmlFor="discount_with2" className="form-label">Sales5 Commission type</label>
                                                <input
                                                    className="form-control"
                                                    id="discount_with2"
                                                    value={vewItems?.sales5_commission ? vewItems?.sales5_commission : '-'}
                                                    disabled
                                                    required
                                                >
                                                </input>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default AdminCustomerAllocationView