import React, { useState, useEffect } from "react";
import { BsMenuUp } from "react-icons/bs";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import { getUser } from "../../service/constants";
import { fetchDataFromAPI } from "../../service/api";
import { toast } from "react-toastify";
import { BsEye } from "react-icons/bs";
import SideOrdeList from "../../commponets/SideOrdeList";
import Footer from "../Footer/Footer";
import useApi from "../../service/useApi";
import Spinner from "react-bootstrap/Spinner";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

function Settings() {
  const { apiAction } = useApi();
  const user = JSON.parse(getUser());
  const [name, setName] = useState(user?.name || "");
  const [phone, setPhone] = useState(user?.phone || "");
  const [phone2, setPhone2] = useState(user?.phone2 || "");
  const [address, setAddress] = useState(user?.address || "");
  const [pincode, setPincode] = useState(user?.pincode || "");
  const [country, setCountry] = useState(user?.country || "");
  const [region, setRegion] = useState(user?.state || "");
  const [city, setCity] = useState(user?.city || "");
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    document.querySelector("body").scrollTo(0,0)
  },[])
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setName(storedUser.name || "");
      setPhone(storedUser.phone || "");
      setPhone2(storedUser.phone2 || "");
      setAddress(storedUser.address || "");
      setPincode(storedUser.pincode || "");
      setCountry(storedUser.country || "");
      setRegion(storedUser.state || "");
      setCity(storedUser.city || "");
    }
  }, []);

  const selectCountry = (val) => {
    setCountry(val);
    setRegion("");
  };

  const selectRegion = (val) => {
    setRegion(val);
  };

  const handleUpdateProfile = (event) => {
    event.preventDefault();
    setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${user?.token}`);

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("phone", phone);
    formdata.append("address", address);
    formdata.append("pincode", pincode);
    formdata.append("phone2", phone2);
    formdata.append("country", country);
    formdata.append("state", region);
    formdata.append("city", city);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://laravel.snackhouseuk.com/api/user/customer/profile/update",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result.status) {
          toast.success("Profile updated successfully");

          // Update local storage or application state with new profile data
          const updatedUser = {
            ...user,
            name,
            phone,
            address,
            pincode,
            phone2,
            country,
            city,
            state: region,
          };
          localStorage.setItem("user", JSON.stringify(updatedUser)); // Assuming you use local storage
          console.log(updatedUser, "---updatedUser");
          // Update profile state
          setName(name);
          setPhone(phone);
          setAddress(address);
          setPincode(pincode);
          setPhone2(phone2);
          setCountry(country);
          setCity(city);
          setRegion(region);
        } else {
          toast.error(result.message || "Failed to update profile");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error updating profile");
        console.error(error);
      });
  };

  return (
    <>
      <Header />
      <Navbar />
      <main>
        <section>
          <div className="container">
            <div className="row">
              <SideOrdeList />
              <div className="col-lg-9 col-md-8 col-12">
                <div className="py-6 p-md-6 p-lg-10">
                  <div className="mb-6">
                  <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{"Account setting"}</li>
                      </ol>
                    </nav>
                    <h2 className="mb-0">Account setting</h2>
                  </div>
                  <div>
                    <h5 className="mb-4">Account details</h5>
                    <div className="row">
                      <div className="col-lg-12">
                        <form onSubmit={handleUpdateProfile}>
                          <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              value={user?.email}
                              disabled
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Mobile</label>
                            <input
                              type="number"
                              className="form-control"
                              value={phone2}
                              onChange={(e) => setPhone2(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Address</label>
                            <textarea
                              cols={10}
                              className="form-control"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Postcode</label>
                            <input
                              type="text"
                              className="form-control"
                              value={pincode}
                              onChange={(e) => setPincode(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Country</label>
                            <CountryDropdown
                              value={country}
                              onChange={selectCountry}
                              style={{
                                width: "100%",
                                padding: "8px",
                                marginBottom: "20px",
                              }}
                            />
                          </div>
                          {country && (
                            <div className="mb-3">
                              <label className="form-label">State</label>
                              <RegionDropdown
                                country={country}
                                value={region}
                                onChange={selectRegion}
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                  marginBottom: "20px",
                                }}
                              />
                            </div>
                          )}
                          <div className="mb-3">
                            <label className="form-label">City</label>
                            <input
                              type="text"
                              className="form-control"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                "Save Details"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <hr className="my-10" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Settings;
