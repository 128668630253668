import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { getUser } from '../service/constants';

function AdminCategoryEditModal({ show, onHide, itemId, onSuccess, itemData }) {
    const [name, setName] = useState('');
    const [priority, setPriority] = useState('1');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
    const user = JSON.parse(getUser());
    useEffect(() => {
        if (itemData) {
            setName(itemData.name || '');
            setPriority(itemData.priority || '1');
        }
    }, [itemData]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${user?.token}`);

        const formdata = new FormData();
        formdata.append("name", name);
        if (file) {
            formdata.append("image", file);
        }
        formdata.append("priority", priority);
        formdata.append("id", itemId?.id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch("https://laravel.snackhouseuk.com/api/admin/category/create", requestOptions) // Ensure this is the correct endpoint
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.status) { // Updated check to match the API response structure
                    toast.success(result.message || 'Category updated successfully');
                    onSuccess();
                    onHide();
                } else {
                    toast.error(result.message || 'Failed to update category');
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error('Error updating category');
                console.error(error);
            });
    };

    return (
        <Modal show={show} onHide={onHide} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Edit Categories</h5>
                        <Button variant="close" onClick={onHide} />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    className="form-control"
                                    id="name"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter name.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="priority" className="form-label">Priority</label>
                                <input
                                    type='number'
                                    className="form-control"
                                    id="priority"
                                    placeholder="Priority"
                                    value={priority}
                                    onChange={(e) => setPriority(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter priority.</div>
                            </div>
                            <div className="mb-3">
                                <label>Current Image:</label>
                                {itemData?.image && (
                                    <div>
                                        <img src={itemData.image} alt="Current" style={{ width: '100px', height: '100px' }} />
                                    </div>
                                )}
                                <label>File:</label>
                                <input type="file" ref={fileInputRef} onChange={handleFileChange} />
                            </div>
                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminCategoryEditModal;
