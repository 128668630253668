import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import useApi from "../service/useApi";
import { fetchDataFromAPI } from "../service/api";
import { getUser } from "../service/constants";
import DatePicker from "react-datepicker";

function AdminItemPriceBandEdit({ show, onHide, itemId, onSuccess, itemData }) {
  const [ItemID, setItemID] = useState("");
  const [stock, setStock] = useState("");
  const [price, setPrice] = useState("");
  const [priceband_id, setPriceband_id] = useState("");
  const [Specialprice_id, setSpecialprice_id] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [priceBand, setPriceBand] = useState([]);
  const [description, setDescription] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountWith, setDiscountWith] = useState("0");
  const [loading, setLoading] = useState(false);
  const adminLogin = JSON.parse(localStorage.getItem("adminLogin"));
  const storedPricebands = JSON.parse(localStorage.getItem("allPriceband"));
  const [fromdate, setFromdate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const user = JSON.parse(getUser());
  const [ItemData, setitemData] = useState();

  // console.log("ItemDataItemData---->", itemData);
  useEffect(() => {

    if (itemData) {
      setItemID(itemData?.item_id || "");
      setStock(itemData?.item?.stock || "");
      setPrice(itemData.price || "");
      setPriceband_id(itemData.priceband_id || "");
      setSpecialprice_id(itemData.special_price || "")
      setItemCode(itemData?.item?.item_code || "");
      setDescription(itemData?.item?.description || "");
      setDiscount(itemData?.item?.discount || "");
      setDiscountWith(itemData?.item?.discount_with || "0");
      setFromdate(itemData?.from_date || "")
      setToDate(itemData?.to_date || "")
    }
  }, [itemData]);
  useEffect(() => {
    fetchPricebandList();
    fetchPriceband();
  }, []);
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const requestBody = {
      item_id: ItemID,
      priceband_id:priceband_id,
      price: price,
      from_date: fromdate,
      to_date: toDate,
      special_price:Specialprice_id,
      id: itemData?.id,

    };
    console.log("requestBody", requestBody)
    fetchDataFromAPI("/admin/itempriceband/create", "post", requestBody, user?.token)
      .then((response) => {
        console.log(response,"----responseresponse");
        if (response.status) {
          toast.success("Item updated successfully!");
          onHide();
          if (onSuccess) {
            onSuccess(response);
          }
        } else {
          if (response.data && typeof response.data === "object") {
            Object.keys(response.data).forEach((key) => {
              response.data[key].forEach((message) => {
                toast.error(`${key}: ${message}`);
              });
            });
          } else {
            toast.error(response.message || "Failed to update item.");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        // toast.error("An error occurred while uploading data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPricebandList = (page) => {
    setLoading(true); // Show loader before API call
    fetchDataFromAPI(
      `/admin/item/dropdwon/list`,
      "get",
      "",
      user?.token
    )
    .then((response) => {
      console.log("response--- list>>", response);
        setLoading(false); // Hide loader after API call completes
        if (response.status === true) {
          const pricebandData = response?.data;
          setitemData(pricebandData)
        } else {
          // toast.error(response?.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error(error);
      });
  };

  const fetchPriceband = (page) => {
    setLoading(true); // Show loader
    fetchDataFromAPI(
      `/admin/item/priceband/list`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false); // Hide loader
        if (response?.status === true) {
          setPriceBand(response.data)
        } else {
          // toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error("Error fetching priceband list:", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content p-1">
          <div className="modal-header border-0">
            <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">
              Edit Item Priceband
            </h5>
            <Button variant="close" onClick={onHide} />
          </div>
          <div className="modal-body">
            <form
              className="needs-validation"
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="mb-3">
                <label htmlFor="priceband_id" className="form-label">Item</label>
                <select
                  value={ItemID}
                  className="form-control"
                  id="priceband_id"
                  onChange={(e) => setItemID(e?.target?.value)}
                  required
                >
                  <option value="">Item</option>
                  {ItemData?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{priceband.name}</option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="priceband_id" className="form-label">Priceband</label>
                <select
                  value={priceband_id}
                  className="form-control"
                  id="priceband_id"
                  onChange={(e) => setPriceband_id(e?.target?.value)}
                  required
                >
                  <option value="">select Priceband</option>
                  {priceBand?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{priceband.type}</option>
                  ))}
                </select>
              </div>
             
                <div className="mb-3">
                  <label htmlFor="price" className="form-label">
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    placeholder="Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter price.</div>
                </div>
             
                <div className="mb-3">
                  <label htmlFor="price" className="form-label">
                  Special Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    placeholder="Price"
                    value={Specialprice_id}
                    onChange={(e) => setSpecialprice_id(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter Special price.</div>
                </div>
             
              <div className="d-flex gap-3">
                <div className="mb-3">
                  <label htmlFor="fromDate" className="form-label me-2">
                    From Date
                  </label>
                  <DatePicker
                    selected={fromdate}
                    className="form-control"
                    onChange={(date) => setFromdate(date)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="toDate" className="form-label me-2">
                    To Date
                  </label>
                  <DatePicker
                    selected={toDate}
                    className="form-control"
                    onChange={(date) => setToDate(date)}
                  />
                </div>
              </div>
              <div className="d-flex mb-3">
                <Button variant="secondary" onClick={onHide}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  style={{ marginLeft: 10 }}
                >
                  {loading ? "Save..." : "Save"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AdminItemPriceBandEdit;
