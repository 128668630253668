import React, { useContext, useEffect, useState } from "react";
import { BsHeart, BsEye, BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import useApi from "../../service/useApi";
import { toast } from "react-toastify";
import "../../Screens/ItemsScreen/ItemsScreen.css";
import { useCart } from "../../context/CartContext";
import { getUser } from "../../service/constants";
import second from "../../assets/images/avatar/coming_soon.jpg";
import LoginModal from "../../commponets/LoginModal";
import { PricebandContext } from "../../context/PricebandContext";
function ItemsScreen() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [allPricebandListToken, setAllPricebandListtoken] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apiAction } = useApi();
  const [like, setlike] = useState(false);
  const [likedItems, setLikedItems] = useState({});
  const { addToCart, removeFromCart } = useCart();
  const user = JSON.parse(getUser());
  const [modalShow, setModalShow] = useState(false);

  const { setCategoryID, setSubCategoryID, setSubSubCategoryID } =
    useContext(PricebandContext);

    const { setCategoryName, setSubCategoryName, setSubSubCategoryName } =
    useContext(PricebandContext);
  useEffect(() => {
    fetchItemList();
    setCategoryID('')
    setSubCategoryID('')
    setSubSubCategoryID('')
    setCategoryName('')
    setSubCategoryName('')
    setSubSubCategoryName('')
  }, [currentPage]);

  useEffect(() => {
    if (user && user?.token == null) {
      fetchItemList();
    } else {
      fetchItemList_token();
    }
  }, []);
  const fetchItemList = async () => {
    setIsLoading(true);
    try {
      const response = await apiAction({
        url: `/user/item/list?page=${currentPage}`,
        method: "GET",
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setAllPricebandList(pricebandData);
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchItemList_token = async () => {
    setIsLoading(true);
    try {
      const response = await apiAction({
        url: `/user/customer/item/list?page=${currentPage}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setAllPricebandListtoken(pricebandData);
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLike = async (itemID) => {
    const requestBody = {
      item_id: itemID,
    };
    const requestOptions = {
      url: "/user/item/like",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`, // Ensure you include the token
      },
      body: JSON.stringify(requestBody),
    };
    try {
      const response = await apiAction(requestOptions);
      if (response?.status === true && response.data.is_liked == true) {
        setLikedItems((prevState) => ({
          ...prevState,
          [itemID]: !prevState[itemID],
        }));
        toast.success(response.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    }
  };

  const handleAddToCart = (product) => {
    if (user === null) {
      setModalShow(true);
      return;
    }
    const productWithQuantity = { ...product, quantity: product.quantity || 1 };
    addToCart(productWithQuantity);
    toast.success("Product added to cart");
  };
  return (
    <>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      <section className="my-lg-14 my-8">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-6">
              <h3 className="mb-0">Items</h3>
              <div className="d-flex justify-content-end align-items-center mt-4 mb-0">
                <Link
                  to={{
                    pathname: "/product",
                    state: { prevTitle: "Items" },
                  }}
                  className="text-decoration-none text-inherit"
                >
                  <a href="index.html#!" onClick={handleNextPage}>
                    View All
                    <BsChevronRight />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="text-center mt-5">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : !allPricebandList || allPricebandList.length === 0 ? (
            <div className="col-12 mb-6 d-flex justify-content-center align-items-center">
              <h5 className="mb-0 text-center">No Product</h5>
            </div>
          ) : (
            <div className="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-3">
              {user?.token == null
                ? allPricebandList?.map((product) => {

                  return (
                    <div key={product.id} className="col">
                      <div className="card card-product cards21">
                        <div className="card-body">
                          <div className="text-center position-relative grid-template-columns">
                            <Link
                              to={
                                product?.item_category?.length > 0
                                  ? `/productList/${product?.id}/${product?.item_category[0]?.category_id}`
                                  : `/productList/${product?.id}`
                              }
                              className="text-decoration-none text-inherit"
                            >
                              <picture>
                                <img
                                  src={product?.itemImage[0] || second}
                                  alt="Product"
                                  className="product-image1"
                                  style={{height:'100px' ,width:'100px'}}
                                />
                              </picture>
                            </Link>
                            <div className="card-product-action">
                              <Link
                                to={`/productList/${product.id}`}
                                className="text-decoration-none text-inherit"
                              >
                                <a
                                  className="btn-action"
                                  data-bs-toggle="tooltip"
                                  data-bs-html="true"
                                  title="Quick View"
                                >
                                  <BsEye />
                                </a>
                              </Link>
                              <a
                                href="#"
                                className="btn-action"
                                data-bs-toggle="tooltip"
                                data-bs-html="true"
                                title="Wishlist"
                                style={{
                                  backgroundColor: likedItems[product.id]
                                    ? "#FF0000"
                                    : "#dedad9",
                                }}
                                onClick={() => handleLike(product.id)}
                              >
                                <BsHeart
                                  style={{
                                    color: likedItems[product.id]
                                      ? "#fff"
                                      : "#000",
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="text-small mb-1 mt-1">
                            <small>{product.item_code}</small>
                          </div>
                          <h2 className="fs-6">{product.name}</h2>
                          <div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-3">
                            {/* <div>
                              <span className="text-dark">
                                {product?.currency} {product?.price}
                              </span>
                            </div> */}
                            <div>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleAddToCart(product)}
                              >
                               
                                Sign in for price
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                : allPricebandListToken?.map((product) => {
                  return (
                    <div key={product?.id} className="col">
                      <div className="card card-product cards21">
                        <div className="card-body">
                          <div className="text-center position-relative grid-template-columns">
                            <Link
                              to={
                                product?.item_category?.length > 0
                                  ? `/productList/${product?.id}/${product?.item_category[0]?.category_id}`
                                  : `/productList/${product?.id}`
                              }
                              className="text-decoration-none text-inherit"
                            >
                              <picture>
                                <img
                                  src={product?.itemImage[0] || second}
                                  alt="Product"
                                  className="product-image1"
                                  style={{height:'100px' ,width:'100px'}}
                                />
                              </picture>
                            </Link>
                            <div className="card-product-action">
                              <Link
                                to={`/productList/${product?.id}`}
                                className="text-decoration-none text-inherit"
                              >
                                <a
                                  className="btn-action"
                                  data-bs-toggle="tooltip"
                                  data-bs-html="true"
                                  title="Quick View"
                                >
                                  <BsEye />
                                </a>
                              </Link>
                              <a
                                href="#"
                                className="btn-action"
                                data-bs-toggle="tooltip"
                                data-bs-html="true"
                                title="Wishlist"
                                style={{
                                  backgroundColor: likedItems[product?.id]
                                    ? "#FF0000"
                                    : "#dedad9",
                                }}
                                onClick={() => handleLike(product?.id)}
                              >
                                <BsHeart
                                  style={{
                                    color: likedItems[product?.id]
                                      ? "#fff"
                                      : "#000",
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="text-small mb-1 mt-1">
                            <small>{product?.item_code}</small>
                          </div>
                          <h2 className="fs-6">{product?.name}</h2>
                          <div className="d-flex justify-content-between align-items-center mt-3">
                            <div>
                              <span className="text-dark">
                                {product?.currency} {product?.price}
                              </span>
                            </div>
                            {
                product.stock > 0? (
                  <div>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleAddToCart(product)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-plus"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    Add
                  </button>
                </div>
                ) : (
                  <button className="btn btn-ghost-dark bg-gray-100 btn-sm" disabled>
                    Out of stock
                  </button>
                )
              }
                            {/* <div>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleAddToCart(product)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-plus"
                                >
                                  <line x1="12" y1="5" x2="12" y2="19"></line>
                                  <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                Add
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ItemsScreen;
