import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
  const navigate = useNavigate();

  const redirectToDashboard = () => {
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    if (userLogin) {
      if (userLogin.role === 'admin') {
        navigate('/adminDashboard');
      } else if (userLogin.role === 'custom') {
        navigate('/dashboard');
      }
    } else {
      // Optionally handle the case where userLogin is null
      navigate('/login'); // or another appropriate route
    }
  };

  return (
    <main>
      <div className="container">
        <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1>404</h1>
          <h2>The page you are looking for doesn't exist.</h2>
          <button className="btn" onClick={redirectToDashboard}>
            Go to Dashboard
          </button>
          {/* <img
            src="assets/img/not-found.svg"
            className="img-fluid py-5"
            alt="Page Not Found"
          /> */}
        </section>
      </div>
    </main>
  );
};

export default Error;
