import React, { useState, useEffect } from 'react';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import AdminDashbords from '../AdminDashbords/AdminDashbords';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminLogin from '../AdminLogin/AdminLogin';
import { useNavigate } from 'react-router-dom';
import constants, { getUser } from '../../service/constants';
import SellerProfile from '../SellerProfile/SellerProfile';
import SellerDashborad from '../SellerDashborad/SellerDashborad';

function SellerDashboard() {
  const user = JSON.parse(getUser());
  console.log("user", user)
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(getUser());

    if (!user) {
      navigate("/sellerDashboard");
    } else {
      if (user.role === 'admin') {
        navigate('/adminDashboard');
      } else if (user.role === 'customer') {
        navigate('/dashboard');
      }
       else if (user.role === 'sales') {
        navigate('/sellerDashboard');
      }
    }
  }, [navigate]);


  return (
    <>
      <SellerProfile />
      {/* <AdminNavbar /> */}
      <div className="main-wrapper">
        <SellerDashborad />
      </div>
    </>
  );
}

export default SellerDashboard;