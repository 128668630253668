import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import useApi from '../service/useApi';
import { getUser } from '../service/constants';

function AdimnEditSubCategoryModal({ show, onHide, itemId, onSuccess, itemData }) {
  const [name, setName] = useState('');
  const { apiAction } = useApi();
  const [categoryID, setCategoryID] = useState('');
  const [categorys , setCategorys] = useState([])
  const [loading, setLoading] = useState(false);
  const user = JSON?.parse(getUser());
  // const categorys = JSON?.parse(localStorage.getItem('category'));
  useEffect(() => {
    if (itemData) {
      setName(itemData.name || '');
      setCategoryID(itemData.category.id || '');
    }
  }, [itemData]);
  useEffect(()=>{
    fetchCategoryList()
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${user?.token}`);

      const formdata = new FormData();
      formdata.append("category_id", categoryID);
      formdata.append("name", name);
      formdata.append("id", itemData?.id);


      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`https://laravel.snackhouseuk.com/api/admin/subcategory/create`, requestOptions);
      const result = await response?.json();
      console.log(result,"---===-resultresult");
      if (response.ok) {
        toast.success('Item updated successfully!');
        onHide();
        if (onSuccess) {
          // onSuccess(result);
        }
      } else {
        toast.error(result.message || 'Failed to update item.');
      }
    } catch (error) {
      console.error('Failed to update item:', error);
      toast.error('Failed to update item.');
    } finally {
      setLoading(false);
    }
  };
  const fetchCategoryList = async (page) => {
    setLoading(true); // Show loader
    try {
        const response = await apiAction({
            url: `/admin/category/list`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user?.token}`,
            },
        });
        if (response.status) {
            const pricebandData = response?.data;
            setCategorys(pricebandData)
        } else {
            toast.error(response?.message);
        }
    } catch (error) {
        console.error('Error fetching categories list:', error);
    } finally {
        setLoading(false); // Hide loader
    }
};
  return (
    <Modal show={show} onHide={onHide} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="modal-content p-1">
          <div className="modal-header border-0">
            <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Edit subcategories</h5>
            <Button variant="close" onClick={onHide} />
          </div>
          <div className="modal-body">
            <form className="needs-validation" noValidate onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="priceband_id" className="form-label">Select Category</label>
              <select
                  value={categoryID}
                  className="form-control"
                  id="priceband_id"
                  onChange={(e) => setCategoryID(e.target.value)}
                  required
              >
                  <option value="">Select Category</option>
                  {categorys?.map((Categories) => (
                      <option key={Categories?.id} value={Categories?.id}>
                          {Categories?.name}
                      </option>
                  ))}
              </select>
              <div className="invalid-feedback">Please select priceband ID.</div>
          </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <div className="invalid-feedback">Please enter item name.</div>
              </div>              
              <div className="d-flex mb-3">
                <Button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={onHide}
                  disabled={loading}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary w-100"
                  style={{ marginLeft: 7 }}
                  disabled={loading}
                >
                  {loading ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AdimnEditSubCategoryModal;
