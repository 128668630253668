import React, { useEffect, useState } from 'react'
import AdminProfile from '../AdminProfile/AdminProfile'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import { Link, useLocation, useParams } from 'react-router-dom';
import { getUser } from '../../service/constants';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../../service/api';
import { format } from 'date-fns';
import second from '../../assets/images/avatar/coming_soon.jpg';
import Processing from '../../commponets/Processing/Processing';

function AdminOrderListView() {
    const location = useLocation();
    const user = JSON.parse(getUser());
    const [vewItems, setViewItems] = useState();
    const [loading, setLoading] = useState(true);
    const { orderNumber } = useParams();
console.log('orderNumber',orderNumber);

    useEffect(() => {
        fetchPricebandList();
    }, []);

    const fetchPricebandList = (page) => {
        setLoading(true);
        fetchDataFromAPI(
            `/admin/order/view?orderNumber=${orderNumber}`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false);
                if (response?.status === true) {
                    console.log(response.data,"----response.data");
                    
                    setViewItems(response.data);
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error, "---error");
            });
    };

    return (
        <>
            <AdminProfile />
            <div className="main-wrapper">
                <AdminNavbar />
                <main className="main-content-wrapper">
                    <div className="container">
                        <div className="row mb-8">
                            <div className="col-md-12">
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                                    <div>
                                        <h2>{vewItems?.order_number}</h2>
                                    </div>
                                    <div>
                                        <Link to={'/AdminOrder'}>
                                            <a href="order-single.html#" className="btn btn-primary">
                                                Back to all orders
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="card h-100 card-lg">
                                <div className="modal-body">
                                    {loading ? (
                                        <Processing /> // Show the loader while loading
                                    ) : (
                                        <form className="needs-validation" noValidate>
                                            <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>Image</th>
                                                        <th>Item code</th>
                                                        <th>Name</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(vewItems?.order_line) &&
                                                        vewItems?.order_line?.map((item) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    {console.log(item,"---items")}
                                                                    <img
                                                                        src={item?.item?.itemImage[0] == null && second ? second : item?.item?.itemImage[0] }
                                                                        alt=""
                                                                        className="icon-shape icon-md"
                                                                        style={{ width: '50px', height: '50px' }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {item?.item.item_code}
                                                                </td>
                                                                <td>
                                                                    {item?.item.name}
                                                                </td>
                                                                <td>
                                                                    {item?.quantity}
                                                                </td>
                                                                <td>
                                                                    {item?.item?.price}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <div className="mb-3 d-flex justify-content-end">
                                                <div>
                                                    <label htmlFor="item_id" className="form-label">
                                                        Total
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="item_id"
                                                        value={vewItems?.totalGrossValue}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AdminOrderListView;
