import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "../service/useApi";
import { PricebandContext } from "../context/PricebandContext";
import { Link } from "react-router-dom";
import '../commponets/Sider.css'
function Sider({ onSelectCategory, onSelectSubCategory, onSelectSubSubCategory }) {
  const { apiAction } = useApi();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  console.log("subCategory", subCategory);
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [openSubSubCategory, setOpenSubSubCategory] = useState(false);
  const { subCategoryid, subSubCategoryid } = useContext(PricebandContext);

  useEffect(() => {
    fetchCategoryList();
    fetchSubCategoryList();
    fetchSubSubCategoryList();
  }, [subCategoryid, subSubCategoryid]);

  const fetchCategoryList = async () => {
    try {
      const response = await apiAction({
        url: "/user/category/list",
        method: "GET",
      });
      if (response.status === true) {
        console.log(response, "----responseresponseresponse");
        const categoryData = response?.data?.map((item) => ({
          id: item.id,
          name: item.name,
          image: item.image,
          priority: item.priority,
          created_at: item.created_at,
          updated_at: item.updated_at,
        }));
        setCategory(categoryData);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    }
  };

  const fetchSubCategoryList = async () => {
    let url = "/user/subcategory/list";

    if (subCategoryid) {
      url += `/${subCategoryid}`;
    }

    try {
      const response = await apiAction({
        url: url,
        method: "GET",
      });

      console.log("response--------------", response);

      // Depending on your API response structure, set subCategory state
      if (subCategoryid) {
        setSubCategory(response?.data); // Assuming response.data is sufficient for single subcategory data
      } else {
        setSubCategory(response?.data); // Assuming response.data.data is an array of subcategories
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchSubSubCategoryList = async () => {
    let url = "/user/subsubcategory/list";

    if (subSubCategoryid) {
      url += `/${subSubCategoryid}`;
    }
    try {
      const response = await apiAction({
        url: url,
        method: "GET",
      });

      console.log("response su sub", response);

      if (subSubCategoryid) {
        setSubSubCategory(response?.data); // Assuming response.data is sufficient for single subcategory data
      } else {
        setSubSubCategory(response?.data); // Assuming response.data.data is an array of subcategories
      }
    } catch (error) {
      console.log(error, "------error");
    }
  };

  const handleCategoryClick = (categoryId) => {
    onSelectCategory(categoryId);
    setOpenCategory(false); // Close the category after selection if needed
  };

  const handleSubCategoryClick = (subCategoryId) => {
    onSelectSubCategory(subCategoryId);
    setOpenSubCategory(false); // Close the subcategory after selection if needed
  };

  const handleSubSubCategoryClick = (subSubCategoryId) => {
    onSelectSubSubCategory(subSubCategoryId);
    setOpenSubSubCategory(false); // Close the subsubcategory after selection if needed
  };

  return (
    <aside className="col-lg-3 col-md-4 mb-6 mb-md-0 sider-container w-100">
      <div
        className="offcanvas offcanvas-start offcanvas-collapse w-md-50"
        tabIndex="-1"
        id="offcanvasCategory"
        aria-labelledby="offcanvasCategoryLabel"
      >
        <div className="offcanvas-header d-lg-none btn">
          <h5 className="offcanvas-title" id="offcanvasCategoryLabel">
            Filter
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body ps-lg-2 pt-lg-0 sider-content">
          <div className="mb-8">
            <a
              href="#"
              className="nav-link collapsed"
              onClick={() => setOpenCategory(!openCategory)}
            >
              <h5 className="mb-3 card mb-4 bg-light border-5 card-body p-2">
                Categories
              </h5>
            </a>
            {openCategory && (
              <ul
                className={`nav nav-category ${openCategory ? "show" : ""}`}
                id="categoryCollapseMenu"
              >
                {category?.map((product) => (
                  <li className="nav-item border-bottom w-100" key={product.id}>
                    <a
                      href="#"
                      className="nav-link collapsed"
                      onClick={() => handleCategoryClick(product.id)}
                    >
                      {product.name}
                      <i className="feather-icon icon-chevron-right"></i>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="mb-8">
            <a
              href="#"
              className="nav-link collapsed"
              onClick={() => setOpenSubCategory(!openSubCategory)}
            >
              <h5 className="mb-3 card mb-4 bg-light border-5 card-body p-2">
                SubCategories
              </h5>
            </a>
            {openSubCategory && (
              <ul
                className={`nav nav-category ${openSubCategory ? "show" : ""}`}
                id="subcategoryCollapseMenu"
              >
                {subCategory?.map((product) => (
                  <li className="nav-item border-bottom w-100" key={product.id}>
                    <a
                      href="#"
                      className="nav-link collapsed"
                      onClick={() => handleSubCategoryClick(product.id)}
                    >
                      {product.name}
                      <i className="feather-icon icon-chevron-right"></i>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="mb-8">
            <a
              href="#"
              className="nav-link collapsed"
              onClick={() => setOpenSubSubCategory(!openSubSubCategory)}
            >
              <h5 className="mb-3 card mb-4 bg-light border-5 card-body p-2">
                SubSubCategories
              </h5>
            </a>
            {openSubSubCategory && (
              <ul
                className={`nav nav-category ${openSubSubCategory ? "show" : ""}`}
                id="subsubcategoryCollapseMenu"
              >
                {subSubCategory?.map((product) => (
                  <li className="nav-item border-bottom w-100" key={product.id}>
                    <a
                      href="#"
                      className="nav-link collapsed"
                      onClick={() => handleSubSubCategoryClick(product.id)}
                    >
                      {product.name}
                      <i className="feather-icon icon-chevron-right"></i>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="mb-8">
            <div>
              <div id="priceRange" className="mb-3"></div>
              <span id="priceRange-value" className="small"></span>
            </div>
          </div>

          <div className="mb-8 position-relative">
            {/* <div className="position-absolute p-3">
              <Link to={"/"} className="">
                Shop Now
              </Link>
            </div> */}
            <img
              src="../assets/images/banner/assortment-citrus-fruits.png"
              alt=""
              className="img-fluid rounded"
            />
          </div>
        </div>
      </div>
    </aside>
  );
}

export default Sider;
