import React, { useState } from 'react'
import logo from "../../assets/images/logo/freshcart-logo.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BsCart, BsListUl, BsBag, BsHouseGearFill, BsPersonVideo3, BsCardChecklist, BsReceiptCutoff, BsPercent, BsFillStarFill, BsFillHouseDoorFill, BsPeopleFill, BsPersonRaisedHand, BsTelephoneFill, BsCardImage } from "react-icons/bs";
import constants, { getUser } from '../../service/constants';
import { toast } from 'react-toastify';
import profile from "../../assets/images/avatar/user.png"
function AdminProfile() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const handleNavLinkClick = (path) => {
    setActiveLink(path);
  };

  const handleLogout = () => {
    localStorage.removeItem(constants.USER);
    toast.success('Successfully logged out.');
    navigate('/AdminLogin');
    window.location.reload();
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  return (
    <>
      <div>
        {/* navbar */}
        <nav className="navbar navbar-expand-lg navbar-glass">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center">
                <a
                  className="text-inherit d-block d-xl-none me-4"
                  data-bs-toggle="offcanvas"
                  href="index.html#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    fill="currentColor"
                    className="bi bi-text-indent-right"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm10.646 2.146a.5.5 0 0 1 .708.708L11.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zM2 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </a>
                <form role="search">
                  {/* <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  /> */}
                </form>
              </div>
              <div>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profile}
                        alt=""
                        className="avatar avatar-md rounded-circle"
                        style={{ height: '45px', width: '45px' }}
                      />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                      <li className="px-4 py-3">
                        <h5 className="mb-1 h6">{user?.name}</h5>
                        <small>{user?.email}</small>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li className="px-4 py-2">
                        <a href="#" onClick={handleLogout}>Log Out</a>
                      </li>
                    </ul>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </nav>
        <div className="main-wrapper">
          <nav
            className="navbar-vertical-nav offcanvas offcanvas-start navbar-offcanvac"
            tabIndex={-1}
            id="offcanvasExample"
          >
            <div className="navbar-vertical">
              <div className="px-4 py-5 d-flex justify-content-between align-items-center">
                <a href="../index.html" className="navbar-brand">
                  <img src={logo} alt="" />
                </a>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div className="navbar-vertical-content flex-grow-1" data-simplebar="">
                <ul className="navbar-nav flex-column" id="sideNavbar">
                  <li className="nav-item">
                    <Link className={`nav-link ${location.pathname === '/adminDashboard' ? 'active' : ''}`}
                      to="/adminDashboard"
                      onClick={() => handleNavLinkClick('/adminDashboard')}>
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-house"></i><BsFillHouseDoorFill />
                        </span>
                        <span className="nav-link-text">Dashboard</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item mt-6 mb-3">
                    <span className="nav-label">Store Managements</span>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/image' ? 'active' : ''}`}
                      to="/image"
                      onClick={() => handleNavLinkClick('/image')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-cart"></i><BsCardImage /></span>
                        <span className="nav-link-text">Manage Files
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/customer-List' ? 'active' : ''}`}
                      to="/customer-List"
                      onClick={() => handleNavLinkClick('/customer-List')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-cart"></i><BsPeopleFill /></span>
                        <span className="nav-link-text">Customer
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/Taxes' ? 'active' : ''}`}
                      to="/Taxes"
                      onClick={() => handleNavLinkClick('/Taxes')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <i className="bi bi-people"></i><BsPercent />
                        </span>
                        <span className="nav-link-text">Tax</span>
                      </div>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/products' ? 'active' : ''}`}
                      to="/products"
                      onClick={() => handleNavLinkClick('/products')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-cart"></i><BsCart /></span>
                        <span className="nav-link-text">priceband</span>
                      </div>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#navCategoriesOrders" aria-expanded="false" aria-controls="navCategoriesOrders">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><BsBag /></span>
                        <span className="nav-link-text">All categories</span>
                      </div>
                    </a>
                    <div id="navCategoriesOrders" className="collapse" data-bs-parent="#sideNavbar">
                      <ul className="nav flex-column">
                        <Link
                          className={`nav-link ${location.pathname === '/category' ? 'active' : ''}`}
                          to="/category"
                          onClick={() => handleNavLinkClick('/category')}
                        >
                          <li className="nav-item">
                            <a className="nav-link" href="">categories</a>
                          </li>
                        </Link>
                        <Link className="nav-link" to="/subcategory">
                          <li className="nav-item"><a className="nav-link" href="">Subcategories</a></li>
                        </Link>
                        <Link className="nav-link" to="/sub-subcategory">
                          <li className="nav-item"><a className="nav-link" href="">SubSubcategories</a></li>
                        </Link>
                      </ul>
                    </div>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/items' ? 'active' : ''}`}
                      to="/items"
                      onClick={() => handleNavLinkClick('/items')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsListUl /></span>
                        <span className="nav-link-text">Items</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/Popular' ? 'active' : ''}`}
                      to="/Popular"
                      onClick={() => handleNavLinkClick('/Popular')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsFillStarFill /></span>
                        <span className="nav-link-text">Popular items</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/Item-Categories' ? 'active' : ''}`}
                      to="/Item-Categories"
                      onClick={() => handleNavLinkClick('/Item-Categories')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <i className="bi bi-shop"></i><BsHouseGearFill />
                        </span><span className="nav-link-text">Item Categories</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/Item-Priceband' ? 'active' : ''}`}
                      to="/Item-Priceband"
                      onClick={() => handleNavLinkClick('/Item-Priceband')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsListUl /></span>
                        <span className="nav-link-text">Item Priceband</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/AdminOrder' ? 'active' : ''}`}
                      to="/AdminOrder"
                      onClick={() => handleNavLinkClick('/AdminOrder')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsPersonVideo3 /></span>
                        <span className="nav-link-text">Customer's Orders</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/AdminSalesOrderList' ? 'active' : ''}`}
                      to="/AdminSalesOrderList"
                      onClick={() => handleNavLinkClick('/AdminSalesOrderList')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsCardChecklist /></span>
                        <span className="nav-link-text">Sale's Orders</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/AdminImportSales' ? 'active' : ''}`}
                      to="/AdminImportSales"
                      onClick={() => handleNavLinkClick('/AdminImportSales')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsReceiptCutoff /></span>
                        <span className="nav-link-text">Import sales</span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/commission-Allocation' ? 'active' : ''}`}
                      to="/commission-Allocation"
                      onClick={() => handleNavLinkClick('/commission-Allocation')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-cart"></i><BsPercent /></span>
                        <span className="nav-link-text">Commission Allocation
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/customer-Allocation' ? 'active' : ''}`}
                      to="/customer-Allocation"
                      onClick={() => handleNavLinkClick('/customer-Allocation')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-cart"></i><BsPersonRaisedHand /></span>
                        <span className="nav-link-text">Customer Allocation
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${location.pathname === '/contact-List' ? 'active' : ''}`}
                      to="/contact-List"
                      onClick={() => handleNavLinkClick('/contact-List')}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsTelephoneFill /></span>
                        <span className="nav-link-text">Contact</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default AdminProfile