import React, { useEffect, useState } from 'react'
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import { getUser } from '../../service/constants';
import { format } from 'date-fns';
import { BsTrash, BsThreeDotsVertical, BsPencilSquare } from "react-icons/bs";
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import CustomerAllocationModal from '../../commponets/CustomerAllocationModal';
import CustomerAllocationEditModal from '../../commponets/CustomerAllocationEditModal';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import ProgressBar from '../../commponets/Processing/Processing';
import { Button } from 'react-bootstrap';
function AdminCustomerAllocation() {

    const [modalShow, setModalShow] = useState(false);
    const [allPricebandList, setAllPricebandList] = useState([]);
    const [filteredPricebandList, setFilteredPricebandList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [editmodalShow, setEditModalShow] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [editItemData, setEditItemData] = useState(null);
    const [loading, setLoading] = useState(false); // State for loader
    const [editItemId, setEditItemId] = useState(null);
    const user = JSON.parse(getUser());
    const [totalItems, setTotalItems] = useState(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    useEffect(() => {
        fetchcustomerList()
    }, [])
    const fetchcustomerList = (page) => {
        setLoading(true); // Show loader
        fetchDataFromAPI(
            `/admin/salescustomer/allocation/list?perPage=10&page=${page}&searchKey=${searchTerm}`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false); // Hide loader
                if (response.status === true) {
                    const pricebandData = response.data.data;
                    setAllPricebandList(pricebandData);
                    setFilteredPricebandList(pricebandData);
                    setTotalPages(response?.data?.last_page);
                    setTotalItems(response?.data?.total)
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching priceband list:", error);
            });
    };


    useEffect(() => {
        fetchcustomerList(currentPage);
    }, [currentPage]);

    useEffect(() => {
        filterPricebandList();
    }, [searchTerm, allPricebandList]);

    const filterPricebandList = () => {
        if (searchTerm.trim() === '') {
            setFilteredPricebandList(allPricebandList);
        } else {
            const filteredList = allPricebandList.filter(item =>
                item?.customer?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
                || item?.customer?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
                || item?.sales1?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
                || item?.sales2?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
                || item?.sales3?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
                || item?.sales4?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
                || item?.sales5?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
            );
            setFilteredPricebandList(filteredList);
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleModalSuccess = () => {
        fetchcustomerList(currentPage);
    };
    const handleEditItemClick = (item) => {
        setEditItemData(item);
        setEditItemId(item.id);
        setEditModalShow(true);
    };

    const handleDeleteItem = (itemId) => {
        setLoading(true);
        fetchDataFromAPI(
            `/admin/salescustomer/allocation/delete/${itemId}`,
            "delete",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false);
                if (response.status === true) {
                    toast.success(response.message);
                    fetchcustomerList(currentPage);
                    setDeleteModalShow(false);
                } else {
                    toast.error(response.message);
                    setLoading(false); // Hide loader
                    setDeleteModalShow(false);
                    setDeleteItemId(null);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error deleting item:", error);
            });
    };

    const handleShowDeleteModal = (itemId) => {
        setDeleteModalShow(true);
        setDeleteItemId(itemId);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleFileUpload = async (event) => {
        setLoading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${user?.token}`,
            },
            body: formData,
        };

        try {
            const response = await fetch('https://laravel.snackhouseuk.com/api/admin/salescustomer/allocation/import', {
                ...requestOptions,
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
                    }
                },
            });

            setLoading(false);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Upload failed');
            }

            const data = await response.json();
            if (data.status === true) {
                window.location.reload();
                toast.success(data.message);
              } else if (data.status === false) {
                window.location.reload();
                toast.error(data.error);
              }else {
                toast.error("Invalid format");
              }
            fetchcustomerList(currentPage);
        } catch (error) {
            setLoading(false);
            console.error('Upload error:', error);
            toast.error(error.message || 'invalid format');
        } finally {
            setUploadProgress(0); // Reset progress bar
        }
    };

    return (
        <>
            <AdminProfile />
            <CustomerAllocationModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
            <CustomerAllocationEditModal show={editmodalShow} onHide={() => setEditModalShow(false)} itemId={editItemId} onSuccess={handleModalSuccess} itemData={editItemData} />
            <DeleteConfirmationModal
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                onDelete={() => handleDeleteItem(deleteItemId)}
                loading={loading}
            />
            <div className="main-wrapper">
                <AdminNavbar />
                <main className="main-content-wrapper">
                    <div className="container">
                        <div className="row mb-8">
                            <div className="col-md-12">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div>
                                        <h2>Customer Allocation</h2>
                                    </div>
                                    <div>
                                        <input type="file" onChange={handleFileUpload} className="btn btn-light ml-2 g-13 m-lg-3" style={{ width: '116px' }} />
                                        <button className="btn btn-primary ml-2" onClick={() => setModalShow(true)}>
                                            Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {uploadProgress > 0 && (
                            <div className="row mb-4">
                                <div className="col-12">
                                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-xl-12 col-12 mb-5">
                                <div className="card h-100 card-lg">
                                    <div className="px-6 py-6">
                                        <div className="row justify-content-between">
                                            <div className="col-lg-4 col-md-6 col-12 mb-2 mb-lg-0">
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        fetchcustomerList();
                                                    }}
                                                >
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Search for products"
                                                            aria-label="Search"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            onKeyPress={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault(); // Prevents the default form submission
                                                                    fetchcustomerList();
                                                                }
                                                            }}
                                                        />
                                                        <Button onClick={() => fetchcustomerList()}>
                                                            <span>Search</span>
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {loading ? (
                                            <div className="text-center py-4">
                                                <div className="loader-container" id="loader-container">
                                                    <div className="loader"></div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="table-responsive">
                                                <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>Customer name</th>
                                                            <th>Customer email</th>
                                                            <th>sales1</th>
                                                            <th>sales2</th>
                                                            <th>sales3</th>
                                                            <th>sales4</th>
                                                            <th>sales5</th>
                                                            <th>Date</th>
                                                            <th>Delete & Edit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            filteredPricebandList?.length > 0 ? (
                                                                filteredPricebandList.map((item) => (
                                                                    <tr key={item.id}>
                                                                        <td>
                                                                            {item?.customer?.name}
                                                                        </td>
                                                                        <td>
                                                                            {item?.customer?.email}
                                                                        </td>
                                                                        <td>
                                                                            {item?.sales1?.email}
                                                                        </td>
                                                                        <td>
                                                                            {item?.sales2?.email}
                                                                        </td>
                                                                        <td>
                                                                            {item?.sales3?.email}
                                                                        </td>
                                                                        <td>
                                                                            {item?.sales4?.email}
                                                                        </td>
                                                                        <td>
                                                                            {item?.sales5?.email}
                                                                        </td>
                                                                        <td>{format(new Date(item.created_at), "dd MMMM yyyy")}</td>
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <td>
                                                                                    <button
                                                                                        className="btn-link dropdown-item text-danger"
                                                                                        onClick={() => handleShowDeleteModal(item?.id)}
                                                                                    >
                                                                                        <BsTrash />

                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        className="btn-link dropdown-item"
                                                                                        onClick={() => handleEditItemClick(item)}>
                                                                                        <BsPencilSquare />

                                                                                    </button>
                                                                                </td>

                                                                                {/* </ul> */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="9" className="text-center">No Customer Allocation found.</td>
                                                                </tr>
                                                            )
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                    <div className="border-top d-md-flex justify-content-between align-items-center px-6 py-6">
                                        <span>Showing {currentPage} to {allPricebandList?.length} of {totalItems} entries</span>

                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={setCurrentPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AdminCustomerAllocation