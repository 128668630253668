import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import axios from 'axios'; // Import Axios
import { fetchDataFromAPI } from '../service/api';
import { getUser } from '../service/constants';

function AdminImportSalesModal(props) {
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [contact2, setContact2] = useState('');
    const [loading, setLoading] = useState(false);
    const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
    const user = JSON.parse(getUser());
    const handleUploadData = (e) => {
        setLoading(true);
        e.preventDefault();
        const requestBody = {
            name: name,
            email:email,
            contact:contact,
            contact2:contact2
        };
        fetchDataFromAPI("/admin/sales/add", "post", requestBody, user?.token)
            .then((response) => {
                if (response.status == true) {
                    console.log(response, "---response");
                    toast.success(response.message);
                    props.onHide();
                    props.onSuccess();
                } else {
                    if (response.data.type) {
                        toast.error(response.data.type[0]);
                    } else {
                        toast.error(response.data.message || "An error occurred");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Add sales</h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleUploadData}>
                            <div className="mb-3">
                                <label htmlFor="type" className="form-label">Name</label>
                                <input
                                    className="form-control"
                                    id="type"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter name.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter Email.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Contact" className="form-label">Contact</label>
                                <input
                                    className="form-control"
                                    id="Contact"
                                    placeholder="Contact"
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter Contact.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contact2" className="form-label">Contact2</label>
                                <input
                                    className="form-control"
                                    id="contact2"
                                    placeholder="contact2"
                                    value={contact2}
                                    onChange={(e) => setContact2(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter contact2.</div>
                            </div>
                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminImportSalesModal;
