import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import useApi from '../service/useApi';
import { fetchDataFromAPI } from '../service/api';
import { getUser } from '../service/constants';

function AdminItemsEditModal({ show, onHide, itemId, onSuccess, itemData }) {
  const [name, setName] = useState('');
  const [stock, setStock] = useState('');
  const [price, setPrice] = useState('');
  const [priceband_id, setPriceband_id] = useState('');
  const [itemCode, setItemCode] = useState('');
  const [description, setDescription] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountWith, setDiscountWith] = useState('0');
  const [loading, setLoading] = useState(false);
  const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
  const storedPricebands = JSON.parse(localStorage.getItem('allPriceband'));
  const user = JSON.parse(getUser());
  useEffect(() => {
    if (itemData) {
      setName(itemData.name || '');
      setStock(itemData.stock || '');
      setPrice(itemData.price || '');
      setPriceband_id(itemData.priceband_id || '');
      setItemCode(itemData.item_code || '');
      setDescription(itemData.description || '');
      setDiscount(itemData.discount || '');
      setDiscountWith(itemData.discount_with || '0');
    }
  }, [itemData]);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const requestBody = {
      name: name,
      stock: stock,
      price: price,
      item_code: itemCode,
      description: description,
      discount: discount,
      discount_with: discountWith,
      id: itemData?.id
    };
    fetchDataFromAPI("/admin/item/create", "post", requestBody, user?.token)
      .then((response) => {
        if (response.status) {
          toast.success('Item updated successfully!');
          onHide();
          if (onSuccess) {
            onSuccess(response);
          }
        } else {
          if (response.data && typeof response.data === 'object') {
            Object.keys(response.data).forEach((key) => {
              response.data[key].forEach((message) => {
                toast.error(`${key}: ${message}`);
              });
            });
          } else {
            toast.error(response.message || 'Failed to update item.');
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("An error occurred while uploading data");
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (
    <Modal show={show} onHide={onHide} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="modal-content p-1">
          <div className="modal-header border-0">
            <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Edit Item</h5>
            <Button variant="close" onClick={onHide} />
          </div>
          <div className="modal-body">
            <form className="needs-validation" noValidate onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <div className="invalid-feedback">Please enter item name.</div>
              </div>
              <div className="d-flex mb-3">
                <div className="mb-3">
                  <label htmlFor="stock" className="form-label">Stock</label>
                  <input
                    type="number"
                    className="form-control"
                    id="stock"
                    placeholder="Stock"
                    value={stock}
                    onChange={(e) => setStock(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter stock.</div>
                </div>
                <div className="mb-3" style={{ marginLeft: 10 }}>
                  <label htmlFor="price" className="form-label">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    placeholder="Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter price.</div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="item_code" className="form-label">Item Code</label>
                <input
                  className="form-control"
                  id="item_code"
                  placeholder="Item Code"
                  value={itemCode}
                  onChange={(e) => setItemCode(e.target.value)}
                  required
                />
                <div className="invalid-feedback">Please enter item code.</div>
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">Description</label>
                <input
                  className="form-control"
                  id="description"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <div className="invalid-feedback">Please enter description.</div>
              </div>
              <div className="d-flex mb-3">
                <div className="mb-3">
                  <label htmlFor="discount" className="form-label">Discount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="discount"
                    placeholder="Discount"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter discount.</div>
                </div>
                <div className="mb-3" style={{ marginLeft: 10 }}>
                  <label htmlFor="discount_with" className="form-label">Discount With</label>
                  <select
                    className="form-control"
                    id="discount_with"
                    value={discountWith}
                    onChange={(e) => setDiscountWith(e.target.value)}
                    required
                  >
                    <option value="0">Percentage</option>
                    <option value="1">Value Off</option>
                  </select>
                  <div className="invalid-feedback">Please select discount type.</div>
                </div>
              </div>
              <div className="d-flex mb-3">
                <Button variant="secondary" onClick={onHide} >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={loading} style={{ marginLeft: 10 }}>
                  {loading ? 'Save...' : 'Save'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AdminItemsEditModal;
