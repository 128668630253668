import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import useApi from "../service/useApi";
import { fetchDataFromAPI } from "../service/api";
import { getUser } from "../service/constants";
import DatePicker from "react-datepicker";

function SellerCommisionEditModal({ show, onHide, itemId, onSuccess, itemData }) {
  const [customer_id, setCustomer_id] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [priceBand, setPriceBand] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [ItemID, setItemID] = useState("");
  
  const [sales1_id, setSales1_id] = useState("");
  const [commission1, setCommission1] = useState("");
  const [commissionWith, setCommissionWith] = useState("0");

  const [sales2_id, setSales2_id] = useState("");
  const [commission2, setCommission2] = useState("");
  const [commissionWith2, setCommissionWith2] = useState("0");

  const [sales3_id, setSales3_id] = useState("");
  const [commission3, setCommission3] = useState("");
  const [commissionWith3, setCommissionWith3] = useState("0");

  const [sales4_id, setSales4_id] = useState("");
  const [commission4, setCommission4] = useState("");
  const [commissionWith4, setCommissionWith4] = useState("0");

  const [sales5_id, setSales5_id] = useState("");
  const [commission5, setCommission5] = useState("");
  const [commissionWith5, setCommissionWith5] = useState("0");

  const [loading, setLoading] = useState(false);
  const user = JSON.parse(getUser());
  const [ItemData, setitemData] = useState();

  // console.log("ItemDataItemData---->", itemData);
  useEffect(() => {

    if (itemData) {
      setItemID(itemData?.item_id || "");
      setCustomer_id(itemData.customer_id || "");
      setItemCode(itemData?.item?.item_code || "");

      setSales1_id(itemData.sales1_id || "");
      setCommission1(itemData?.sales1_commission || "");
      setCommissionWith(itemData?.sales1_commission_type || "0");

      setSales2_id(itemData.sales2_id || "");
      setCommission2(itemData?.sales2_commission || "");
      setCommissionWith2(itemData?.sales2_commission_type || "0");

      setSales3_id(itemData.sales3_id || "");
      setCommission3(itemData?.sales3_commission || "");
      setCommissionWith3(itemData?.sales3_commission_type || "0");
      
      setSales4_id(itemData.sales4_id || "");
      setCommission4(itemData?.sales4_commission || "");
      setCommissionWith4(itemData?.sales4_commission_type || "0");

      setSales5_id(itemData.sales5_id || "");
      setCommission5(itemData?.sales5_commission || "");
      setCommissionWith5(itemData?.sales5_commission_type || "0");
    }
  }, [itemData]);
  useEffect(() => {
    fetchPricebandList();
    fetchPriceband();
    fetchcustomerList()
  }, []);
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const requestBody = {
      item_id: ItemID,
      customer_id:customer_id,
      sales1_id:sales1_id,
      sales1_commission_type:commissionWith,
      sales1_commission:commission1,

      sales2_id:sales2_id,
      sales2_commission_type:commissionWith2,
      sales2_commission:commission2,

      sales3_id:sales3_id,
      sales3_commission_type:commissionWith3,
      sales3_commission:commission3,

      sales4_id:sales4_id,
      sales4_commission_type:commissionWith4,
      sales4_commission:commission4,

      sales5_id:sales5_id,
      sales5_commission_type:commissionWith5,
      sales5_commission:commission5,

      id: itemData?.id,

    };
    console.log("requestBody", requestBody)
    fetchDataFromAPI("/admin/commission/create", "post", requestBody, user?.token)
      .then((response) => {
        console.log(response,"----responseresponse");
        if (response.status) {
          toast.success("Item updated successfully!");
          onHide();
          if (onSuccess) {
            onSuccess(response);
          }
        } else {
          if (response.data && typeof response.data === "object") {
            Object.keys(response.data).forEach((key) => {
              response.data[key].forEach((message) => {
                toast.error(`${key}: ${message}`);
              });
            });
          } else {
            toast.error(response.message || "Failed to update item.");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("An error occurred while uploading data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPricebandList = (page) => {
    setLoading(true); // Show loader before API call
    fetchDataFromAPI(
      `/admin/item/listing`,
      "get",
      "",
      user?.token
    )
    .then((response) => {
      console.log("response--- list>>", response);
        setLoading(false); // Hide loader after API call completes
        if (response.status === true) {
          const pricebandData = response?.data;
          setitemData(pricebandData)
        } else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error(error);
      });
  };

  const fetchPriceband = (page) => {
    setLoading(true); // Show loader
    fetchDataFromAPI(
      `/admin/listing`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false); // Hide loader
        if (response?.status === true) {
          setPriceBand(response.data)
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error("Error fetching priceband list:", error);
      });
  };
  const fetchcustomerList = (page) => {
    setLoading(true); // Show loader
    fetchDataFromAPI(
      `/admin/customer/listing`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false); // Hide loader
        if (response?.status === true) {
            setCustomer(response.data)
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error("Error fetching priceband list:", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-content p-1">
          <div className="modal-header border-0">
            <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">
              Edit Commission 
            </h5>
            <Button variant="close" onClick={onHide} />
          </div>
          <div className="modal-body">
            <form
              className="needs-validation"
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="mb-3">
                <label htmlFor="sales1_id" className="form-label">Item</label>
                <select
                  value={ItemID}
                  className="form-control"
                  id="sales1_id"
                  onChange={(e) => setItemID(e?.target?.value)}
                  required
                >
                  <option value="">Item</option>
                  {ItemData?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{priceband?.item_code}</option>
                  ))}
                </select>
              </div>

          
              <div className="mb-3">
                <label htmlFor="sales1_id" className="form-label">Customer List</label>
                <select
                  value={customer_id}
                  className="form-control"
                  id="sales1_id"
                  onChange={(e) => setCustomer_id(e?.target?.value)}
                  required
                >
                  <option value="">Customer List</option>
                  {customer?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{priceband?.email}</option>
                  ))}
                </select>
              </div>
             
               {/* 1 */}
              <div className="d-flex mb-3 justify-content-between">
              <div className="mb-3">
                <label htmlFor="sales1_id" className="form-label">Sales List 1</label>
                <select
                  value={sales1_id}
                  className="form-control"
                  id="sales1_id"
                  onChange={(e) => setSales1_id(e?.target?.value)}
                  required
                >
                  <option value="">Sales List</option>
                  {priceBand?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                  ))}
                </select>
              </div>
                <div className="mb-3">
                  <label htmlFor="commission1" className="form-label">Sales Commission 1</label>
                  <input
                    type="number"
                    className="form-control"
                    id="commission1"
                    placeholder="Sales Commission"
                    value={commission1}
                    onChange={(e) => setCommission1(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter Sales Commission 1.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="discount_with" className="form-label">Sales Commission type 1</label>
                  <select
                    className="form-control"
                    id="discount_with"
                    value={commissionWith}
                    onChange={(e) => setCommissionWith(e.target.value)}
                    required
                  >
                    <option value="0">Percentage</option>
                    <option value="1">Value Off</option>
                  </select>
                  <div className="invalid-feedback">Please select Sales Commission type 1.</div>
                </div>
              </div>

                  {/* 2 */}
              <div className="d-flex mb-3 justify-content-between">
              <div className="mb-3">
                <label htmlFor="sales1_id" className="form-label">Sales List 2</label>
                <select
                  value={sales2_id}
                  className="form-control"
                  id="sales2_id"
                  onChange={(e) => setSales2_id(e?.target?.value)}
                  required
                >
                  <option value="">Sales List</option>
                  {priceBand?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                  ))}
                </select>
              </div>
                <div className="mb-3">
                  <label htmlFor="commission1" className="form-label">Sales Commission 2</label>
                  <input
                    type="number"
                    className="form-control"
                    id="commission1"
                    placeholder="Sales Commission"
                    value={commission2}
                    onChange={(e) => setCommission2(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter Sales Commission 2.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="discount_with" className="form-label">Sales Commission type 2</label>
                  <select
                    className="form-control"
                    id="discount_with"
                    value={commissionWith2}
                    onChange={(e) => setCommissionWith2(e.target.value)}
                    required
                  >
                    <option value="0">Percentage</option>
                    <option value="1">Value Off</option>
                  </select>
                  <div className="invalid-feedback">Please select Sales Commission type 2.</div>
                </div>
              </div>

              {/* 3 */}
              <div className="d-flex mb-3 justify-content-between">
              <div className="mb-3">
                <label htmlFor="sales3_id" className="form-label">Sales List 3</label>
                <select
                  value={sales3_id}
                  className="form-control"
                  id="sales3_id"
                  onChange={(e) => setSales3_id(e?.target?.value)}
                  required
                >
                  <option value="">Sales List</option>
                  {priceBand?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                  ))}
                </select>
              </div>
                <div className="mb-3">
                  <label htmlFor="commission1" className="form-label">Sales Commission 3</label>
                  <input
                    type="number"
                    className="form-control"
                    id="commission1"
                    placeholder="Sales Commission"
                    value={commission3}
                    onChange={(e) => setCommission3(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter Sales Commission 3.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="discount_with" className="form-label">Sales Commission type 3</label>
                  <select
                    className="form-control"
                    id="discount_with"
                    value={commissionWith3}
                    onChange={(e) => setCommissionWith3(e.target.value)}
                    required
                  >
                    <option value="0">Percentage</option>
                    <option value="1">Value Off</option>
                  </select>
                  <div className="invalid-feedback">Please select Sales Commission type 3.</div>
                </div>
              </div>

                  {/* 4 */}
              <div className="d-flex mb-3 justify-content-between">
              <div className="mb-3">
                <label htmlFor="sales4_id" className="form-label">Sales List 4</label>
                <select
                  value={sales4_id}
                  className="form-control"
                  id="sales4_id"
                  onChange={(e) => setSales4_id(e?.target?.value)}
                  required
                >
                  <option value="">Sales List</option>
                  {priceBand?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                  ))}
                </select>
              </div>
                <div className="mb-3">
                  <label htmlFor="commission4" className="form-label">Sales Commission 4</label>
                  <input
                    type="number"
                    className="form-control"
                    id="commission4"
                    placeholder="Sales Commission"
                    value={commission4}
                    onChange={(e) => setCommission4(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter Sales Commission 4.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="discount_with" className="form-label">Sales Commission type 4</label>
                  <select
                    className="form-control"
                    id="discount_with"
                    value={commissionWith4}
                    onChange={(e) => setCommissionWith4(e.target.value)}
                    required
                  >
                    <option value="0">Percentage</option>
                    <option value="1">Value Off</option>
                  </select>
                  <div className="invalid-feedback">Please select Sales Commission type 4.</div>
                </div>
              </div>

                  {/* 5 */}
              <div className="d-flex mb-3 justify-content-between">
              <div className="mb-3">
                <label htmlFor="sales5_id" className="form-label">Sales List 5</label>
                <select
                  value={sales5_id}
                  className="form-control"
                  id="sales5_id"
                  onChange={(e) => setSales5_id(e?.target?.value)}
                  required
                >
                  <option value="">Sales List</option>
                  {priceBand?.map((priceband) => (
                   <option key={priceband?.id} value={priceband?.id}>{`${priceband.sales_code} - ${priceband.email} `}</option>
                  ))}
                </select>
              </div>
                <div className="mb-3">
                  <label htmlFor="commission5" className="form-label">Sales Commission 5</label>
                  <input
                    type="number"
                    className="form-control"
                    id="commission5"
                    placeholder="Sales Commission"
                    value={commission5}
                    onChange={(e) => setCommission5(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please enter Sales Commission 5.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="discount_with" className="form-label">Sales Commission type 5</label>
                  <select
                    className="form-control"
                    id="discount_with"
                    value={commissionWith5}
                    onChange={(e) => setCommissionWith5(e.target.value)}
                    required
                  >
                    <option value="0">Percentage</option>
                    <option value="1">Value Off</option>
                  </select>
                  <div className="invalid-feedback">Please select Sales Commission type 5.</div>
                </div>
              </div>



              <div className="d-flex mb-3">
                <Button variant="secondary" onClick={onHide}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  style={{ marginLeft: 10 }}
                >
                  {loading ? "Save..." : "Save"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SellerCommisionEditModal;
