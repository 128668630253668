import React, { useContext, useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { BsTrash } from "react-icons/bs";
import AdminProductModal from '../../commponets/AdminProductModal';
import { fetchDataFromAPI } from '../../service/api';
import { PricebandContext } from '../../context/PricebandContext';
import { getUser } from '../../service/constants';
import './AdminProducts.css';
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';


function AdminProducts() {
  const [modalShow, setModalShow] = useState(false);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { apiAction } = useApi();
  const user = JSON.parse(getUser());
  const { setPricebandData } = useContext(PricebandContext);
  const [totalItems, setTotalItems] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    fetchPricebandList(currentPage);
    
  }, [currentPage]);

  useEffect(() => {
    filterPricebandList();
  }, [searchTerm, allPricebandList]);

  const fetchPricebandList = (page) => {
    setLoading(true);
    fetchDataFromAPI(
      `/admin/item/priceband/list?perPage=10&page=${page}&itemSearch=${searchTerm}`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false);
        if (response.status === true) {
          const pricebandData = response.data.data;
          setAllPricebandList(pricebandData);
          setTotalItems(response?.data?.total);
          setPricebandData(pricebandData);
          localStorage.setItem('allPriceband', JSON.stringify(pricebandData));
          setFilteredPricebandList(pricebandData);
          setTotalPages(response?.data?.last_page);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching priceband list:", error);
      });
  };

  const filterPricebandList = () => {
    if (searchTerm.trim() === '') {
      setFilteredPricebandList(allPricebandList);
    } else {
      const filteredList = allPricebandList.filter(item =>
        item.type.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPricebandList(filteredList);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleModalSuccess = () => {
    fetchPricebandList(currentPage);
  };

  const handleDeleteItem = (itemId) => {
    setLoading(true);
    fetchDataFromAPI(
      `/admin/item/priceband/delete/${itemId}`,
      "delete",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false);
        if (response.status === true) {
          toast.success(response.message);
          fetchPricebandList(currentPage);
          setDeleteModalShow(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setDeleteModalShow(false);
        setDeleteItemId(null);
      });
  };

  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch('https://laravel.snackhouseuk.com/api/admin/item/priceband/import', {
        ...requestOptions,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      });

      setLoading(false);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Upload failed');
      }

      const data = await response.json();      
      if (data.status === true) {
        window.location.reload();
        toast.success(data.message);
      } else if (data.status === false) {
        window.location.reload();
        toast.error(data.error);
      }else {
        toast.error("Invalid format");
      }
      fetchPricebandList(currentPage);
    } catch (error) {
      setLoading(false);
      console.error('Upload error:', error);
      toast.error(error.message || 'invalid format');
    } finally {
      setUploadProgress(0); // Reset progress bar
    }
  };

  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdminProductModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
        <Modal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          size="sm"
          centered
        >
          <Modal.Body>
            <div className="modal-content p-1">
              <div className="modal-header border-0">
                <h5 className="modal-title fs-3 fw-bold">Confirm Delete</h5>
                <Button variant="close" onClick={() => setDeleteModalShow(false)} />
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this item?</p>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    onClick={() => setDeleteModalShow(false)}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    className="ms-2"
                    onClick={() => handleDeleteItem(deleteItemId)}
                    disabled={loading}
                  >
                    {loading ? 'Deleting...' : 'Delete'}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between align-items-center">
                  <div>
                    <h2>Priceband</h2>
                  </div>
                  <div>
                    <input 
                      type="file" 
                      accept=".xls,.xlsx"
                      onChange={handleFileUpload} 
                      className="btn btn-light ml-2 g-13 m-lg-3" 
                      style={{ width: '116px' }} 
                    />
                    <button className="btn btn-primary ml-2" onClick={() => setModalShow(true)}>
                      Add Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {uploadProgress > 0 && (
              <div className="row mb-4">
                <div className="col-12">
                  <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-lg-0">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  
                  <div className="card-body p-0">
                    {loading ? (
                      <div className="text-center py-4">
                        <div className="loader-container" id="loader-container">
                          <div className="loader"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>Type</th>
                              <th />
                              <th>Date & Time</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredPricebandList.length > 0 ? (
                              filteredPricebandList.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.type}</td>
                                  <td />
                                  <td>{format(new Date(item.created_at), "dd MMMM yyyy")}</td>
                                  <td>
                                    <button
                                      className="btn btn-link text-danger"
                                      onClick={() => handleShowDeleteModal(item.id)}
                                    >
                                      <BsTrash />
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td  colSpan="9" className="text-center">No Product found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="border-top d-md-flex justify-content-between align-items-center px-6 py-6">
                    <span>Showing 1 to {allPricebandList.length} of {totalItems} entries</span>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminProducts;
