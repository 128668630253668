// import React, { useContext, useEffect, useState } from "react";
// import Navbar from "../Navbar/Navbar";
// import Header from "../Header/Header";
// import { Link, useLocation, useParams } from "react-router-dom";
// import { BsEye, BsHeart, } from "react-icons/bs";
// import useApi from "../../service/useApi";
// import { toast } from "react-toastify";
// import { useCart } from "../../context/CartContext";
// import "../../Screens/ProductDetails/ProductDetails.css";
// import { PricebandContext } from "../../context/PricebandContext";
// import { getUser } from "../../service/constants";
// import second from '../../assets/images/avatar/coming_soon.jpg'
// import Pagination from "../../commponets/Pagination";
// import Footer from "../Footer/Footer";
// import LoginModal from "../../commponets/LoginModal";
// function CategoriesDetails() {
//   const { nameID, name } = useParams()
//   const location = useLocation();
//   const { prevTitle } = location.state || {};
//   const { apiAction } = useApi();
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [allPricebandList, setAllPricebandList] = useState([]);
//   const [allPricebandListToken, setAllPricebandListToken] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [sorting, setSorting] = useState({ key: "", order: "asc" });
//   const [searchQuery, setSearchQuery] = useState("");
//   const { addToCart, removeFromCart } = useCart();
//   const { categoryID, subCategoryID, subSubCategoryID } = useContext(PricebandContext);
//   const user = JSON.parse(getUser());
//   const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
//   const [selectedSubSubCategoryId, setSelectedSubSubCategoryId] = useState(null);
//   const [selectedCategoryId, setSelectedCategoryId] = useState(null);
//   const { setSubCategoryId, setSubSubCategoryId } = useContext(PricebandContext);
//   const [likedItems, setLikedItems] = useState({});
//   const { setCategoryID, setSubCategoryID, setSubSubCategoryID } =
//     useContext(PricebandContext);
//     const [modalShow, setModalShow] = useState(false);
//   const [totalItem, setTotalItem] = useState()
//   useEffect(() => {
//     fetchItemList();
//     fetchItemList_token()
//   }, [
//     currentPage,
//     sorting,
//     searchQuery,
//     selectedCategoryId,
//     selectedSubCategoryId,
//     selectedSubSubCategoryId,
//     categoryID,
//     subCategoryID,
//     subSubCategoryID
//   ]);

//   const fetchItemList = async () => {
//     setIsLoading(true);
//     try {
//       let apiUrl = `/user/item/list?page=${currentPage}`;
//       if (sorting.order && sorting.key) {
//         apiUrl += `&sorting=${sorting.order}&sortingKey=${sorting.key}`;
//       }
//       if (searchQuery) {
//         apiUrl += `&itemSearch=${searchQuery}`;
//       }
//       if (selectedCategoryId) {
//         apiUrl += `&category_id=${selectedCategoryId}`;
//       }
//       if (selectedSubCategoryId) {
//         apiUrl += `&subcategory_id=${selectedSubCategoryId}`;
//       }
//       if (selectedSubSubCategoryId) {
//         apiUrl += `&sub_subcategory_id=${selectedSubSubCategoryId}`;
//       }
//       if (categoryID) {
//         apiUrl += `&category_id=${categoryID}`;
//       }
//       if (subCategoryID) {
//         apiUrl += `&subcategory_id=${subCategoryID}`;
//       }
//       if (subSubCategoryID) {
//         apiUrl += `&sub_subcategory_id=${subSubCategoryID}`;
//       }
//       if (nameID) {
//         apiUrl += `&category_id=${nameID}`;
//       }
//       const response = await apiAction({
//         url: apiUrl,
//         method: "GET",
//       });
//       if (response.status === true) {
//         const pricebandData = response?.data?.data;
//         setAllPricebandList(pricebandData);
//         setTotalItem(response.data.total)
//         setTotalPages(response.data.last_page);

//       } else {
//         toast.error(response?.message);
//       }
//     } catch (error) {
//       console.log(error, "------error");
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const fetchItemList_token = async () => {
//     setIsLoading(true);
//     try {
//       let apiUrl = `/user/customer/item/list?page=${currentPage}`;
//       if (sorting.order && sorting.key) {
//         apiUrl += `&sorting=${sorting.order}&sortingKey=${sorting.key}`;
//       }
//       if (searchQuery) {
//         apiUrl += `&itemSearch=${searchQuery}`;
//       }
//       if (selectedCategoryId) {
//         apiUrl += `&category_id=${selectedCategoryId}`;
//       }
//       if (selectedSubCategoryId) {
//         apiUrl += `&subcategory_id=${selectedSubCategoryId}`;
//       }
//       if (selectedSubSubCategoryId) {
//         apiUrl += `&sub_subcategory_id=${selectedSubSubCategoryId}`;
//       }
//       if (categoryID) {
//         apiUrl += `&category_id=${categoryID}`;
//       }
//       if (subCategoryID) {
//         apiUrl += `&subcategory_id=${subCategoryID}`;
//       }
//       if (subSubCategoryID) {
//         apiUrl += `&sub_subcategory_id=${subSubCategoryID}`;
//       }
//       if (nameID) {
//         apiUrl += `&category_id=${nameID}`;
//       }
//       const response = await apiAction({
//         url: apiUrl,
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${user?.token}`,
//         },
//       });
//       if (response.status === true) {
//         const pricebandData = response?.data?.data;
//         console.log('pricebandData-----',pricebandData);
//         setTotalItem(response.data.total)
//         setAllPricebandListToken(pricebandData);
//         setTotalPages(response.data.last_page);

//       } else {
//         toast.error(response?.message);
//       }
//     } catch (error) {
//       console.log(error, "------error");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleSortChange = (e) => {
//     const { value } = e.target;
//     const [key, order] = value.split(":");
//     setSorting({ key, order });
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };


//   const handleLike = async (itemID) => {
//     const requestBody = {
//       item_id: itemID,
//     };
//     const requestOptions = {
//       url: "/user/item/like",
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${user?.token}`, // Ensure you include the token
//       },
//       body: JSON.stringify(requestBody),
//     };
//     try {
//       const response = await apiAction(requestOptions);
//       if (response?.status === true && response.data.is_liked == true) {
//         setLikedItems((prevState) => ({
//           ...prevState,
//           [itemID]: !prevState[itemID],
//         }));
//         toast.success(response.message);
//       } else {
//         toast.error(response?.message);
//       }
//     } catch (error) {
//       console.log(error, "------error");
//     }
//   };

//   const ProductCard = ({ product }) => {
//     const handleAddToCart = () => {
//       if (user === null) {
//         setModalShow(true);
//         return;
//       }
//       const productWithQuantity = {
//         ...product,
//         quantity: product.quantity || 1,
//       };
//       addToCart(productWithQuantity);
//       toast.success("Product added to cart");
//     };


//     return (
//       <div className="col">
//         <div className="card card-product cards2">
//           <div className="card-body">
//             <div className="text-center position-relative">
//               <Link
//                 to={`/productList/${product?.id}${product?.item_category[0]?.category_id ? `/${product.item_category[0].category_id}` : ''}`}
//                 className="text-decoration-none text-inherit"
//                 key={product.id}
//               >
//                 <picture>
//                   <img
//                     src={product?.itemImage[0] || second}
//                     alt="Product Image"
//                     loading="lazy"
//                     className="product-image1"
//                     style={{ height: "100px", width: "100px" }}
//                   />
//                 </picture>
//               </Link>
//               <div className="card-product-action">
//                 <Link
//                   to={`/productList/${product?.id}${product?.item_category[0]?.category_id ? `/${product.item_category[0].category_id}` : ''}`}
//                   key={product?.id}
//                   className="btn-action"
//                 >
//                   <BsEye />
//                 </Link>
//                 <a
//                   href="#"
//                   className="btn-action"
//                   data-bs-toggle="tooltip"
//                   data-bs-html="true"
//                   title="Wishlist"
//                   style={{
//                     backgroundColor: likedItems[product?.id]
//                       ? "#FF0000"
//                       : "#dedad9",
//                   }}
//                   onClick={() => handleLike(product?.id)}
//                 >
//                   <BsHeart
//                     style={{
//                       color: likedItems[product?.id]
//                         ? "#fff"
//                         : "#000",
//                     }}
//                   />
//                 </a>
//               </div>
//             </div>
//             <div className="text-small mb-1">
//               <small>{product?.item_code}</small>
//             </div>
//             <h2 className="fs-6">
//               {product?.name}
//             </h2>
//             <div>
//               <small className="text-warning">
//                 {product.rating &&
//                   [...Array(Math.floor(product?.rating))].map((_, index) => (
//                     <i key={index} className="bi bi-star-fill"></i>
//                   ))}
//                 {product?.rating && product?.rating % 1 !== 0 && (
//                   <i className="bi bi-star-half"></i>
//                 )}
//               </small>
//             </div>
          
//             <div className="d-flex justify-content-between align-items-center mt-3">
//               {/* <div>
//                 <span className="text-dark">
//                   {product?.currency} {product?.price}
//                 </span>
//               </div> */}
//               <div>
//                 <button
//                   className="btn btn-primary btn-sm"
//                   onClick={() => handleAddToCart(product)}
//                 >
//                   Sign in for price
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   const ProductCardToken = ({ product }) => {
//     const handleAddToCart = () => {
//       addToCart(product);
//       toast.success("Product added to cart");
//     };
//     return (
//       <div className="col">
//         <div className="card card-product cards2">
//           <div className="card-body">
//             <div className="text-center position-relative">
//               <Link
//                 to={`/productList/${product?.id}${product?.item_category[0]?.category_id ? `/${product.item_category[0].category_id}` : ''}`}
//                 className="text-decoration-none text-inherit"
//                 key={product?.id}
//               >
//                 <picture>
//                   <img
//                     src={product?.itemImage[0] || second}
//                     alt="Product Image"
//                     loading="lazy"
//                     className="product-image1"
//                     style={{ height: "100px", width: "100px" }}
//                   />
//                 </picture>
//               </Link>
//               <div className="card-product-action">
//                 <Link
//                   to={`/productList/${product?.id}${product?.item_category[0]?.category_id ? `/${product.item_category[0].category_id}` : ''}`}
//                   //  className="text-decoration-none text-inherit"
//                   key={product?.id}
//                   className="btn-action"
//                 >
//                   <BsEye />
//                 </Link>
//                 <a
//                   href="#"
//                   className="btn-action"
//                   data-bs-toggle="tooltip"
//                   data-bs-html="true"
//                   title="Wishlist"
//                   style={{
//                     backgroundColor: likedItems[product?.id]
//                       ? "#FF0000"
//                       : "#dedad9",
//                   }}
//                   onClick={() => handleLike(product?.id)}
//                 >
//                   <BsHeart
//                     style={{
//                       color: likedItems[product?.id]
//                         ? "#fff"
//                         : "#000",
//                     }}
//                   />
//                 </a>
//               </div>
//             </div>
//             <div className="text-small mb-1 mt-6">
//               <small>{product?.item_code}</small>
//             </div>
//             <h2 className="fs-6">
//               {product?.name}
//             </h2>
//             <div>
//               <small className="text-warning">
//                 {product?.rating &&
//                   [...Array(Math.floor(product?.rating))].map((_, index) => (
//                     <i key={index} className="bi bi-star-fill"></i>
//                   ))}
//                 {product?.rating && product?.rating % 1 !== 0 && (
//                   <i className="bi bi-star-half"></i>
//                 )}
//               </small>
//               <span className="text-muted small">{product?.numReviews}</span>
//             </div>
//             <div className="d-flex justify-content-between align-items-center mt-3">
//               <div>
//                 <span className="text-dark">
//                   {product?.currency} {product?.price}
//                 </span>
//               </div>
//               <div>
//                 <button
//                   className="btn btn-primary btn-sm"
//                   onClick={handleAddToCart}
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="16"
//                     height="16"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     stroke="currentColor"
//                     strokeWidth="2"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     className="feather feather-plus"
//                   >
//                     <line x1="12" y1="5" x2="12" y2="19"></line>
//                     <line x1="5" y1="12" x2="19" y2="12"></line>
//                   </svg>
//                   Add
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <>
//       <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
//       <Header />
//       <Navbar />
//       <main>
//         <div className="mt-8 mb-lg-14 mb-8">
//           <div className="container-fluid min-vh-100 justify-content-center align-items-center">
//             <div className="container">
//               <div className="row gx-10">
//                 {/* <section className="col-lg-9 col-md-12"> */}
//                 <div className="row gx-4 gx-lg-5 justify-content-center">
//                   <div className="col-lg-9">
//                   <nav aria-label="breadcrumb">
//                       <ol className="breadcrumb mb-0">
//                         <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
//                         <li className="breadcrumb-item active" aria-current="page">{name}</li>
//                       </ol>
//                     </nav>
//                     <div className="card mb-4 bg-light border-0 mt-6">
//                       <div className="card-body p-4 p-lg-9">
//                         <h2 className="mb-0 fs-2">{name}</h2>
//                       </div>
//                     </div>
//                     <div className="d-lg-flex justify-content-between align-items-center mb-4">
//                       <div>
//                         <p className="mb-0">
//                           <span className="text-dark">
//                             {allPricebandList?.length} of {totalItem}{" "}
//                           </span>
//                           Products found
//                         </p>
//                       </div>
//                       <div className="d-md-flex align-items-center">
//                         <div className="me-2 flex-grow-1">
//                           <select className="form-select" onChange={handleSortChange}>
//                             <option value="name:asc">Sort by: Name (A-Z)</option>
//                             <option value="name:desc">Sort by: Name (Z-A)</option>
//                             <option value="price:asc">Sort by: Price (Low to High)</option>
//                             <option value="price:desc">Sort by: Price (High to Low)</option>
//                           </select>
//                         </div>
//                         <div>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Search..."
//                             onChange={handleSearchChange}
//                           />
//                         </div>
//                       </div>
//                     </div>

//                     {isLoading ? (
//                       <div className="text-center mt-5">
//                         <div className="spinner-border" role="status">
//                           <span className="visually-hidden">Loading...</span>
//                         </div>
//                       </div>
//                     ) : (
//                       <>
//                         {allPricebandList?.length === 0 ? (
//                           <div className="text-center mt-5">
//                             <h2>No Product</h2>
//                           </div>
//                         ) : (
//                           <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-4">
//                             {user?.token == null
//                               ? allPricebandList?.map((product) => (
//                                 <div key={product?.id} className="col">
//                                   <ProductCard product={product} />
//                                 </div>
//                               ))
//                               : allPricebandListToken?.map((product) => (
//                                 <div key={product?.id} className="col">
//                                   <ProductCardToken product={product} />
//                                 </div>
//                               ))}
//                           </div>
//                         )}
//                       </>
//                     )}

//                     <div className="row mt-4">
//                       <div className="col">
//                         <Pagination
//                           currentPage={currentPage}
//                           totalPages={totalPages}
//                           onPageChange={setCurrentPage}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//       <Footer />
//     </>
//   );
// }

// export default CategoriesDetails;

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import { Link, useLocation, useParams } from "react-router-dom";
import { BsEye, BsHeart } from "react-icons/bs";
import useApi from "../../service/useApi";
import { toast } from "react-toastify";
import { useCart } from "../../context/CartContext";
import "../../Screens/ProductDetails/ProductDetails.css";
import { PricebandContext } from "../../context/PricebandContext";
import { getUser } from "../../service/constants";
import second from "../../assets/images/avatar/coming_soon.jpg";
import Pagination from "../../commponets/Pagination";
import Footer from "../Footer/Footer";
import LoginModal from "../../commponets/LoginModal";
function CategoriesDetails() {
  const { nameID, name } = useParams();
  const { state } = useLocation();
  const { apiAction } = useApi();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [allPricebandListToken, setAllPricebandListToken] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState({ key: "", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const { addToCart, removeFromCart } = useCart();
  const { categoryID, subCategoryID, subSubCategoryID } = useContext(PricebandContext);
  const { categoryName, subCategoryName, subSubCategoryName } = useContext(PricebandContext);
  const user = JSON.parse(getUser());
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedSubSubCategoryId, setSelectedSubSubCategoryId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [likedItems, setLikedItems] = useState({});
  const [modalShow, setModalShow] = useState(false);

  const [totalItem, setTotalItem] = useState();
  useEffect(() => {
    if (allPricebandList.length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [allPricebandList]);
  useEffect(() => {
    fetchItemList();
    fetchItemList_token();
  }, [
    currentPage,
    sorting,
    searchQuery,
    selectedCategoryId,
    selectedSubCategoryId,
    selectedSubSubCategoryId,
    categoryID,
    subCategoryID,
    subSubCategoryID,
    pageSize
  ]);

  const fetchItemList = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `/user/item/list?`;
      if (sorting.order && sorting.key) {
        apiUrl += `&sorting=${sorting.order}&sortingKey=${sorting.key}`;
      }
      if (searchQuery) {
        apiUrl += `&itemSearch=${searchQuery}`;
      }
      if (selectedCategoryId) {
        apiUrl += `&category_id=${selectedCategoryId}`;
      }
      if (selectedSubCategoryId) {
        apiUrl += `&subcategory_id=${selectedSubCategoryId}`;
      }
      if (selectedSubSubCategoryId) {
        apiUrl += `&sub_subcategory_id=${selectedSubSubCategoryId}`;
      }
      if (categoryID) {
        apiUrl += `&category_id=${categoryID}`;
      }
      if (subCategoryID) {
        apiUrl += `&subcategory_id=${subCategoryID}`;
      }
      if (subSubCategoryID) {
        apiUrl += `&sub_subcategory_id=${subSubCategoryID}`;
      }
      if (nameID) {
        apiUrl += `&category_id=${nameID}`;
      }

      if (pageSize) {
        apiUrl += `&perPage=${pageSize}`;
      }
      const response = await apiAction({
        url: apiUrl,
        method: "GET",
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setAllPricebandList(pricebandData);
        setTotalItem(response.data.total);
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };
  const fetchItemList_token = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `/user/customer/item/list?`;
      if (sorting.order && sorting.key) {
        apiUrl += `&sorting=${sorting.order}&sortingKey=${sorting.key}`;
      }
      if (state?.search) {
        apiUrl += `&itemSearch=${state?.search}`;
      }

      if (searchQuery) {
        apiUrl += `&itemSearch=${searchQuery}`;
      }
      if (selectedCategoryId) {
        apiUrl += `&category_id=${selectedCategoryId}`;
      }
      if (selectedSubCategoryId) {
        apiUrl += `&subcategory_id=${selectedSubCategoryId}`;
      }
      if (selectedSubSubCategoryId) {
        apiUrl += `&sub_subcategory_id=${selectedSubSubCategoryId}`;
      }
      if (categoryID) {
        apiUrl += `&category_id=${categoryID}`;
      }
      if (subCategoryID) {
        apiUrl += `&subcategory_id=${subCategoryID}`;
      }
      if (subSubCategoryID) {
        apiUrl += `&sub_subcategory_id=${subSubCategoryID}`;
      }
      if (nameID) {
        apiUrl += `&category_id=${nameID}`;
      }

      if (pageSize) {
        apiUrl += `&perPage=${pageSize}`;
      }
      const response = await apiAction({
        url: apiUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setTotalItem(response.data.total);
        setAllPricebandListToken(pricebandData);
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    const [key, order] = value.split(":");
    setSorting({ key, order });
  };

  useEffect(() => {
    if (state?.search) {
      setSearchQuery(state?.search);
    }
  }, [state?.search]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLike = async (itemID) => {
    const requestBody = {
      item_id: itemID,
    };
    const requestOptions = {
      url: "/user/item/like",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`, // Ensure you include the token
      },
      body: JSON.stringify(requestBody),
    };
    try {
      const response = await apiAction(requestOptions);
      if (response?.status === true && response.data.is_liked == true) {
        setLikedItems((prevState) => ({
          ...prevState,
          [itemID]: !prevState[itemID],
        }));
        toast.success(response.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    }
  };

  const ProductCard = ({ product }) => {
    const handleAddToCart = () => {
      if (user === null) {
        setModalShow(true);
        return;
      }
      const productWithQuantity = {
        ...product,
        quantity: product.quantity || 1,
      };
      addToCart(productWithQuantity);
      toast.success("Product added to cart");
    };

    return (
      <div className="col">
        <div className="card card-product cards2">
          <div className="card-body">
            <div className="text-center position-relative">
              <Link
                to={`/productList/${product?.id}${product?.item_category[0]?.category_id
                    ? `/${product.item_category[0].category_id}`
                    : ""
                  }`}
                className="text-decoration-none text-inherit"
                key={product.id}
              >
                <picture>
                  <img
                    src={product?.itemImage[0] || second}
                    alt="Product Image"
                    loading="lazy"
                    className="product-image1"
                    style={{ height: "100px", width: "100px" }}
                  />
                </picture>
              </Link>
              <div className="card-product-action">
                <Link
                  to={`/productList/${product?.id}${product?.item_category[0]?.category_id
                      ? `/${product.item_category[0].category_id}`
                      : ""
                    }`}
                  key={product?.id}
                  className="btn-action"
                >
                  <BsEye />
                </Link>
                <a
                  href="#"
                  className="btn-action"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  title="Wishlist"
                  style={{
                    backgroundColor: likedItems[product?.id]
                      ? "#FF0000"
                      : "#dedad9",
                  }}
                  onClick={() => handleLike(product?.id)}
                >
                  <BsHeart
                    style={{
                      color: likedItems[product?.id] ? "#fff" : "#000",
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="text-small mb-1 mt-1">
              <small>{product?.item_code}</small>
            </div>
            <small className="fs-6">{product?.name}</small>
            <div>
              <small className="text-warning">
                {product.rating &&
                  [...Array(Math.floor(product?.rating))].map((_, index) => (
                    <i key={index} className="bi bi-star-fill"></i>
                  ))}
                {product?.rating && product?.rating % 1 !== 0 && (
                  <i className="bi bi-star-half"></i>
                )}
              </small>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              {/* <div>
                <span className="text-dark">
                  {product?.currency} {product?.price}
                </span>
              </div> */}
              <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleAddToCart(product)}
                >
                  Sign in for price
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ProductCardToken = ({ product }) => {
    const handleAddToCart = () => {
      addToCart(product);
      toast.success("Product added to cart");
    };
    return (
      <div className="col">
        <div className="card card-product cards2">
          <div className="card-body">
            <div className="text-center position-relative">
              <Link
                to={`/productList/${product?.id}${product?.item_category[0]?.category_id
                    ? `/${product.item_category[0].category_id}`
                    : ""
                  }`}
                className="text-decoration-none text-inherit"
                key={product?.id}
              >
                <picture>
                  <img
                    src={product?.itemImage[0] || second}
                    alt="Product Image"
                    loading="lazy"
                    className="product-image1"
                    style={{ height: "100px", width: "100px" }}
                  />
                </picture>
              </Link>
              <div className="card-product-action">
                <Link
                  to={`/productList/${product?.id}${product?.item_category[0]?.category_id
                      ? `/${product.item_category[0].category_id}`
                      : ""
                    }`}
                  key={product?.id}
                  className="btn-action"
                >
                  <BsEye />
                </Link>
                <a
                  href="#"
                  className="btn-action"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  title="Wishlist"
                  style={{
                    backgroundColor: likedItems[product?.id]
                      ? "#FF0000"
                      : "#dedad9",
                  }}
                  onClick={() => handleLike(product?.id)}
                >
                  <BsHeart
                    style={{
                      color: likedItems[product?.id] ? "#fff" : "#000",
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="text-small mb-1 mt-1">
              <small>{product?.item_code}</small>
            </div>
            <h2 className="fs-6">{product?.name}</h2>
            <div>
              <small className="text-warning">
                {product?.rating &&
                  [...Array(Math.floor(product?.rating))].map((_, index) => (
                    <i key={index} className="bi bi-star-fill"></i>
                  ))}
                {product?.rating && product?.rating % 1 !== 0 && (
                  <i className="bi bi-star-half"></i>
                )}
              </small>
              <span className="text-muted small">{product?.numReviews}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div>
                <span className="text-dark">
                  {product?.currency} {product?.price}
                </span>
              </div>
              <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleAddToCart}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      <Header />
      <Navbar />
      <main>
        <div className="mt-8 mb-lg-14 mb-8">
          <div className="container-fluid min-vh-100 justify-content-center align-items-center">
            <div className="container">
              <div className="row gx-10">
                {/* <section className="col-lg-9 col-md-12"> */}
                <div className="row gx-4 gx-lg-5 justify-content-center">
                  <div className="col-lg-9">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{name}</li>
                      </ol>
                    </nav>
                    <div className="card mb-4 bg-light border-0 mt-6">

                      <div className="card-body p-4 p-lg-9">
                        <h2 className="mb-0 fs-2">
                          {categoryName ||
                            subCategoryName ||
                            subSubCategoryName ||
                            "Items"}
                        </h2>
                      </div>
                    </div>
                    <div className="d-lg-flex justify-content-between align-items-center mb-4">
                      <div>
                        <p className="mb-0">
                          <span className="text-dark">
                           
                            {allPricebandList?.length} of {totalItem}{" "}
                          </span>
                          Products found
                        </p>
                      </div>
                      <div className="d-md-flex align-items-center">
                        <div className="me-2 flex-grow-1">
                          <select
                            className="form-select"
                            onChange={handleSortChange}
                          >
                            <option value="name:asc">
                              Sort by: Name (A-Z)
                            </option>
                            <option value="name:desc">
                              Sort by: Name (Z-A)
                            </option>
                            <option value="price:asc">
                              Sort by: Price (Low to High)
                            </option>
                            <option value="price:desc">
                              Sort by: Price (High to Low)
                            </option>
                          </select>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={handleSearchChange}
                          />
                        </div>
                        <div className='ms-2'>

                          <select
                            id="pageSize"
                            className="form-select"
                            name="pageSize"

                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                          >
                            <option value="10">12</option>
                            <option value="20">20</option>
                            <option value="48">48</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {isLoading ? (
                      <div className="text-center mt-5">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {allPricebandList?.length === 0 ? (
                          <div className="text-center mt-5">
                            <h2>No Product</h2>
                          </div>
                        ) : (
                          <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-4">
                            {user?.token == null
                              ? allPricebandList?.map((product) => (
                                <div key={product?.id} className="col">
                                  <ProductCard product={product} />
                                </div>
                              ))
                              : allPricebandListToken?.map((product) => (
                                <div key={product?.id} className="col">
                                  <ProductCardToken product={product} />
                                </div>
                              ))}
                          </div>
                        )}
                      </>
                    )}

                    <div className="row mt-4">
                      <div className="col">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default CategoriesDetails;
