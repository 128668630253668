import React, { useState } from 'react';
import signin from "../../assets/images/banner/size332x332.png";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../../service/api';
import { saveUserLocally } from '../../service/constants';

function SellerLogin({ onLogin }) {
    const navigate = useNavigate();
    const [salesCode, setSalesCode] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleSignIn = async (e) => {
        e.preventDefault();

        // Reset errors
        setEmailError('');
        setPasswordError('');

        // Form validation
        let isValid = true;
        if (!salesCode) {
            setEmailError('Sales code is required');
            isValid = false;
        }
        if (!password ) {
            setPasswordError('Password is required');
            isValid = false;
        }
        if (!isValid) {
            return;
        }

        const body = {
            sales_code: salesCode,
            password: password,
        };

        fetchDataFromAPI("/sale/login", "post", body, "")
            .then((res) => {
                if (res.status == true && res) {
                    saveUserLocally(JSON.stringify(res?.data));
                    console.log('res --->seles Login res', res);
                    toast.success("Login successful!");
                    navigate("/sellerDashboard");
                } else {
                    toast.error(res.data)
                    if (res?.errors?.sales_code) {
                        setEmailError(res.errors.sales_code[0]);
                    }
                    if (res?.errors?.password) {
                        setPasswordError(res.errors.password[0]);
                    }
                    toast.error(res?.errors.sales_code[0] || res?.errors.password[0]  || "Login failed");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    return (
        <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
            <section className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <img src={signin} alt="Sign In" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 offset-lg-1">
                        <div className="mb-4">
                            <h1 className="h2 fw-bold mb-1">Sign in to Snackhouseuk</h1>
                            <p>Welcome back to Snackhouseuk! Enter your salesCode to get started.</p>
                        </div>
                        <form className="needs-validation" noValidate="" onSubmit={handleSignIn}>
                            <div className="mb-3">
                                <label htmlFor="sales_code" className="form-label">Sales code</label>
                                <input
                                    value={salesCode}
                                    onChange={(e) => setSalesCode(e.target.value)}
                                    type="text"
                                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                    id="sales_code"
                                    placeholder="Seller"
                                    required=""
                                />
                                {emailError && <div className="invalid-feedback">{emailError}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="formSigninPassword" className="form-label">Password</label>
                                <input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                    id="formSigninPassword"
                                    placeholder="*****"
                                    required=""
                                />
                                {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="form-check">
                                </div>
                                <div>
                                    Forgot password?
                                    <Link to={'/sales-forgot-password'}>
                                    <a href="forgot-password.html">Reset It</a>
                                    </Link>
                                </div>
                            </div>
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary">Sign In</button>
                            </div>
                            <div className="mt-3 text-center">
                                Don't have an account?
                                <Link to="/sellerSingUp">
                                    <span> Sign Up</span>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default SellerLogin;
