import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import useApi from '../service/useApi';
import { getUser } from '../service/constants';

function AdminEditTax({ show, onHide, itemData, onSuccess }) {
    const [taxRate, setTaxRate] = useState('');
    const [loading, setLoading] = useState(false);
    const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
    // console.log(itemData, "-itemDataitemDataitemData");
    const user = JSON.parse(getUser());
    useEffect(() => {
        if (itemData) {
            setTaxRate(itemData?.tax_rate || '');
        }
    }, [itemData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${user?.token}`);

            const formdata = new FormData();
            formdata.append("tax_rate", taxRate);
            formdata.append("id", itemData?.id);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            const response = await fetch(`https://laravel.snackhouseuk.com/api/admin/tax/create`, requestOptions);
            const result = await response.json();
            if (response.ok) {
                toast.success(result?.message);
                onHide();
                if (onSuccess) {
                    onSuccess(result);
                }
            } else {
                toast.error(result.message || 'Failed to update subcategory.');
            }
        } catch (error) {
            console.error('Failed to update subcategory:', error);
            toast.error('Failed to update subcategory.');
        } finally {
            setLoading(false); // Reset loading state regardless of success or failure
        }
    };

    
    return (
        <Modal show={show} onHide={onHide} size="sm" centered>
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold">Edit Taxes</h5>
                        <Button variant="close" onClick={onHide} />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Tax rate</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="name"
                                    placeholder="Enter subcategory name"
                                    value={taxRate}
                                    onChange={(e) => setTaxRate(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    onClick={onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="ms-2"
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminEditTax;
