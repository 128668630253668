import React, { useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../../service/api';
import { getUser } from '../../service/constants';
import AdminImportSalesModal from '../../commponets/AdminImportSalesModal';
import { BsTrash } from "react-icons/bs";
import Pagination from '../../commponets/Pagination';
import DeleteConfirmationModal from '../../commponets/DeleteConfirmationModal';
import ProgressBar from '../../commponets/Processing/Processing';
import { Button } from 'react-bootstrap';
function AdminImportSales() {
  const [searchTerm, setSearchTerm] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [priceBandID, setPriceBandID] = useState([]);
  const user = JSON.parse(getUser());
  const [loading, setLoading] = useState(false);
  const [aItemList, setAllItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fetchPricebandList = () => {
    setLoading(true);
    fetchDataFromAPI(
      `/admin/sales/listing?page=${currentPage}&searchKey=${searchTerm}`,
      "get",
      "",
      user?.token)
      .then((response) => {
        setLoading(false);
        if (response.status === true) {
          const pricebandData = response?.data?.data;
          setAllItemList(pricebandData);
          setTotalItems(response?.data?.total)
          setTotalPages(response?.data?.last_page);
          setCurrentPage(response?.data?.current_page);
        } else {
          // toast.error(response?.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Set loading to false on error
        console.log(error, "---error");
      });
  };

  useEffect(() => {
    fetchPricebandList();
  }, [currentPage]);

  useEffect(() => {
    filterPricebandList();
    fetchPricebandIdList()
  }, [searchTerm, aItemList]);

  const filterPricebandList = () => {
    if (searchTerm.trim() === '') {
      setFilteredPricebandList(aItemList);
    } else {
      const filteredList = aItemList.filter(item =>
        item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || item?.sales_code?.toLowerCase().includes(searchTerm.toLowerCase()) || item?.email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPricebandList(filteredList);
    }
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch('https://laravel.snackhouseuk.com/api/admin/sales/import', {
        ...requestOptions,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      });

      setLoading(false);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Upload failed');
      }

      const data = await response.json();      
      if (data.status === true) {
        window.location.reload();
        toast.success(data.message);
      } else if (data.status === false) {
        window.location.reload();
        toast.error(data.error);
      }else {
        toast.error("Invalid format");
      }
      fetchPricebandList(currentPage);
    } catch (error) {
      setLoading(false);
      console.error('Upload error:', error);
      toast.error(error.message || 'invalid format');
    } finally {
      setUploadProgress(0); // Reset progress bar
    }
  };



  const handleModalSuccess = () => {
    fetchPricebandList();
  };

  const fetchPricebandIdList = () => {
    setLoading(true); // Show loader
    fetchDataFromAPI(
      `/admin/item/priceband/list`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false); // Hide loader
        if (response.status === true) {
          setPriceBandID(response?.data)
        } else {
          // toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error("Error fetching priceband list:", error);
      });
  };

  const handleDeleteItem = (itemId) => {
    setLoading(true); // Show loader for delete action
    fetchDataFromAPI(
      `/admin/sales/delete/${itemId}?`,
      "delete",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false); // Hide loader after delete action
        if (response.status === true) {
          toast.success(response.message);
          fetchPricebandList(currentPage);
          setDeleteModalShow(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader
        setDeleteModalShow(false);
        setDeleteItemId(null);
        console.error("Error deleting item:", error);
      });
  };

  const handleShowDeleteModal = (itemId) => {
    setDeleteModalShow(true);
    setDeleteItemId(itemId);
  };
  return (
    <>
      <AdminProfile />
      <AdminImportSalesModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
      <div className="main-wrapper">
        <AdminNavbar />
        <DeleteConfirmationModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => handleDeleteItem(deleteItemId)}
          loading={loading}
        />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                    <h2>Import sales</h2>
                  </div>
                  <div>
                    <input type="file" onChange={handleFileChange} className="btn btn-light ml-2 g-13 m-2" style={{ width: '116px' }} />
                    <a href="#" role="button" className="btn btn-primary" onClick={() => setModalShow(true)}>
                      Add New
                    </a>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {uploadProgress > 0 && (
              <div className="row mb-4">
                <div className="col-12">
                  <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {loading ? (
                      <div className="text-center py-5">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>Sales Code</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>contact</th>
                              <th>contact2</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredPricebandList.length > 0 ? (
                              filteredPricebandList?.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    {item?.sales_code}
                                  </td>
                                  <td>{item?.name}</td>
                                  <td>
                                    {item?.email}
                                  </td>
                                  <td>
                                    {item?.contact}
                                  </td>
                                  <td>
                                    {item?.contact2}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-link text-danger"
                                      onClick={() => handleShowDeleteModal(item?.id)}>
                                      <BsTrash />
                                    </button>
                                  </td>
                                </tr>
                              ))
                            )
                              : (<tr>
                                <td colSpan="9" className="text-center">No Import sales found.</td>
                              </tr>
                              )
                            }

                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="border-top d-flex justify-content-between align-items-md-center px-6 py-6 flex-md-row flex-column gap-4">
                    <span>Showing {currentPage} to {aItemList?.length} of {totalItems} entries</span>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminImportSales;
