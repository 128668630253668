import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../../assets/libs/tiny-slider/dist/tiny-slider.css';
import '../../assets/libs/slick-carousel/slick/slick.css';
import '../../assets/libs/slick-carousel/slick/slick-theme.css';
import '../../assets/libs/simplebar/dist/simplebar.min.css';
import '../../assets/css/theme.min.css';
import "../../../src/Screens/AdminNavbar/AdminNavbar.css";
import Logo from "../../../src/assets/images/logo/116x116.png";
import { BsCart, BsListUl, BsBag, BsHouseGearFill, BsPersonVideo3, BsCardChecklist, BsReceiptCutoff, BsPercent, BsFillStarFill, BsCartCheckFill, BsFillHouseDoorFill ,BsPeopleFill ,BsPersonRaisedHand , BsTelephoneFill ,BsCardImage} from "react-icons/bs";

function AdminNavbar({ isSidebarVisible }) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  const handleNavLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <nav className={`navbar-vertical-nav d-xl-block ${isSidebarVisible ? 'd-block' : 'd-none'}`}>
      <div className="navbar-vertical">
        <div className="d-flex justify-content-center align-items-center px-4 py-5">
          <a href="" className="navbar-brand">
            <img src={Logo} alt="FreshCart Logo" />
          </a>
        </div>
        <div className="navbar-vertical-content flex-grow-1" data-simplebar="">
          <ul className="navbar-nav flex-column" id="sideNavbar">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/adminDashboard' ? 'active' : ''}`}
                to="/adminDashboard"
                onClick={() => handleNavLinkClick('/adminDashboard')}>
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-house"></i><BsFillHouseDoorFill />
                  </span>
                  <span className="nav-link-text">Dashboard</span>
                </div>
              </Link>
            </li>
            <li className="nav-item mt-6 mb-3">
              <span className="nav-label">Store Managements</span>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/image' ? 'active' : ''}`}
                to="/image"
                onClick={() => handleNavLinkClick('/image')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-cart"></i><BsCardImage /></span>
                  <span className="nav-link-text">Manage Files
                  </span>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/customer-List' ? 'active' : ''}`}
                to="/customer-List"
                onClick={() => handleNavLinkClick('/customer-List')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-cart"></i><BsPeopleFill /></span>
                  <span className="nav-link-text">Customer
                  </span>
                </div>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/Taxes' ? 'active' : ''}`}
                to="/Taxes"
                onClick={() => handleNavLinkClick('/Taxes')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <i className="bi bi-people"></i><BsPercent />
                  </span>
                  <span className="nav-link-text">Tax</span>
                </div>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/products' ? 'active' : ''}`}
                to="/products"
                onClick={() => handleNavLinkClick('/products')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-cart"></i><BsCart /></span>
                  <span className="nav-link-text">priceband</span>
                </div>
              </Link>
            </li>



            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#navCategoriesOrders" aria-expanded="false" aria-controls="navCategoriesOrders">
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><BsBag /></span>
                  <span className="nav-link-text">All categories</span>
                </div>
              </a>
              <div id="navCategoriesOrders" className="collapse" data-bs-parent="#sideNavbar">
                <ul className="nav flex-column">
                  <Link
                    className={`nav-link ${location.pathname === '/category' ? 'active' : ''}`}
                    to="/category"
                    onClick={() => handleNavLinkClick('/category')}
                  >
                    <li className="nav-item">
                      <a className="nav-link" href="">categories</a>
                    </li>
                  </Link>
                  <Link className="nav-link" to="/subcategory">
                    <li className="nav-item"><a className="nav-link" href="">Subcategories</a></li>
                  </Link>
                  <Link className="nav-link" to="/sub-subcategory">
                    <li className="nav-item"><a className="nav-link" href="">SubSubcategories</a></li>
                  </Link>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/items' ? 'active' : ''}`}
                to="/items"
                onClick={() => handleNavLinkClick('/items')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsListUl /></span>
                  <span className="nav-link-text">Items</span>
                </div>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/Popular' ? 'active' : ''}`}
                to="/Popular"
                onClick={() => handleNavLinkClick('/Popular')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsFillStarFill /></span>
                  <span className="nav-link-text">Popular items</span>
                </div>
              </Link>
            </li>


            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/Item-Categories' ? 'active' : ''}`}
                to="/Item-Categories"
                onClick={() => handleNavLinkClick('/Item-Categories')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <i className="bi bi-shop"></i><BsHouseGearFill />
                  </span><span className="nav-link-text">Item Categories</span>
                </div>
              </Link>
            </li>


            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/Item-Priceband' ? 'active' : ''}`}
                to="/Item-Priceband"
                onClick={() => handleNavLinkClick('/Item-Priceband')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsListUl /></span>
                  <span className="nav-link-text">Item Priceband</span>
                </div>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/AdminOrder' ? 'active' : ''}`}
                to="/AdminOrder"
                onClick={() => handleNavLinkClick('/AdminOrder')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsPersonVideo3 /></span>
                  <span className="nav-link-text">Customer's Orders</span>
                </div>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/AdminSalesOrderList' ? 'active' : ''}`}
                to="/AdminSalesOrderList"
                onClick={() => handleNavLinkClick('/AdminSalesOrderList')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsCardChecklist /></span>
                  <span className="nav-link-text">Sale's Orders</span>
                </div>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/AdminImportSales' ? 'active' : ''}`}
                to="/AdminImportSales"
                onClick={() => handleNavLinkClick('/AdminImportSales')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsReceiptCutoff /></span>
                  <span className="nav-link-text">Import sales</span>
                </div>
              </Link>
            </li>


            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/commission-Allocation' ? 'active' : ''}`}
                to="/commission-Allocation"
                onClick={() => handleNavLinkClick('/commission-Allocation')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-cart"></i><BsPercent /></span>
                  <span className="nav-link-text">Commission Allocation
                  </span>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/customer-Allocation' ? 'active' : ''}`}
                to="/customer-Allocation"
                onClick={() => handleNavLinkClick('/customer-Allocation')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-cart"></i><BsPersonRaisedHand /></span>
                  <span className="nav-link-text">Customer Allocation
                  </span>
                </div>
              </Link>
            </li>


            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/contact-List' ? 'active' : ''}`}
                to="/contact-List"
                onClick={() => handleNavLinkClick('/contact-List')}
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon"><i className="bi bi-list-task"></i><BsTelephoneFill /></span>
                  <span className="nav-link-text">Contact</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default AdminNavbar;
