import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LoginModal from './LoginModal'; // Ensure correct path
import { useNavigate } from 'react-router-dom';
import useApi from '../../src/service/useApi';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../service/api';
import { saveUserLocally } from '../service/constants';

function RegisterModal(props) {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const [emails, setEmailss] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [phone2, setPhone2] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [pincode, setPincode] = useState('');
  const [errors, setErrors] = useState({});

  const handleSignUp = (e) => {
    // setLoading(true);
    e.preventDefault();
    const requestBody = {
      name: name,
      email: emails,
      phone: phone,
      phone2: phone2,
      address: address,
      pincode: pincode,
      password: password,
    };
    console.log("requestBody", requestBody);
    fetchDataFromAPI("/user/register", "post", requestBody)
      .then((response) => {
        console.log(response, "----responseresponse");
        if (response.status) {
          toast.success(response?.message);
          saveUserLocally(JSON.stringify(response?.data));
          props.onHide();
          navigate("/");
        } else {
          if (response.errors && Array.isArray(response.errors)) {
            response.errors.forEach((error) => {
              toast.error(error);
            });
          } else {
            toast.error(response.message || "Failed to update item.");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("An error occurred while uploading data");
      })
      .finally(() => {
        // setLoading(false);
      });
  };


  return (
    <>
    {/* <LoginModal show={modalShow} onHide={() => setModalShow(false)}/> */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Sign Up</h5>
            </div>
            <div className="modal-body">
              <form className="needs-validation" noValidate onSubmit={handleSignUp}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input
                    className="form-control"
                    id="name"
                    placeholder="Enter Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  {errors['The name field is required.'] && (
                    <div className="invalid-feedback d-block">Please enter name.</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Email address"
                    required
                    value={emails}
                    onChange={(e) => setEmailss(e.target.value)}
                  />
                  {errors['The email field is required.'] && (
                    <div className="invalid-feedback d-block">Please enter email.</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">Address</label>
                  <input
                    className="form-control"
                    id="address"
                    placeholder="Enter Address"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  {errors['The address field is required.'] && (
                    <div className="invalid-feedback d-block">Please enter address.</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="pincode" className="form-label">Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    id="pincode"
                    placeholder="Enter Pincode"
                    required
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                  {errors['The pincode field is required.'] && (
                    <div className="invalid-feedback d-block">Please enter pincode.</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Phone</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    placeholder="Enter Phone"
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {errors['The phone field is required.'] && (
                    <div className="invalid-feedback d-block">Please enter phone.</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Mobile</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    placeholder="Enter Mobile"
                    required
                    value={phone2}
                    onChange={(e) => setPhone2(e.target.value)}
                  />
                  {errors['The phone field is required.'] && (
                    <div className="invalid-feedback d-block">Please enter phone.</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Enter Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors['The password field is required.'] && (
                    <div className="invalid-feedback d-block">Please enter password.</div>
                  )}
                </div>
                <Button type="submit" className="btn btn-primary w-100">Sign Up</Button>
              </form>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              Already have an account?
              <a href="#" role="button" onClick={() => setModalShow(true)}> Sign in</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegisterModal;
