import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DeleteConfirmationModal = ({ show, onHide, onDelete, loading }) => {
  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Body>
        <div className="modal-content p-1">
          <div className="modal-header border-0">
            <h5 className="modal-title fs-3 fw-bold">Confirm Delete</h5>
            <Button variant="close" onClick={onHide} />
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete this item?</p>
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={onHide}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-2"
                onClick={onDelete}
                disabled={loading}
              >
                {loading ? 'Deleting...' : 'Delete'}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteConfirmationModal;
