import React, { useEffect, useState } from 'react';
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getUser } from '../../service/constants';
import mastercard from '../../../src/assets/images/banner/FooterBanner787x300 _1.png';

function Footer() {
    const { apiAction } = useApi();
    const [category, setCategory] = useState([]);
    const user = JSON.parse(getUser());
    
    const fetchCategoryList = async () => {
        try {
            const response = await apiAction({
                url: "/user/category/list",
                method: "GET",
            });
            if (response.status === true) {
                setCategory(response.data);
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.log("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchCategoryList();
    }, []);

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row g-4 py-4">
                        <div className="col-12 col-md-6">
                            <div className="row g-4">
                                <div className="col-6 col-sm-6 col-md-3">
                                    <h6 className="mb-4">For retailers</h6>
                                    <ul className="nav flex-column">
                                        {category.map((product) => (
                                            <li key={product.id} className="nav-item mb-2">
                                                <Link to={`/categories/${product?.id}/${product?.name}`} className="nav-link">
                                                    {product?.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-6 col-md-9">
                                    <h6 className="mb-4">My Account</h6>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2">
                                            <Link to={user === null ? `/userSignin` : `/settings`} className="nav-link">
                                                My Account
                                            </Link>
                                        </li>
                                        <li className="nav-item mb-2">
                                            <Link to={user === null ? `/userSignin` : `/orderList`} className="nav-link">
                                                Order List
                                            </Link>
                                        </li>
                                        <li className="nav-item mb-2">
                                            <Link to={user === null ? `/userSignin` : `/wishlist`} className="nav-link">
                                                Shop Your Favourites
                                            </Link>
                                        </li>
                                        <li className="nav-item mb-2">
                                            <Link to={`/terms-conditions`} className="nav-link">
                                                Terms and Conditions
                                            </Link>
                                        </li>
                                        <li className="nav-item mb-2">
                                            <Link to={`/contact-us`} className="nav-link">
                                                Contact us
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-md-end justify-content-center align-items-center">
                            <img src={mastercard} alt="MasterCard" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
