import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "../../service/useApi";
import { format } from "date-fns";
import { fetchDataFromAPI } from "../../service/api";
import { getUser } from "../../service/constants";
import AdminOrderStatus from "../../commponets/AdminOrderStatus";
import AdminOrderPaymentStatus from "../../commponets/AdminOrderPaymentStatus";
import SellerProfile from "../SellerProfile/SellerProfile";
import SellerNavbar from "../SellerNavbar/SellerNavbar";
import SalesOrderPayment from "../../commponets/SalesOrderPayment";
import SalesOrderPaymentSatus from "../../commponets/SalesOrderPaymentSatus";

function SellerOrderList() {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowPaymentOrder, setModalShowPaymentOrder] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [aItemList, setAllItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [orderStatus, setOrderStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log('orderStatus--------------------------------', orderStatus);
  const user = JSON.parse(getUser());

  useEffect(() => {
    fetchPricebandList(currentPage);
  }, [currentPage, orderStatus, paymentStatus]);

  useEffect(() => {
    filterPricebandList();
  }, [searchTerm, aItemList]);

  const fetchPricebandList = (page) => {
    setLoading(true);
    fetchDataFromAPI(
      `/sale/order/listing?perPage=10&page=${page}&orderStatus=${orderStatus}&paymentStatus=${paymentStatus}`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false);
        if (response?.status === true) {
          const pricebandData = response?.data?.data;
          setAllItemList(pricebandData);
          setFilteredPricebandList(pricebandData);
          setTotalPages(response?.data?.last_page);
        } else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "---error");
      });
  };

  const filterPricebandList = () => {
    if (searchTerm?.trim() === "") {
      setFilteredPricebandList(aItemList);
    } else {
      const filteredList = aItemList.filter((item) =>
        item.order_number?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPricebandList(filteredList);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleModalSuccess = (newStatus) => {
    const updatedItemList = aItemList.map((item) => {
      if (item.id === selectedItemId) {
        return { ...item, status: newStatus };
      }
      return item;
    });
    setOrderStatus(updatedItemList)
    setAllItemList(updatedItemList);
    setFilteredPricebandList(updatedItemList);
    setModalShow(false);
  };

  const handleModalPaymentSuccess = (newStatus) => {
    const updatedItemList = aItemList.map((item) => {
      if (item.id === selectedItemId) {
        return { ...item, payment_status: newStatus };
      }
      return item;
    });
    setAllItemList(updatedItemList);
    setFilteredPricebandList(updatedItemList);
    setModalShowPaymentOrder(false);
  };

  const handleChangeStatusClick = (itemId) => {
    setSelectedItemId(itemId);
    setModalShow(true);
  };

  const handleChangeStatusPaymentClick = (itemId) => {
    setSelectedItemId(itemId);
    setModalShowPaymentOrder(true);
  };



  return (
    <>
      <SalesOrderPayment
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSuccess={handleModalSuccess}
        itemId={selectedItemId}
        onUpdate={() => fetchPricebandList(currentPage)}
      />
      <SalesOrderPaymentSatus
        show={modalShowPaymentOrder}
        onHide={() => setModalShowPaymentOrder(false)}
        onSuccess={handleModalPaymentSuccess}
        itemId={selectedItemId}
        onUpdate={() => fetchPricebandList(currentPage)}
      />
      <SellerProfile />
      <div className="main-wrapper">
        <SellerNavbar />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                  <div>
                  <h2>Order List</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                        <form className="d-flex" role="search">
                          <input
                            className="form-control"
                            type="search"
                            placeholder="Search..."
                            aria-label="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </form>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                        <select
                          className="form-select"
                          value={orderStatus}
                          onChange={(e) => setOrderStatus(e.target.value)}
                        >
                           <option value="">Select order status</option>
                          <option value="0">Created</option>
                          <option value="1">Accepted</option>
                          <option value="2">Cancelled</option>
                          <option value="3">Delivered</option>
                        </select>
                      </div>

                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-md-0">
                        <select
                          className="form-select"
                          value={paymentStatus}
                          onChange={(e) => setPaymentStatus(e.target.value)}
                        >
                            <option value="">Select payment status</option>
                          <option value="0">Pending</option>
                          <option value="1">Paid</option>
                          <option value="2">Cancelled</option>
                          <option value="3">Refunded</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      {loading ? (
                        <div className="text-center py-5">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <table className="table table-centered table-hover mb-0 text-nowrap table-borderless table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>Order Number</th>
                              <th>Order Status</th>
                              <th>Payment Status</th>
                              <th>Total</th>
                              <th>Number of items</th>
                              <th>Created at</th>
                              <th>Updated at</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              filteredPricebandList.length > 0 ?
                              (
                                Array.isArray(filteredPricebandList) &&
                              filteredPricebandList.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    <a className="text-reset">
                                      {item?.order_number}
                                    </a>
                                  </td>
                                  <td>
                                    {item?.status == 0 && (
                                      <button
                                        onClick={() =>
                                          handleChangeStatusClick(item?.id)
                                        }
                                        className="btn btn-dark-secondary w-100"
                                      >
                                        Created
                                      </button>
                                    )}
                                    {item?.status == 1 && (
                                      <button
                                        onClick={() =>
                                          handleChangeStatusClick(item?.id)
                                        }
                                        className="btn btn-dark-secondary w-100"
                                      >
                                        Accepted
                                      </button>
                                    )}
                                    {item?.status == 2 && (
                                      <button
                                        onClick={() =>
                                          handleChangeStatusClick(item?.id)
                                        }
                                        className="btn btn-dark-secondary w-100"
                                      >
                                        Cancelled
                                      </button>
                                    )}
                                    {item?.status == 3 && (
                                      <button
                                        onClick={() =>
                                          handleChangeStatusClick(item?.id)
                                        }
                                        className="btn btn-dark-secondary w-100"
                                      >
                                        Delivered
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    {item?.payment_status == 0 && (
                                      <button
                                        onClick={() =>
                                          handleChangeStatusPaymentClick(
                                            item?.id
                                          )
                                        }
                                        className="btn btn-dark-secondary w-100"
                                      >
                                        Pending
                                      </button>
                                    )}
                                    {item?.payment_status == 1 && (
                                      <button
                                        onClick={() =>
                                          handleChangeStatusPaymentClick(
                                            item?.id
                                          )
                                        }
                                        className="btn btn-dark-secondary w-100"
                                      >
                                        Paid
                                      </button>
                                    )}
                                    {item?.payment_status == 2 && (
                                      <button
                                        onClick={() =>
                                          handleChangeStatusPaymentClick(
                                            item?.id
                                          )
                                        }
                                        className="btn btn-dark-secondary w-100"
                                      >
                                        Cancelled
                                      </button>
                                    )}
                                    {item?.payment_status == 3 && (
                                      <button
                                        onClick={() =>
                                          handleChangeStatusPaymentClick(
                                            item?.id
                                          )
                                        }
                                        className="btn btn-dark-secondary w-100"
                                      >
                                        Refunded
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    {item?.totalGrossValue}
                                  </td>
                                  <td>{item.order_line?.length}</td>
                                  <td>
                                    {format(
                                      new Date(item?.created_at),
                                      "yyyy-MM-dd"
                                    )}
                                  </td>
                                  <td>
                                    {format(
                                      new Date(item?.updated_at),
                                      "yyyy-MM-dd"
                                    )}
                                  </td>
                                </tr>
                              ))
                              )
                              : (
                                <tr>
                                <td colSpan="9" className="text-center">No Order List found.</td>
                              </tr>
                              )
                            }
                            
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div className="border-top d-md-flex justify-content-between align-items-center px-6 py-6">
                      <span className="text-muted">
                        Showing {currentPage} to {totalPages} of {totalPages}{" "}
                        entries
                      </span>
                      <nav className="d-flex mt-2 mt-md-0">
                        <ul className="pagination mb-0">
                          <li className="page-item">
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              Previous
                            </button>
                          </li>
                          {Array.from({ length: totalPages }, (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${i + 1 === currentPage ? "active" : ""
                                }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(i + 1)}
                              >
                                {i + 1}
                              </button>
                            </li>
                          ))}
                          <li className="page-item">
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default SellerOrderList;
